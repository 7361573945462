import React from 'react'
import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { HiCheck, HiChevronDown } from 'react-icons/hi2'
import { slugify } from '../../utils/helper'
import langIcon from "../../assets/images/global.png";

interface TempDropdownProps {
  name?: string
  label?: string
  placeholder?: string
  disabled?: boolean
  options?: {
    value: number | string
    label: string
  }[]
  onChange?: (value?: string) => void
  value?: string | string[]
  defaultValue?: string | string[]
  id?: string
  index?: number
  className?: string
  inputClassName?: string
  onFocus?: () => void
  onBlur?: () => void
  multiple?: boolean
  inputHeight?: string
  noBg?: boolean
  langIcon?: boolean
}

const DropdownSelect: React.FC<TempDropdownProps> = props => (
  <div className='flex flex-col gap-[14px] px-1'>
    {props.label && (
      <label htmlFor='options' className='block text-sm font-medium text-black'>
        {props.label}
      </label>
    )}
    <Listbox
      multiple={props.multiple}
      defaultValue={props.defaultValue}
      value={props.value}
      onChange={props.onChange}
      disabled={props?.disabled ?? props.options?.length === 0}
    >
      {({ open, disabled, value }) => (
        <div className={`relative ${open ? 'z-50' : ''}`}>
          <input type='hidden' name={props.name} value={value} />
          <Listbox.Button
            id={props.id || slugify(props?.label || '')}
            className={`${props.inputClassName || 'h-14 sm:text-sm'}
            relative w-full cursor-pointer rounded-md ${props.noBg ? '' : 'bg-white'} py-2 pl-3 pr-10 text-left outline-none
            hover:ring-digital_mint hover:ring-2 focus:ring-2 focus:ring-digital_mint transition-all ease-in-out
            duration-300 transform border-1 border-calm_platinum/10 language-dropdown
            ${open ? 'ring-digital_mint ring-2' : ''}
            ${disabled ? 'cursor-not-allowed hover:ring-calm_platinum/20' : ''}
            `}
          >
            {props?.options && props.options.length > 0 ? (
              <div className="flex items-center space-x-2">
                {props.langIcon && <img
                src={langIcon}
                alt="Language"
                style={{
                  width: '20px',
                  height: '20px',
                  cursor: 'pointer',
                }}/>}
                <span className='flex items-center whitespace-nowrap min-h-[20px]'>
                  {[[value].flatMap(v => v).map(v => props.options?.find(
                    o => o.value === v)?.label)].flat().join(', ') || props.placeholder}
                </span>
                <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                  {open ? (
                    <HiChevronDown className='h-5 w-5 text-black transform rotate-180' aria-hidden='true' />
                  ) : (
                    <HiChevronDown className='h-5 w-5 text-black' aria-hidden='true' />
                  )}
                </span>
              </div>
            ) : (
              <span className='block truncate'>No options available!</span>
            )}
          </Listbox.Button>
          <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
            <Listbox.Options className='absolute inset-x-0 mt-1 max-h-60 w-full overflow-y-auto overflow-x-visible rounded-md bg-white py-1 px-0 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm list-none'>
              {props?.options?.map((option, optionIdx) => (
                <Listbox.Option
                  key={optionIdx}
                  className={`relative cursor-pointer select-none mx-1 py-2 pl-10 pr-4 rounded-md ${
                    option?.label === props?.value ? 'bg-digital_mint/10 text-rich_moss' : 'text-gray-900'
                  }`}
                  value={option.value}
                >
                  {
                    <>
                      <span className={`block whitespace-nowrap ${option?.value === props?.value ? 'font-medium' : 'font-normal'}`}>
                        {option.label}
                      </span>
                      {option?.value === props?.value || (Array.isArray(value) && value.some(v => v === option?.value)) ? (
                        <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-digital_mint'>
                          <HiCheck className='h-5 w-5' aria-hidden='true' />
                        </span>
                      ) : null}
                    </>
                  }
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  </div>
)

export default DropdownSelect
