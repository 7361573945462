import React, { useContext, useEffect, useState } from 'react'
import * as Accordion from '@radix-ui/react-accordion'
import { RxChevronRight } from 'react-icons/rx'
import CheckBox from '../checkBox'
import DatePicker from '../datePicker'
import Tooltip from '../tooltip'
import { HiXMark } from 'react-icons/hi2'
import { IFilterData, IOptions } from '../../pages/containers/containersList/containersList'
import { useTranslation } from 'react-i18next'
import {useBasicFieldName} from "../../hooks/useBasicFieldName";

type IProps = {
  filterData: IFilterData
  onFilterDataChange: (data: IFilterData) => void
  tempFilterData: {
    arrivalDate: string
    departureDate: string
  }
  onTempFilterDataChange: (data: { arrivalDate: string; departureDate: string }) => void
  setIsNewFilterConditions: (data: boolean) => void
  options: IOptions
}

enum FieldName {
  assigneeList = 'assigneeList',
  yardLocationList = 'yardLocationList',
  inspectionStatusList = 'inspectionStatusList',
}

const FilterAccordion = (props: IProps) => {
  const [departureDate, setDepartureDate] = useState(props.filterData.departureDate)
  const [arrivalDate, setArrivalDate] = useState(props.filterData.arrivalDate)
  const [assigneeSearchQuery, setAssigneeSearchQuery] = useState('')
  const [yardLocationSearchQuery, setYardLocationSearchQuery] = useState('')
  const [inspectionStatusSearchQuery, setContainerStatusSearchQuery] = useState('')
  const basicFieldName = useBasicFieldName();

  const getLocalFormattedDate = (date: Date[]) => {
    try {
      if (date.length > 1) {
        return `${date[0].getDate()}-${date[0].getMonth() + 1}-${date[0].getFullYear()} ~ ${date[1].getDate()}-${
          date[1].getMonth() + 1
        }-${date[1].getFullYear()}`
      }
    } catch (error) {
      return date as unknown as string
    }
  }

  const handleManualDateChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    props.onTempFilterDataChange({ ...props.tempFilterData, [fieldName]: e.target.value as unknown as string })
    props.setIsNewFilterConditions(true)
  }

  const handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    switch (fieldName) {
      case 'source':
        setAssigneeSearchQuery(e.target.value)
        break
      case 'destination':
        setYardLocationSearchQuery(e.target.value)
        break
      case 'status':
        setContainerStatusSearchQuery(e.target.value)
        break
      default:
        break
    }
  }

  const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: FieldName) => {
    if (props.filterData[fieldName].includes(e.target.value)) {
      props.onFilterDataChange({
        ...props.filterData,
        [fieldName]: [...props.filterData[fieldName].filter(item => item !== e.target.value)],
      })
    } else {
      props.onFilterDataChange({
        ...props.filterData,
        [fieldName]: [...props.filterData[fieldName], e.target.value],
      })
    }
    props.setIsNewFilterConditions(true)
  }

  const { t } = useTranslation()

  return (
    <Accordion.Root
      type="single"
      collapsible
      className="border-none outline-none focus:outline-none text-strong_graphite hover:ring-0 focus:ring-0 ring-0 min-w-[250px]"
    >
      <Accordion.Item value="departure-date">
        <AccordionTrigger>
          {basicFieldName('Departure Date', 'container')}{' '}
          {props.filterData.departureDate ? '*' : ''}
        </AccordionTrigger>
        <NonFunctionalAccordionContent>
          <Tooltip title={basicFieldName('Departure Date', 'container')}>
            <div className="relative flex w-full min-w-[250px]">
              <input
                placeholder={basicFieldName('Departure Date', 'container')}
                className="flex w-full px-4 py-2 m-1 transition duration-300 ease-in-out border rounded-md outline-none form-input focus:border-digital_mint focus:ring-digital_mint hover:ring-1 ring-digital_mint border-calm_platinum/50 focus:outline-none"
                onChange={e => handleManualDateChange(e, 'departureDate')}
                value={
                  props.tempFilterData.departureDate ||
                  getLocalFormattedDate(props.filterData.departureDate as unknown as Date[]) ||
                  ''
                }
              />
              <button
                className="absolute flex w-8 h-8 items-center justify-center bg-calm_platinum/20 cursor-pointer rounded-full top-[9px] outline-none border-none right-[10px] hover:bg-calm_platinum/50 transition-colors ease-in-out"
                onClick={() => {
                  props.onFilterDataChange({ ...props.filterData, departureDate: '' as unknown as Date })
                  props.onTempFilterDataChange({ ...props.tempFilterData, departureDate: '' })
                }}
              >
                <HiXMark className="w-4 h-4" />
              </button>
            </div>
          </Tooltip>
          <DatePicker
            value={props.filterData.departureDate || new Date()}
            onChange={date => {
              props.onFilterDataChange({ ...props.filterData, departureDate: date })
              props.onTempFilterDataChange({ ...props.tempFilterData, departureDate: '' })
            }}
          />
        </NonFunctionalAccordionContent>
      </Accordion.Item>

      <Divider />

      <Accordion.Item value="arrival">
        <AccordionTrigger>
          {basicFieldName('Arrival Date', 'container')} {props.filterData.arrivalDate ? '*' : ''}
        </AccordionTrigger>
        <NonFunctionalAccordionContent>
          <Tooltip title="Better if date selected from Calendar">
            <div className="relative flex w-full min-w-[250px]">
              <input
                placeholder="Select Arrival Date"
                className="flex w-full px-4 py-2 m-1 transition duration-300 ease-in-out border rounded-md outline-none form-input focus:border-digital_mint focus:ring-digital_mint hover:ring-1 ring-digital_mint border-calm_platinum/50 focus:outline-none"
                value={
                  props.tempFilterData.arrivalDate ||
                  getLocalFormattedDate(props.filterData.arrivalDate as unknown as Date[]) ||
                  ''
                }
                onChange={e => handleManualDateChange(e, 'arrivalDate')}
              />
              <button
                className="absolute flex w-8 h-8 items-center justify-center bg-calm_platinum/20 cursor-pointer rounded-full top-[9px] outline-none border-none right-[10px] hover:bg-calm_platinum/50 transition-colors ease-in-out"
                onClick={() => {
                  props.onFilterDataChange({ ...props.filterData, arrivalDate: '' as unknown as Date })
                  props.onTempFilterDataChange({ ...props.tempFilterData, arrivalDate: '' })
                }}
              >
                <HiXMark className="w-4 h-4" />
              </button>
            </div>
          </Tooltip>
          <DatePicker
            value={props.filterData.arrivalDate}
            onChange={date => {
              props.onFilterDataChange({ ...props.filterData, arrivalDate: date })
              props.onTempFilterDataChange({ ...props.tempFilterData, arrivalDate: '' })
            }}
          />
        </NonFunctionalAccordionContent>
      </Accordion.Item>

      <Divider />

      <Accordion.Item value="assignee">
        <AccordionTrigger>
          {t('assignee', {defaultValue: 'Assignee'})}{' '}
          {props.filterData.assigneeList.length > 0 ? `(${props.filterData.assigneeList.length})` : ''}
        </AccordionTrigger>
        <AccordionContent dataLength={props.options.assigneeList.length}>
          <div className="relative flex w-full min-w-[250px]">
            <input
              placeholder="Search Assignee"
              className="flex w-full px-4 py-2 m-1 transition duration-300 ease-in-out border rounded-md outline-none form-input focus:border-digital_mint focus:ring-digital_mint hover:ring-1 ring-digital_mint border-calm_platinum/50 focus:outline-none"
              value={assigneeSearchQuery}
              onChange={e => handleSearchQuery(e, 'source')}
            />
            <button
              className="absolute flex w-8 h-8 items-center justify-center bg-calm_platinum/20 cursor-pointer rounded-full top-[9px] outline-none border-none right-[10px] hover:bg-calm_platinum/50 transition-colors ease-in-out"
              onClick={() => setAssigneeSearchQuery('')}
            >
              <HiXMark className="w-4 h-4" />
            </button>
          </div>
          {props.options.assigneeList.filter(item => item?.toLowerCase().includes(assigneeSearchQuery.toLowerCase()))
            .length > 0 ? (
            props.options.assigneeList
              .filter(item => item?.toLowerCase().includes(assigneeSearchQuery.toLowerCase()))
              .map(
                (option: string, index: number) =>
                  option && (
                    <CheckBox
                      key={index}
                      label={option}
                      value={option}
                      checked={props.filterData.assigneeList?.includes(option)}
                      onChange={e => handleCheckBoxChange(e, FieldName.assigneeList)}
                    />
                  )
              )
          ) : (
            <p className="text-sm text-center text-calm_platinum">No Data Found</p>
          )}
        </AccordionContent>
      </Accordion.Item>

      <Divider />

      <Accordion.Item value="yard-location">
        <AccordionTrigger>
          {basicFieldName('Yard Location', 'container')}{' '}
          {props.filterData.yardLocationList.length > 0 ? `(${props.filterData.yardLocationList.length})` : ''}
        </AccordionTrigger>
        <AccordionContent dataLength={props.options.yardLocationList.length}>
          <div className="relative flex w-full min-w-[250px]">
            <input
              placeholder="Search Yard Location"
              className="flex w-full px-4 py-2 m-1 transition duration-300 ease-in-out border rounded-md outline-none form-input focus:border-digital_mint focus:ring-digital_mint hover:ring-1 ring-digital_mint border-calm_platinum/50 focus:outline-none"
              value={yardLocationSearchQuery}
              onChange={e => handleSearchQuery(e, 'destination')}
            />
            <button
              className="absolute flex w-8 h-8 items-center justify-center bg-calm_platinum/20 cursor-pointer rounded-full top-[9px] outline-none border-none right-[10px] hover:bg-calm_platinum/50 transition-colors ease-in-out"
              onClick={() => setYardLocationSearchQuery('')}
            >
              <HiXMark className="w-4 h-4" />
            </button>
          </div>
          {props.options.yardLocationList.filter(item => item?.toLowerCase().includes(yardLocationSearchQuery.toLowerCase()))
            .length > 0 ? (
            props.options.yardLocationList
              .filter(item => item?.toLowerCase().includes(yardLocationSearchQuery.toLowerCase()))
              .map(
                (option: string, index: number) =>
                  option && (
                    <CheckBox
                      key={index}
                      label={option}
                      value={option}
                      checked={props.filterData.yardLocationList?.includes(option)}
                      onChange={e => handleCheckBoxChange(e, FieldName.yardLocationList)}
                    />
                  )
              )
          ) : (
            <p className="text-sm text-center text-calm_platinum">No Data Found</p>
          )}
        </AccordionContent>
      </Accordion.Item>

      <Divider />

      <Accordion.Item value="inspection-status">
        <AccordionTrigger>
          {t('inspectionStatus')}{' '}
          {props.filterData.inspectionStatusList.length > 0 ? `(${props.filterData.inspectionStatusList.length})` : ''}
        </AccordionTrigger>
        <AccordionContent dataLength={props.options.inspectionStatusList.length}>
          <div className="relative flex w-full min-w-[250px]">
            <input
              placeholder="Search Inspection Status"
              className="flex w-full px-4 py-2 m-1 transition duration-300 ease-in-out border rounded-md outline-none form-input focus:border-digital_mint focus:ring-digital_mint hover:ring-1 ring-digital_mint border-calm_platinum/50 focus:outline-none"
              value={inspectionStatusSearchQuery}
              onChange={e => handleSearchQuery(e, 'status')}
            />
            <button
              className="absolute flex w-8 h-8 items-center justify-center bg-calm_platinum/20 cursor-pointer rounded-full top-[9px] outline-none border-none right-[10px] hover:bg-calm_platinum/50 transition-colors ease-in-out"
              onClick={() => setContainerStatusSearchQuery('')}
            >
              <HiXMark className="w-4 h-4" />
            </button>
          </div>
          {props.options.inspectionStatusList.filter(item =>
            item?.toLowerCase().includes(inspectionStatusSearchQuery.toLowerCase())
          ).length > 0 ? (
            props.options.inspectionStatusList
              .filter(item => item?.toLowerCase().includes(inspectionStatusSearchQuery.toLowerCase()))
              .map(
                (option: string, index: number) =>
                  option && (
                    <CheckBox
                      key={index}
                      label={option}
                      value={option}
                      checked={props.filterData.inspectionStatusList?.includes(option)}
                      onChange={e => handleCheckBoxChange(e, FieldName.inspectionStatusList)}
                    />
                  )
              )
          ) : (
            <p className="text-sm text-center text-calm_platinum">No Data Found</p>
          )}
        </AccordionContent>
      </Accordion.Item>
    </Accordion.Root>
  )
}

const AccordionTrigger = ({ children, ...props }: { children: React.ReactNode }) => {
  return (
    <Accordion.Header className="m-0">
      <Accordion.Trigger
        className="inline-flex items-center justify-start w-full py-2 text-sm font-semibold transition duration-300 ease-in-out bg-transparent border-none rounded-md outline-none cursor-pointer ring-0 group hover:text-digital_mint rdx-state-open:text-digital_mint rdx-state-open:font-bold text-strong_graphite"
        {...props}
      >
        <RxChevronRight
          aria-hidden
          className="mr-2 transition duration-300 ease-in-out transform stroke-1 group-rdx-state-open:rotate-90 group-hover:text-digital_mint"
        />
        {children}
      </Accordion.Trigger>
    </Accordion.Header>
  )
}

const AccordionContent = ({
  children,
  className,
  dataLength,
  ...props
}: {
  children: React.ReactNode
  className?: string
  dataLength?: number
}) => {
  const [viewMore, setViewMore] = useState(false)
  return (
    <Accordion.Content className={`py-2 mx-4 ${className}`} {...props}>
      <div
        className={`flex flex-col w-full gap-4 justify-start py-1 overflow-hidden ${
          viewMore ? '' : dataLength && dataLength > 5 ? 'max-h-44' : 'max-h-60'
        }`}
      >
        {children}
      </div>
      {dataLength && dataLength > 5 ? (
        <button
          className="flex items-center justify-center px-3 py-2 text-sm font-semibold transition duration-300 ease-in-out bg-transparent border-none outline-none cursor-pointer w-fit ring-0 hover:text-digital_mint text-strong_graphite"
          onClick={() => setViewMore(!viewMore)}
        >
          {viewMore ? 'View Less...' : 'View More...'}
        </button>
      ) : null}
    </Accordion.Content>
  )
}

const NonFunctionalAccordionContent = ({
  children,
  className,
  ...props
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <Accordion.Content className={`py-2 mx-3 ${className}`} {...props}>
      {children}
    </Accordion.Content>
  )
}

const Divider = () => (
  <div className="mx-2">
    <div className="flex my-2 w-full h-[1px] bg-strong_graphite" />
  </div>
)

export default FilterAccordion
