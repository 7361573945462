import React, { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { HiChevronDown } from 'react-icons/hi'
import Tooltip from '../tooltip'

const DropdownWithoutLabel = ({
  options,
  selected,
  onChange,
  className,
  toolTipTitle,
}: {
  options: readonly { label: string; value: string | number }[]
  selected: { label: string; value: string | number }
  onChange: (value: { label: string; value: string | number }) => void
  className?: string
  toolTipTitle: string
}) => {
  // const [selected, setSelected] = useState(people[0]);

  return (
    <Listbox value={selected} onChange={onChange}>
      <div className={className}>
        <div className='relative mt-1'>
          <Tooltip title={toolTipTitle}>
            <Listbox.Button className='relative w-full py-2 pl-3 pr-10 text-left bg-white border rounded-lg cursor-pointer border-calm_platinum/50 focus:ring-1 focus:ring-digital_mint h-11 focus:outline-none focus-visible:border-digital_mint focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-digital_mint sm:text-sm'>
              <span className='block truncate'>{selected.label}</span>
              <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                <HiChevronDown className='w-5 h-5 text-gray-400' aria-hidden='true' />
              </span>
            </Listbox.Button>
          </Tooltip>
          <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
            <Listbox.Options className='absolute w-full px-0 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-0 ring-opacity-5 focus:outline-none sm:text-sm'>
              {options.map((option, idx) => (
                <Listbox.Option
                  key={idx}
                  className={`relative cursor-pointer select-none list-none px-3 py-2 ${
                    option.value === selected.value ? 'bg-digital_mint/10 text-digital_mint' : 'text-gray-900'
                  }`}
                  value={option}
                >
                  {({ selected }) => <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{option.label}</span>}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </div>
    </Listbox>
  )
}

export default DropdownWithoutLabel
