import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import { getCheckReportV2 } from '../../api/apiCalls'
import './checkReports.scss'
import emoryLogo from '../../assets/images/emory_logo.svg'
import downloadIcon from '../../assets/images/file_download_icon_green.svg'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import Loader from '../../components/Loader/loader'
import MediaSlider from '../../components/MediaSlider/mediaSlider'
import { useParams } from 'react-router-dom'
import JSZip from 'jszip'
import FileSaver from 'file-saver'
import { FaFileAlt, FaFileCsv, FaPlayCircle } from 'react-icons/fa'
import LoaderWithProgress from '../../components/Loader/loaderWithProgress'
import { BsFilePdf, BsFiletypeJson } from 'react-icons/bs'
import videoPoster from '../../assets/images/video-poster-new.png'
import withTitle from '../../hoc/withTitle'
import { styled } from '../../stitches.config'
import Flex from '../../components/flex/Flex'
import getThumbnail from '../../utils/getThumbnail'
import { t } from "i18next";
import { formatDate } from "../../utils/helper";
import InputField from "../../components/InputField/inputField";
import { ScreenOrderForTranslationContext } from '../../contexts/userDetailsContext'
import { showAlertForError } from '../../helpers/validations'

const Box = styled('div')
const CheckReports = (props: any) => {
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [response, setResponse] = useState<Record<any, any>>()
  const [showMediaSlider, setShowMediaSlider] = useState<boolean>(false)
  const [startingInd, setStartingInd] = useState<any>(0)
  const [mediaData, setMediaData] = useState<any>([])
  const [mediaData2, setMediaData2] = useState<any>([])
  const [mediaType, setMediaType] = useState<any>('')
  const [showIsArchived, setShowIsArchived] = useState<boolean>(false)

  const [docHoverId, setDocHoverId] = useState<any>(null)

  const ref = React.useRef(null)

  const params = useParams()
  const checkReportIdentifier = params.identifier

  useEffect(() => {

    setShowLoader(true)

    if (checkReportIdentifier) {
      getCheckReportV2(checkReportIdentifier)
        .then((response: any) => {
          setShowLoader(false)
          if (response.status === 406) {
            setShowIsArchived(true)
            response = null;
          }
          else if (response.status && response.status !== 200) {
            alert(response.status + ' ' + response.statusText)
            response = null;
          }
          else {
            setResponse(response)
          }
        })
        .catch((error: any) => {
          setShowLoader(false)
          console.log(error)
          showAlertForError(error)
        })
    }
  }, [])

  const onMediaClick = (ind: any, type: string) => {
    if (type === 'video') {
      const index = response?.data.photos.length + ind
      setStartingInd(index)
    } else {
      setStartingInd(ind)
    }
    setMediaData(response?.data.photos.concat(response?.data.videos))
    setMediaType('photosVideos')
    setShowMediaSlider(true)
  }
  const { translations, translationsLoaded } = useContext(ScreenOrderForTranslationContext)

  const onMarkingClick = (ind: any) => {
    setStartingInd(ind)
    setMediaData(response?.data.markings)
    setMediaData2(response?.data.marking_ref)
    setMediaType('markings')
    setShowMediaSlider(true)
  }

  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadingProgress, setDownloadingProgress] = useState<number | string>('')
  const handleDownload = (type: string) => {
    const zip = new JSZip()
    const zipFileName = `check-report-${type}.zip`
    const folder = zip.folder(`check-report-${type}`)

    const tempArr =
      type === 'photos'
        ? response?.data.photos
        : type === 'videos'
          ? response?.data.videos
          : type === 'documents'
            ? response?.data.documents
            : null

    try {
      if (tempArr && tempArr.length > 0) {
        setIsDownloading(true)
        let totalDownloaded = 0
        tempArr.forEach((item: (typeof tempArr)[0]) => {
          const filename = item.url.split('/').at(-1)
          fetch(item.url, {
            headers: {
              "Content-Type": "image/png",
              "cross-origin": "anonymous"
            },
            cache: 'no-cache'
          })
            .then((r) => r.blob())
            .then((blob) => {
              totalDownloaded += 1
              folder?.file(filename, blob, { binary: true })
              setDownloadingProgress(Math.round((totalDownloaded / tempArr.length) * 100) + ' %')
              if (totalDownloaded === tempArr.length) {
                zip.generateAsync({ type: 'blob' }).then(function (content) {
                  FileSaver.saveAs(content, zipFileName)
                })
                setIsDownloading(false)
              }
            })
        })
      }
    } catch (error: any) {
      setDownloadingProgress('')
      setIsDownloading(false)
      alert(error.message)
    }
  }

  const onDocHover = (id: any) => {
    setDocHoverId(id)
  }

  const getSimpleBasicField = (item: any, beAppVersion: number = 0) => {
    if (beAppVersion < 200) { return item.value }
    if (['stage_id', 'item_type_id', 'container_db_id'].includes(item.key)) {
      return item.value_label
    } else { return item.value }
  }

  function renderStdOrInspFields(fields: any) {
    return fields.map((item: any) => {
      return (
        <div className='display_flex add-new-item-field-container' id={item.id} key={item.id}>
          <p className='add-new-item-field-name add-right-margin'>{item.field_name}</p>
          <p className='add-new-item-field-value add-new-item-disabled-field-value'>
            {item.data_type && item.data_type.toLowerCase() === 'date'
              ? formatDate(item.value)
              : item.data_type.toLowerCase() === 'boolean'
                ? item.value === 'true' || item.value === true || item.value === '1'
                  ? t('yes')
                  : item.value === 'false' || item.value === false || item.value === '0'
                    ? t('no')
                    : ''
                : item.value}
          </p>
        </div>
      )
    })
  }

  return (
    <>
      {showLoader && <Loader />}
      {showMediaSlider && (
        <MediaSlider
          dismissHandler={() => setShowMediaSlider(false)}
          mediaData={mediaData}
          mediaData2={mediaData2}
          startingInd={startingInd}
          mediaType={mediaType}
        ></MediaSlider>
      )}
      <div className='check-report-page-container' ref={ref}>
        <Flex align='center' css={{ borderBottom: '1px solid #ddd', p: 16, '@small': { px: 32 }, '@medium': { px: 48 } }}>
          <img src={response?.data.wide_brand_logo || response?.data.square_brand_logo} className='check-report-company-logo' alt='' />
          <a className='check-report-emory-logo-link' href="https://emory.pro" target={"_blank"}>
            <img src={emoryLogo} className='check-report-emory-logo' alt='' />
          </a>
        </Flex>
        <div className='check-report-content-outer-container'>
          <Flex
            justify='between'
            align='center'
            css={{
              borderBottom: '1px solid #ddd',
              p: 16,
              '@small': { px: 48 },
              '@medium': { px: 64 },

              '& h1': {
                fontFamily: 'Nunito',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: 18,
                lineHeight: '30px',
                color: '$rich_moss',
                m: 0,
                mr: '2rem',

                '@small': {
                  fontSize: 24,
                  lineHeight: '32px',
                },
              },
            }}
          >
            <h1>{response?.report_type === 'HANDOVER' ? t('digitalHandoverReport') : t('digitalInspectionReport')}</h1>
            <a href={response?.pdf_link} target='_blank' download className='download-link' rel='noreferrer'>
              <Flex
                css={{
                  all: 'unset',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '& p': { display: 'none', '@small': { display: 'inline-block' } },
                }}
                as='button'
                type='button'
              >
                <img src={downloadIcon} className='check-report-page-download-pdf-icon' alt='' />
                <p className='check-report-page-download-pdf-text'>{t('downloadPDF')}</p>
              </Flex>
            </a>
          </Flex>

          {!showIsArchived &&
            (<Flex direction={{ '@medium': 'row', '@initial': 'column' }} css={{ p: 16 }}>
              <Box css={{ '@small': { pl: 32 }, '@medium': { width: '35%', pl: 48 } }}>
                <div className='display_flex add-new-item-field-container'>
                  <p className='add-new-item-field-name add-right-margin'>{t('assigneeName')}</p>
                  <p className='add-new-item-field-value'>{response?.assignee_name}</p>
                </div>
                <div className='display_flex add-new-item-field-container'>
                  <p className='add-new-item-field-name add-right-margin'>{t('assigneeId')}</p>
                  <p className='add-new-item-field-value'>{response?.employee_id}</p>
                </div>
                <div className='display_flex add-new-item-field-container'>
                  <p className='add-new-item-field-name add-right-margin'>{t('version')}</p>
                  <p className='add-new-item-field-value'>{response?.version}</p>
                </div>
                <div className='display_flex add-new-item-field-container'>
                  <p className='add-new-item-field-name add-right-margin'>{t('createdOn')}</p>
                  <p className='add-new-item-field-value add-new-item-disabled-field-value'>{formatDate(response?.created_at)}</p>
                </div>
                <div className='display_flex add-new-item-field-container'>
                  <p className='add-new-item-field-name add-right-margin'>{t('assigneeSignature')}</p>
                  <p className='add-new-item-field-value add-new-item-disabled-field-value'>
                    <img src={response?.assignee_signature} className='wide-brand-logo-img cursor_pointer' alt='' crossOrigin='anonymous' />
                  </p>
                </div>
                {response?.report_type === 'HANDOVER' && (<>
                  <div className='display_flex add-new-item-field-container'>
                    <p className='add-new-item-field-name add-right-margin'>{t('handoverSignature')}</p>
                    <p className='add-new-item-field-value add-new-item-disabled-field-value'>
                      <img src={response?.handover_sign_image} className='wide-brand-logo-img cursor_pointer' alt='' crossOrigin='anonymous' />
                    </p>
                  </div>
                  <div className='display_flex add-new-item-field-container'>
                    <p className='add-new-item-field-name add-right-margin'>{t('handoverName')}</p>
                    <p className='add-new-item-field-value'>{response?.handover_name}</p>
                  </div>
                  <div className='display_flex add-new-item-field-container' id='check-report-inspection-comments'>
                    <p className='add-new-item-field-name add-right-margin'>{t('handoverComment')}</p>
                    <InputField
                      value={response?.handover_comment||''}
                      autoSize
                      disabled
                      textarea={true}
                      className='add-new-item-field-value'
                    />
                  </div>
                </>)}

                {translationsLoaded && (
                  <p className="add-new-item-left-col-title">
                    {response?.type?.toLowerCase() === 'item'
                      ? translations['itemDetails']?.toUpperCase()
                      : translations['containerDetails']?.toUpperCase()}
                  </p>
                )}
                {response?.data.basic_fields &&
                  response?.data.basic_fields.length > 0 &&
                  response?.data.basic_fields.map((item: any) => {
                    if (item.key === 'description') return
                    return (
                      <div className='display_flex add-new-item-field-container' id={item.id} key={item.key}>
                        <p className='add-new-item-field-name add-right-margin'>{item.field_name}</p>
                        {item.key === 'image' ? (
                          <img src={item.value} className='wide-brand-logo-img cursor_pointer' alt='' crossOrigin='anonymous' />
                        ) : (
                          <p className='add-new-item-field-value add-new-item-disabled-field-value'>
                            {item.data_type && item.data_type.toLowerCase() === 'date'
                              ? formatDate(item.value)
                              : item.data_type.toLowerCase() === 'boolean'
                                ? item.value === 'true' || item.value === true || item.value === '1'
                                  ? t('yes')
                                  : item.value === 'false' || item.value === false || item.value === '0'
                                    ? t('no')
                                    : ''
                                : getSimpleBasicField(item, response?.data?.backend_api_version)}
                          </p>
                        )}
                      </div>
                    )
                  })
                }

                {response?.data.standard_fields &&
                  response?.data.standard_fields.length > 0 &&
                  renderStdOrInspFields(response?.data.standard_fields)
                }
                <div className='display_flex add-new-item-field-container' id='check-report-description'>
                  <p className='add-new-item-field-name add-right-margin'>{t('description')}</p>
                  <InputField
                    value={response?.data.description||''}
                    autoSize={true}
                    textarea={true}
                    disabled
                    className='add-new-item-field-value'
                  />
                </div>

                { /* ---- inspection fields -----  */}
                {response?.data.inspection_fields && response?.data.inspection_fields.length > 0 && (
                  <p className='add-new-item-left-col-title check-report-inspection-details-title'>
                    {t('inspectionDetails').toUpperCase()}</p>
                )}
                {response?.data.inspection_fields &&
                  response?.data.inspection_fields.length > 0 &&
                  renderStdOrInspFields(response?.data.inspection_fields)
                }
                <div className='display_flex add-new-item-field-container' id='check-report-inspection-comments'>
                  <p className='add-new-item-field-name add-right-margin'>{t('inspectionComments')}</p>
                  <InputField
                    value={response?.data.inspection_comments||''}
                    autoSize
                    disabled
                    textarea={true}
                    className='add-new-item-field-value'
                  />
                </div>
              </Box>

              <Box css={{ '@small': { pr: 32 }, '@medium': { width: '65%', pr: 48 } }}>

                { /* ---- Markings -----  */}

                <Flex
                  direction={{ '@initial': 'column', '@medium': 'row' }}
                  align={{ '@initial': 'start', '@medium': 'center' }}
                  css={{ gap: 16 }}
                >
                  <p className='add-new-item-left-col-title'>{t('markings').toUpperCase()}</p>
                </Flex>

                <div className='display_flex uploaded-data-container align_center'>
                  {response?.data.markings && response?.data.markings.length > 0 ? (
                    response?.data.markings.map((marking: any, ind: any) => {
                      return (
                        <div className='check-report-photo-video-outer-container' key={marking.id}>
                          <div className='add-logo-field-container check-report-photo-video-container'>
                            <> <div className='item-index'>{ind + 1}</div>
                              <div className='uploaded-data-img-container'>
                                <object
                                  data={marking.marked_image ? marking.marked_image : marking.image}
                                  className='items-img-style cursor_pointer'
                                  type='image/png'
                                  onClick={() => {
                                    onMarkingClick(ind)
                                  }}>
                                  <img className='items-img-style cursor_pointer' loading='lazy' src={marking.marked_image ?
                                    marking.marked_image : marking.image} alt='' crossOrigin='anonymous' />
                                </object>
                              </div>
                              <p className='markings-views-text'>{marking.name}</p>
                            </>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <p>No Markings</p>
                  )}
                </div>

                { /* ---- custom markings -----  */}
                {response?.data.custom_markings && response?.data.custom_markings.length > 0 && (
                  <p className='add-new-item-left-col-title'>{t('customMarkings')}</p>
                )}
                {response?.data.custom_markings &&
                  response?.data.custom_markings.length > 0 &&
                  <ul className="add-new-item-field-value add-new-item-disabled-field-value">
                    {response?.data.custom_markings?.map((marking: string) => <li>{marking}</li>)}
                  </ul>
                }

                { /* ---- Documents -----  */}
                {isDownloading ? (
                  <div className='fixed inset-0 z-10'>
                    <LoaderWithProgress progress={downloadingProgress} />
                  </div>
                ) : null}
                <Flex justify='between' className='add-new-item-left-col-title' css={{ mb: 16 }}>
                  {t('documents').toUpperCase()}
                  {response?.data.documents?.length > 0 && (
                    <button onClick={() => handleDownload('documents')} className='download-imgs-btn'>
                      {t('download')}
                    </button>
                  )}
                </Flex>
                <div className='display_flex uploaded-data-container'>
                  {response?.data.documents?.length > 0 &&
                    response?.data.documents.map((item: any, index: number) => {
                      const fileExtension = item.url.split('.').pop().toLowerCase();
                      const isImage = ['jpg', 'jpeg', 'png'].includes(fileExtension);
                      const isVideo = ['mp4', 'mov', 'MOV'].includes(fileExtension);
                      return (
                        <div className='add-new-item-upload-photos-single-photo-container' key={item.id}>
                          <a
                            href={item.url}
                            target='_blank'
                            style={{ color: 'white' }}
                            rel='noreferrer'
                          >
                            <div
                              className='add-logo-field-container add-new-item-type-img-upload-field-container'
                              onMouseOver={() => onDocHover(item.id)}
                              onPointerLeave={() => setDocHoverId(null)}
                            >
                              {item.url && (
                                <>
                                  <div className='item-index'>{index + 1}</div>
                                  <div className='uploaded-data-img-container' style={{ position: 'inherit' }}>
                                    {isImage ? (
                                      <object
                                        className='item-details-img-style cursor_pointer position_relative'
                                        data={getThumbnail(item.url)}
                                        type='image/png'
                                      >
                                        <img className='item-details-img-style' loading='lazy' src={item.url} alt='' crossOrigin='anonymous' />
                                      </object>
                                    ) : isVideo ? (
                                      <div className='uploaded-data-img-container uploaded-video-container' style={{ position: 'relative' }}>
                                        <video
                                          width='220'
                                          height='240'
                                          className='cursor_pointer position_relative'
                                          src={item.url}
                                        />
                                        <div
                                          className='video-overlay'
                                          style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: 'rgba(0, 0, 0, 0.4)',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            pointerEvents: 'none',
                                          }}
                                        >
                                          <FaPlayCircle
                                            style={{ fontSize: '3rem', color: 'white' }}
                                          />
                                        </div>
                                      </div>
                                    ) : item.url && item.url.slice(-4).toLowerCase() === '.csv' ? (
                                      <FaFileCsv style={{ fontSize: '3.5rem', cursor: 'pointer' }} />
                                    ) : item.url.slice(-5).toLowerCase() === '.json' ? (
                                      <BsFiletypeJson style={{ fontSize: '3.5rem', cursor: 'pointer' }} />
                                    ) : item.url.slice(-4).toLowerCase() === '.pdf' ? (
                                      <BsFilePdf className='h-14 w-14 text-strong_graphite cursor-pointer' />
                                    ) : (
                                      <FaFileAlt style={{ fontSize: '3.5rem' }} />
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </a>
                          <p
                            style={{
                              position: 'inherit',
                              fontSize: '12px',
                              margin: 'auto',
                              overflowWrap: 'break-word',
                              textAlign: 'center',
                            }}
                          >
                            ...{(item.file_display_name || item.url).substr((item.file_display_name || item.url).length - 10)}
                          </p>
                        </div>
                      );
                    })}
                  {(!response?.data.documents || response?.data.documents.length === 0) && <p className='no-docs-txt'>
                    {t('noDataAvailable')}</p>}
                </div>

                <Flex justify='between' className='add-new-item-left-col-title' css={{ mb: 16 }}>
                  {t('photos').toUpperCase()}
                  {response?.data.photos?.length > 0 && (
                    <button onClick={() => handleDownload('photos')} className='download-imgs-btn'>
                      {t('download')}
                    </button>
                  )}
                </Flex>
                <div className='display_flex uploaded-data-container'>
                  {response?.data.photos?.length > 0 &&
                    response?.data.photos.map((item: any, ind: any) => (
                      <div className='add-new-item-upload-photos-single-photo-container' key={ind}>
                        <div className='add-logo-field-container item-container-check-report'>
                          <div className='item-index'>{ind + 1}</div>
                          {item.url && (
                            <object
                              onClick={() => onMediaClick(ind, 'photo')}
                              data={getThumbnail(item.url)}
                              type='image/png'
                              className='uploaded-data-img-container'>
                              <img className='item-details-img-style' loading='lazy' src={item.url} alt='' crossOrigin='anonymous' />
                            </object>
                          )}
                        </div>
                      </div>
                    ))}
                  {(!response?.data.photos || response?.data.photos.length === 0) && <p className='no-docs-txt'>
                    {t('noDataAvailable')}
                  </p>}
                </div>

                <Flex justify='between' className='add-new-item-left-col-title' css={{ mb: 16 }}>
                  {t('videos').toUpperCase()}
                  {response?.data.videos?.length > 0 && (
                    <button onClick={() => handleDownload('videos')} className='download-imgs-btn'>
                      {t('download')}
                    </button>
                  )}
                </Flex>
                <div className='display_flex uploaded-data-container'>
                  {response?.data.videos?.length > 0 &&
                    response?.data.videos.map((item: any, ind: any) => {
                      return (
                        <div className='add-new-item-upload-photos-single-photo-container' key={item.id}>
                          <div className='add-logo-field-container item-container-check-report'>
                            <div className='item-index'>{ind + 1}</div>
                            {item.url && (
                              <>
                                <input type='file' className='logo-upload-input' disabled={true} title={item.url} accept='video/*' />
                                <div className='uploaded-data-img-container uploaded-video-container border-'>
                                  <video
                                    width='220'
                                    height='240'
                                    className='cursor_pointer position_relative'
                                    src={item.url}
                                    onClick={() => onMediaClick(ind, 'video')}
                                    crossOrigin='anonymous'
                                    poster={videoPoster}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )
                    })}
                  {(!response?.data.videos || response?.data.videos.length === 0) && <p className='no-docs-txt'>No Videos</p>}
                </div>
              </Box>
            </Flex>)
          }
          {showIsArchived && (
            <p className="isArchivedMsg">{t('reportIsArchived')}</p>
          )}
        </div>
      </div>
    </>
  )
}

export default withTitle(CheckReports, 'Inspection Report | Emory')
