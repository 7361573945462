import React, { createContext, Fragment, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { styled } from '../stitches.config'

import arrowRight from '../assets/images/arrow_right_icon.svg'

const BreadcrumbsContext = createContext({
  breadcrumbs: [] as ICrumb[],
  push: (crumb: ICrumb) => crumb,
  pop: (index?: number) => index,
})

export interface ICrumb {
  title: string
  href: string | null
}

function BreadCrumbsProvider({ children }: PropsWithChildren<unknown>) {
  const [breadcrumbs, setBreadcrumbs] = useState<ICrumb[]>([])

  const push = useCallback((crumb: ICrumb) => {
    setBreadcrumbs(b => b.slice().concat(crumb))
    return crumb
  }, [])

  const pop = useCallback((index?: number) => {
    setBreadcrumbs(b => b.slice(0, index ?? b.length - 1))
    return index
  }, [])

  return <BreadcrumbsContext.Provider value={{ breadcrumbs, push, pop }}>{children}</BreadcrumbsContext.Provider>
}

export const useBreadCrumbs = (title?: ICrumb['title'] | null, href: ICrumb['href'] | null = location.pathname) => {
  const state = useContext(BreadcrumbsContext)

  if (!state) throw new Error('Please wrap BreadCrumbsProvider')

  useEffect(() => {
    if (title) {
      state.push({
        title,
        href,
      })

      return () => {
        state.pop()
      }
    }
  }, [state.push, state.pop, title, href])

  return state.breadcrumbs
}

const BreadCrumbs = styled('div', {
  px: 24,
  py: 32,
})

const Crumb = styled('p', {
  display: 'inline-block',
  textDecoration: 'none',
  fontWeight: 400,
  fontSize: 14,
  lineHeight: 1.38,
  marginTop: 3,
  color: '#333333',
  margin: 0,
  cursor: 'default',

  '&:first-child': {
    fontSize: 24,
    lineHeight: 1.38,
    fontWeight: 500,
    color: '$text',
  },
})

export const renderBreadCrumbs = (breadcrumbs: ICrumb[]) => (
  <BreadCrumbs>
    {breadcrumbs.map((crumb, i, { length }) => (
      <Fragment key={crumb.title}>
        <Crumb {...(!!crumb.href && { as: Link, to: crumb.href })}>{crumb.title}</Crumb>
        {i < length - 1 && <img src={arrowRight} className='arrow-right-icon' />}
      </Fragment>
    ))}
  </BreadCrumbs>
)

export default BreadCrumbsProvider
