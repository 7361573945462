import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import Modal from 'react-modal'
import { getLoggedInUserDetails, updateMemberDetails, uploadFile } from '../../api/apiCalls'
import InputField from '../../components/InputField/inputField'
import './userAccount.scss'
import editIcon from '../../assets/images/edit_icon.svg'
import { getEmailErrorMsg, phoneNumberValidation, showAlertForError } from '../../helpers/validations'
// import { UploadToS3 } from '../../helpers/uploadToS3';
import { css } from '@emotion/react'
import ClipLoader from 'react-spinners/ClipLoader'
import variables from '../../themes.module.scss'
import noPicFound from '../../assets/images/no_profile_pic.png'
import lockIcon from '../../assets/images/lock_icon.png'
import Button from '../../components/Button/button'
import { UserPermissionsContext } from '../../contexts/userPermissionsContext'
import { useTranslation } from 'react-i18next'

const UserAccount = (props: any) => {
  const { t } = useTranslation()

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: 'none',
      border: 'none',
    },
    overlay: {
      background: 'rgba(174,174,174,0.5)',
      backdropFilter: 'blur(15px)',
      // zIndex: '9999999999 !important'
      // -webkitBackdropFilter: 'blur(5px)'
    },
  }

  const [profilePic, setProfilePic] = useState<any>(null)
  const [memberId, setMemberId] = useState<any>(null)
  const [userRole, setUserRole] = useState<string>('')
  const [userName, setUserName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<any>('')
  const [phoneNum, setPhoneNum] = useState<string>('')
  const [address, setAddress] = useState<any>('')
  const [userSignature, setUserSignature] = useState<any>(null)
  const [editable, setEditable] = useState<boolean>(false)
  const [imgLoading, setImgLoading] = useState<boolean>(false)
  const [onHover, setOnHover] = useState<boolean>(false)
  const { ...userPermissionsData } = useContext(UserPermissionsContext)
  const userPermissions = userPermissionsData?.data

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    getUserDetails()
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  const getUserDetails = () => {
    const token = localStorage.getItem('accessToken')
    props.showLoader(true)
    getLoggedInUserDetails(token)
      .then(response => {
        props.showLoader(false)
        // console.log(response)
        if (response.user) {
          if (response.user.full_name) {
            setUserName(response.user.full_name)
          }
          if (response.user.email) {
            setEmail(response.user.email)
          }
          if (response.user.phone) {
            setPhoneNum(response.user.phone)
          }
          if (response.user.address) {
            setAddress(response.user.address)
          }
          if (response.user.profile_photo) {
            setProfilePic(response.user.profile_photo)
          }
          if (response.user.user_role_name) {
            setUserRole(response.user.user_role_name)
          }
          if (response.user.signature) {
            setUserSignature(response.user.signature)
          }
          if (response.user.id) {
            setMemberId(response.user.id)
          }
        }
      })
      .catch(error => {
        console.log(error)
        props.showLoader(false)
      })
  }

  const onEditClick = () => {
    setEditable(true)
  }

  const onCancelClick = () => {
    getUserDetails()
    setEditable(false)
  }

  const onSaveClick = () => {
    const token = localStorage.getItem('accessToken')
    const data = {
      full_name: userName,
      email: email,
      phone: phoneNum,
      address: address,
      // user_role: 1,
      // language_id: 1,
      // employee_id: "asdasd",
      profile_photo: profilePic,
      // signature: "url"
    }
    setEditable(false)
    props.showLoader(true)
    updateMemberDetails(memberId, data, token)
      .then(response => {
        // console.log(response)
        props.showLoader(false)
        userPermissionsData.refetch()
        getUserDetails()
      })
      .catch(error => {
        console.log(error)
        props.showLoader(false)
        showAlertForError(error)
      })
  }

  const onNameChange = (val: any) => {
    setUserName(val)
  }

  const onEmailChange = (val: any) => {
    setEmail(val)
    setEmailError(getEmailErrorMsg(val))
  }

  const onPhonenNumChange = (val: any) => {
    if (phoneNumberValidation(val)) {
      setPhoneNum(val)
    }
  }

  const onAddressChange = (val: any) => {
    setAddress(val)
  }

  const onEditProfilePic = async (event: any) => {
    if (event.target.files[0]) {
      // console.log(event.target.files[0])
      setImgLoading(true)
      // let orgId = userPermissions && userPermissions.data.organization_id;
      const res: any = await uploadFile(event.target.files[0])
      const imgUrl = res.file_path || ''
      setProfilePic(imgUrl)
      setImgLoading(false)
      const token = localStorage.getItem('accessToken')
      const data = {
        profile_photo: imgUrl,
      }
      props.showLoader(true)
      updateMemberDetails(memberId, data, token)
        .then(response => {
          // console.log(response)
          props.showLoader(false)
          getUserDetails()
        })
        .catch(error => {
          console.log(error)
          props.showLoader(false)
          showAlertForError(error)
        })
    }
  }

  const override = css`
    display: block;
    margin: 0 auto;
    margin-top: 3rem;
  `

  return (
    <div>
      <Modal
        isOpen={true}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => props.dismissHandler()}
        style={customStyles}
        contentLabel='Example Modal'
        ariaHideApp={false}
      >
        <div className='media-slider-outer-container user-profile-outer-container'>
          <div className='media-slider-close-icon-container user-profile-close-icon'>
            <p className='cross-icon cursor_pointer media-slider-cross-icon' onClick={() => props.dismissHandler()}>
              x
            </p>
          </div>
          <p className='user-profile-title'>Profile</p>
          <div className='user-profile-content-outer-container'>
            {/* <div className={!onHover ? "user-profile-pic-container" : "user-profile-pic-container reduced_opacity"}> */}
            <div className='user-profile-pic-container'>
              <div
                className='user-profile-pic-input-outer-container'
                // onMouseOver={() => setOnHover(true)}
                // onMouseLeave={() => setOnHover(false)}
              >
                <input type='file' className='logo-upload-input user-profile-pic-input' onChange={onEditProfilePic} />
                {imgLoading ? (
                  <ClipLoader color={variables.digitalMint} loading={imgLoading} css={override} size={18} />
                ) : profilePic ? (
                  <img
                    src={profilePic}
                    className={!onHover ? 'user-profile-pic' : 'user-profile-pic reduced_opacity'}
                    // onClick={onEditProfilePic}
                  />
                ) : (
                  <img
                    src={noPicFound}
                    className={!onHover ? 'user-profile-pic' : 'user-profile-pic reduced_opacity'}
                    // onClick={onEditProfilePic}
                  />
                )}
                <div
                  className={imgLoading ? 'edit-profile-pic-icon-container no_left_padding' : 'edit-profile-pic-icon-container'}
                  // onMouseOver={() => setOnHover(true)}
                  // onMouseLeave={() => setOnHover(false)}
                >
                  <img
                    src={editIcon}
                    className='cursor_pointer upload-item-layout-edit-icon'
                    // onClick={onEditProfilePic}
                  />
                </div>
              </div>
            </div>
            <p className='user-profile-role-text'>{userRole}</p>

            <div className='user-profile-info-container'>
              <div className='user-profile-edit-icon-container'>
                <div className={!editable ? 'edit-data-btn margin_none' : 'edit-data-btn reduced_opacity_4 margin_none'}>
                  <Button
                    btnStyle='textOnlyBtn'
                    textColor='#000000'
                    width='auto'
                    textDecoration={!editable && 'underline'}
                    padding='0'
                    // onClick={!editable ? onEditDataClick : onCancelClick}
                    onClick={onEditClick}
                    className={editable && 'cursor_default'}
                  >
                    {!editable ? t('edit') : t('inEditMode')}
                  </Button>
                </div>
                {/* {!editable ?
                                    // <img src={editIcon} className="cursor_pointer upload-item-layout-edit-icon" onClick={onEditClick}/>
                                    <p
                                    className="cursor_pointer user-profile-edit-text"
                                    onClick={onEditClick}>
                                        Edit Data
                                    </p>
                                :
                                    <p
                                    className="cursor_pointer user-profile-edit-text"
                                    // onClick={onEditClick}
                                    >
                                        Edit Data
                                    </p>
                                //     <div className="display_flex user-info-save-cancel-container">
                                //         <p
                                //         onClick={onCancelClick}
                                //         className="cursor_pointer user-profile-cancel-text"
                                //         >
                                //             Cancel
                                //         </p>
                                //         <p
                                //         onClick={onSaveClick}
                                //         className="cursor_pointer user-profile-save-text"
                                //         >
                                //             Save
                                //         </p>
                                //     </div>
                                } */}
              </div>
              <div className={!editable ? 'display_flex user-profile-info-row' : 'display_flex user-profile-info-row no_top_margin'}>
                <p className='user-profile-info-row-field-name'>{t('fullName')}</p>
                {editable ? (
                  <InputField
                    value={userName}
                    placeholder='Enter Full Name'
                    onChange={onNameChange}
                    type='text'
                    className='user-profile-input-field'
                  />
                ) : (
                  <p className='user-profile-info-row-field-value'>{userName}</p>
                )}
              </div>
              <div className={!editable ? 'display_flex user-profile-info-row' : 'display_flex user-profile-info-row no_top_margin'}>
                <p className='user-profile-info-row-field-name'>{t('email')}</p>
                {editable ? (
                  <InputField
                    value={email}
                    placeholder='Enter Email'
                    onChange={onEmailChange}
                    type='email'
                    error={emailError}
                    className='user-profile-input-field'
                    disabled={true}
                  />
                ) : (
                  <p className='user-profile-info-row-field-value'>{email}</p>
                )}
              </div>
              <div className={!editable ? 'display_flex user-profile-info-row' : 'display_flex user-profile-info-row no_top_margin'}>
                <p className='user-profile-info-row-field-name'>{t('phone')}</p>
                {editable ? (
                  <InputField
                    value={phoneNum}
                    placeholder='Enter Phone Number'
                    onChange={onPhonenNumChange}
                    type='text'
                    className='user-profile-input-field'
                  />
                ) : (
                  <p className='user-profile-info-row-field-value'>{phoneNum}</p>
                )}
              </div>
              <div className={!editable ? 'display_flex user-profile-info-row' : 'display_flex user-profile-info-row no_top_margin'}>
                <p className='user-profile-info-row-field-name'>{t('address')}</p>
                {editable ? (
                  <div className='user-profile-textarea-container'>
                    <InputField
                      value={address}
                      placeholder='Enter Address'
                      onChange={onAddressChange}
                      textarea={true}
                      className='user-profile-input-field user-profile-textarea-style'
                    />
                  </div>
                ) : (
                  // <p className="user-profile-info-row-field-value">
                  //     {address}
                  // </p>
                  <div className='user-profile-textarea-container'>
                    <InputField
                      value={address}
                      autoSize={true}
                      disabled
                      textarea={true}
                      className='user-profile-input-field user-profile-textarea-style'
                    />
                  </div>
                )}
              </div>
              <div className='display_flex user-profile-info-row'>
                <p className='user-profile-info-row-field-name'>
                  {t('signature')}{' '}
                  <span>
                    <img src={lockIcon} className='signature-lock-icon' />
                  </span>
                </p>
                <div className={editable ? 'user-signature-edit-mode-container' : 'user-signature-container'}>
                  <img src={userSignature} className='user-signature' />
                </div>
              </div>
            </div>
            {editable && (
              <div className='display_flex company-details-bottom-btn-inner-container user-profile-bottom-btns-container'>
                <Button
                  backgroundColor={variables.clearSilver}
                  textColor='#333333'
                  padding='0.75rem 2rem'
                  fontSize='12px'
                  height='fit-content'
                  width='auto'
                  onClick={onCancelClick}
                >
                  {t('cancel')}
                </Button>

                <Button padding='0.75rem 2rem' fontSize='12px' height='fit-content' width='auto' leftMargin='1.25rem' onClick={onSaveClick}>
                  {t('confirm')}
                </Button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default UserAccount
