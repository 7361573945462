import React, { useState, useEffect, createContext } from "react";
import { useQuery } from 'react-query';
import { getSuperadminMembers } from "../api/apiCalls";

export const SuperadminMembersContext = createContext<any>({});

export const SuperadminMembersProvider: React.FC<any> = (props) => {
    
    const [membersList, setMembersList] = useState();
    
    let getSuperadminMembersList = async () => {    
      let token = localStorage.getItem('adminAccessToken')
      const res = await getSuperadminMembers(token)
      return res
    };
        
    const { isLoading, isSuccess, isError, data, error, refetch } = useQuery('superadminMembersList',  getSuperadminMembersList, 
        {
            enabled: localStorage.getItem("adminLoggedIn") === "true" ? true : false
        }
    )

    useEffect(() => {      
      if(data && localStorage.getItem("adminLoggedIn") === "true"){                        
        setMembersList(data)
      }          
  }, [data])

    useEffect(() => {
      refetch()
    }, [])
    
  return (    
    <SuperadminMembersContext.Provider value={[membersList, setMembersList, { isLoading, isSuccess, isError, data, error , refetch}]}>
      {props.children}
    </SuperadminMembersContext.Provider>
  );
  }