import React, { useState } from 'react'
import Button from '../../../components/Button/button'
import variables from '../../../themes.module.scss'
import InputField from '../../../components/InputField/inputField'
import { getEmailErrorMsg, phoneNumberValidation } from '../../../helpers/validations'
import DropdownSelect from '../../../components/dropdownSelect/dropdownSelect'
import arrowRight from '../../../assets/images/arrow_right_icon.svg'
import './addNewMember.scss'
import { useEffect } from 'react'
import { createNewMember, deleteMember, getMemberDetails, getUserRoles, suspendMember, updateMemberDetails } from '../../../api/apiCalls'
import CustomMessagePopup from '../../../components/CustomMessagePopup/customMessagePopup'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Loader from '../../../components/Loader/loader'
import Dropdown from '../../../components/dropdown'
import { IOption } from '../../../interfaces/dropdown'
import { useTranslation } from 'react-i18next'

const AddNewMember = (props: any) => {
  const memberId = useParams()?.id
  const [memberName, setMemberName] = useState<string>('')
  const [memberNameError, setMemberNameError] = useState<string>('')
  const [memberEmail, setMemberEmail] = useState<string>('')
  const [preferredLanguage, setPreferredLanguage] = useState<string>('')
  const [memberEmailError, setMemberEmailError] = useState<string>('')
  const [memberAddress, setMemberAddress] = useState<string>('')
  const [memberAddressError, setMemberAddressError] = useState<string>('')
  const [memberPhnNum, setContactPhnNum] = useState<any>('')
  const [memberPhnNumError, setContactPhnNumError] = useState<any>('')
  const [memberDisplayCode, setMemberDisplayCode] = useState<string>('')
  const [userRole, setUserRole] = useState<IOption>()
  const [userRoleOptions, setUserRoleOptions] = useState([])
  const [existingMemberRoleId, setExistingMemberRoleId] = useState<any>(null)
  const [suspendedStatus, setSuspendedStatus] = useState<boolean>(false)
  const [showMemberCreatedPopup, setShowMemberCreatedPopup] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const onMemberEmailChangeHandler = (value: string) => {
    setMemberEmail(value)
    setMemberEmailError(getEmailErrorMsg(value))
  }

  const onMemberPhnNumChangeHandler = (value: any) => {
    if (phoneNumberValidation(value)) {
      setContactPhnNum(value)
    }
  }

  const languageOptions = [
    { value: 1, label: 'English' },
    { value: 2, label: 'German' },
  ]

  const getRole = (val: any) => {
    return userRoleOptions.find((item: any, ind: any) => item.value === val)
  }

  useEffect(() => {
    if (existingMemberRoleId && userRoleOptions.length > 0) {
      setUserRole(getRole(existingMemberRoleId))
    }
  }, [existingMemberRoleId, userRoleOptions])

  useEffect(() => {
    getDetailsOnLoad()
  }, [])

  const [loading, showLoader] = useState(false)

  const getDetailsOnLoad = () => {
    const token = localStorage.getItem('accessToken')
    if (memberId) {
      showLoader(true)
      getMemberDetails(memberId, token)
        .then(response => {
          showLoader(false)
          if (response.user) {
            setMemberName(response.user.full_name)
            setMemberEmail(response.user.email)
            setContactPhnNum(response.user.phone)
            setMemberAddress(response.user.address)
            setExistingMemberRoleId(response.user.user_role)
            setPreferredLanguage(response.user.language_id)
            setMemberDisplayCode(response.user.employee_id)
            setSuspendedStatus(response.user.status === 1 ? false : true)
          }
        })
        .catch(error => {
          showLoader(false)
          console.log(error)
          alert('Error: ' + error)
        })
    }

    getUserRoles(token)
      .then(response => {
        const tempArr: any = []
        if (response.user_roles && response.user_roles.length > 0) {
          response.user_roles.forEach((item: any) => {
            const data = {
              value: item.id,
              label: item.name,
            }
            tempArr.push(data)
          })
          setUserRoleOptions(tempArr)
        }
      })
      .catch(error => {
        console.log(error)
        alert('Error: ' + error)
      })
  }

  const onSaveClick = () => {
    if (!memberName) {
      alert('Please enter member name')
    } else if (!memberEmail || memberEmailError) {
      if (memberEmail && memberEmailError) {
        alert('Please enter member email in correct format')
      } else {
        alert('Please enter member email')
      }
    } else if (!userRole || (userRole && !userRole.value)) {
      alert('Please select user role')
    } else if (!memberDisplayCode) {
      alert('Please enter member display code')
    } else {
      const token = localStorage.getItem('accessToken')
      const data = {
        full_name: memberName,
        email: memberEmail,
        phone: memberPhnNum,
        address: memberAddress,
        user_role: userRole.value,
        language_id: preferredLanguage,
        employee_id: memberDisplayCode,
      }

      if (memberId) {
        showLoader(true)
        updateMemberDetails(memberId, data, token)
          .then(response => {
            showLoader(false)
            if (response.status === 200) {
              showSuccessToast()
              setMessage('Member details updated for ' + memberName)
            } else {
              showFailureToast()
            }
          })
          .catch(error => {
            showLoader(false)
            alert('Error: ' + error)
          })
      } else {
        showLoader(true)
        createNewMember(data, token)
          .then(response => {
            showLoader(false)
            if (response.status === 201) {
              setSuspendedStatus(false)
              setMessage('New Member ' + memberName + ' created!')
              showSuccessToast()
            } else {
              showFailureToast()
            }
          })
          .catch(error => {
            console.log(error)
            showLoader(false)
            alert('Error: ' + error)
          })
      }
    }
  }

  /**
   * This function and the corresponding backend API call act as a toggle button/function.
   * If the member is currently active, suspend them, else vice-versa.
   */
  const onSuspendClick = () => {
    showLoader(true)
    const token = localStorage.getItem('accessToken')
    suspendMember(memberId, token)
      .then(response => {
        showLoader(false)
        if (response.status !== 200) {
          alert(response.data.errors.message)
        } else {
          setSuspendedStatus(!suspendedStatus)
        }
      })
      .catch(error => {
        showLoader(false)
        alert('Error: ' + error)
      })
  }

  const navigate = useNavigate()

  const onDeleteClick = () => {
    showLoader(true)
    const token = localStorage.getItem('accessToken')
    deleteMember(memberId, token)
      .then(response => {
        showLoader(false)
        if (response.status !== 200) {
          alert(response.data.errors.message)
        } else {
          navigate('/dashboard/members')
        }
      })
      .catch(error => {
        showLoader(false)
        alert('Error: ' + error)
      })
  }

  const onCustomPopupDismiss = () => {
    setShowMemberCreatedPopup(false)
    setSuspendedStatus(false)
  }

  const showSuccessToast = () => {
    toast.success(memberId ? 'Member details saved!' : 'New member created!', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  const showFailureToast = () => {
    toast.error(memberId ? 'An error occurred while saving member details!' : 'An error occurred while creating new member!', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  const { t } = useTranslation()

  return (
    <>
      {loading && <Loader />}
      <ToastContainer
        position='bottom-center'
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme='colored'
      />

      {showMemberCreatedPopup && <CustomMessagePopup dismissHandler={onCustomPopupDismiss} message={message} />}
      <div className='sub-tab-content-container'>
        <div className='display_flex add-member-header-container'>
          <p className='selected-tab-page-title cursor_pointer' onClick={() => setSuspendedStatus(false)}>
            {t('manageUsers')}
          </p>
          <img src={arrowRight} className='arrow-right-icon' />
          <p className='sub-tab-selected-title'>{memberId ? t('editUserInfo') : t('addNewUser')}</p>
        </div>
        <p className='tabs-page-title-bottom-border' />

        <div className='display_flex flex-row-input-fields-container'>
          <div className='add-new-item-left-col-container'>
            <div className='row-left-field-container'>
              <InputField
                label={t('userName')}
                value={memberName}
                placeholder='Enter User Name'
                onChange={(val: string) => setMemberName(val)}
                type='text'
                error={memberNameError}
              />
            </div>

            <div className='row-left-field-container row-input-fields-container-margin-top'>
              <InputField
                label={t('userPhoneNumber')}
                value={memberPhnNum}
                placeholder='Enter User Phone Number'
                onChange={onMemberPhnNumChangeHandler}
                type='text'
                error={memberPhnNumError}
              />
            </div>

            <div className='row-left-field-container row-input-fields-container-margin-top'>
              <DropdownSelect
                label={t('preferredLanguage')!}
                placeholder={t('selectPreferredLang')!}
                options={languageOptions}
                value={preferredLanguage}
                onChange={(val: any) => setPreferredLanguage(val)}
                id='preferred-language-dropdown'
                index={2}
              />
            </div>
            <div className='row-left-field-container row-input-fields-container-margin-top'>
              <Dropdown
                label={t('userRole')}
                placeholder='Select User Role'
                options={userRoleOptions}
                value={userRole}
                onChange={setUserRole}
                id='user-role-dropdown'
              />
            </div>
          </div>

          <div className='add-new-item-right-col-container org-settings-company-details-right-col'>
            <div className='row-right-field-container'>
              <InputField
                label={t('userEmail')}
                value={memberEmail}
                placeholder='Enter User Email'
                onChange={onMemberEmailChangeHandler}
                type='email'
                error={memberEmailError}
                disabled={memberId}
              />
            </div>

            <div className='row-right-field-container row-input-fields-container-margin-top'>
              <InputField
                label={t('userAddress')}
                value={memberAddress}
                error={memberAddressError}
                placeholder='Enter User Address'
                onChange={(val: string) => setMemberAddress(val)}
                textarea={true}
                className='member-address-textarea'
              />
            </div>

            <div className='row-right-field-container row-input-fields-container-margin-top'>
              <InputField
                label={t('userDisplayCode')}
                value={memberDisplayCode}
                placeholder='Enter User Display Code'
                onChange={(val: string) => setMemberDisplayCode(val)}
                type='text'
                error={false}
              />
            </div>
          </div>
        </div>

        <div className='company-details-bottom-btn-container'>
          <div className='display_flex company-details-bottom-btn-inner-container'>
            <Link to='..'>
              <Button backgroundColor='#EEEEEE' textColor='#333333' padding='0.75rem 2rem' fontSize='12px' height='fit-content' width='auto'>
                {t('cancel')}
              </Button>
            </Link>

            <Button padding='0.75rem 2rem' fontSize='12px' height='fit-content' width='auto' leftMargin='1.25rem' onClick={onSaveClick}>
              {t('saveChanges')}
            </Button>
          </div>
        </div>
      </div>

      {!!memberId && (
        <>
          <div className='add-member-divider-div'></div>
          <div className='sub-tab-content-container add-member-danger-zone-container'>
            <p className='selected-tab-page-title manage-members-page-title'>{t('dangerZone')}</p>
            <p className='tabs-page-title-bottom-border' />

            <p className='suspend-member-acc-text'>{t('suspendUserAccountThisActionFreezesTheUsersAccountAndIsReversible')}</p>
            <Button
              btnStyle={!suspendedStatus && 'transparentBtn'}
              textColor={suspendedStatus ? '#ffffff' : variables.emoryDanger}
              width='35%'
              onClick={onSuspendClick}
              // disabled={suspendedStatus}
            >
              {suspendedStatus ? 'Activate User Account' : t('suspendUserAccount')}
            </Button>

            <p className='suspend-member-acc-text'>{t('deleteUserAccountThisActionDeletedTheUsersAccountAndIsIrreversible')}</p>
            <Button textColor={variables.clearSilver} width='35%' backgroundColor={variables.emoryDanger} onClick={onDeleteClick}>
              {t('deleteUserAccountPermanently')}
            </Button>
          </div>
        </>
      )}
    </>
  )
}

export default AddNewMember
