import React from 'react'
import * as Checkbox from '@radix-ui/react-checkbox'
import { memo, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { styled } from '../../stitches.config'
import Flex from '../flex/Flex'
import ReactPaginate from 'react-paginate'
import DropdownWithoutLabel from '../dropdownSelect/dropdownWithoutLabel'
import { BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'

export interface ISort {
  by: string
  order: 'asc' | 'desc' | undefined
}

export const ITEMS_PER_PAGE_OPTIONS = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
] as const

type Key = 'search' | 'filter' | 'offset' | 'show' | 'sortBy' | 'order' | 'show_archived'

const get = (urlSearchParams: URLSearchParams, key: Key, cacheKey?: string) => {
  return urlSearchParams.get(key) || (cacheKey && localStorage.getItem(`${cacheKey}.${key}`)) || ''
}

export const useDataTable = ({ initialFilterData, cacheKey }: { initialFilterData?: any; cacheKey?: string } = { initialFilterData: {} }) => {
  const urlSearchParams = new URLSearchParams(location.search)
  const search = get(urlSearchParams, 'search')
  const filters = get(urlSearchParams, 'filter', cacheKey)
  const offset = +get(urlSearchParams, 'offset')
  const itemsPerPage = +get(urlSearchParams, 'show', cacheKey) || ITEMS_PER_PAGE_OPTIONS[2].value
  const sortBy = get(urlSearchParams, 'sortBy')
  const order = get(urlSearchParams, 'order') as ISort['order']
  const showArchived = get(urlSearchParams, 'show_archived')

  const [selected, setSelected] = useState<number[]>([])

  const navigate = useNavigate()

  const setUrlParam = useCallback(
    (key: Key, value: string | number = '') => {
      const urlSearchParams = new URLSearchParams(location.search)

      const stringifiedValue = value.toString()
      if (stringifiedValue.trim()) {
        urlSearchParams.set(key, stringifiedValue)
        if (cacheKey) {
          localStorage.setItem(`${cacheKey}.${key}`, stringifiedValue)
        }
      } else {
        urlSearchParams.delete(key)
        localStorage.removeItem(`${cacheKey}.${key}`)
      }
      navigate({ search: urlSearchParams.toString() }, { replace: true })
    },
    [navigate]
  )

  const getFilterData = () => {
    try {
      return JSON.parse(decodeURIComponent(filters)) || initialFilterData
    } catch {
      return initialFilterData
    }
  }

  return { search, filters, offset, showArchived, itemsPerPage, sortBy, order, setUrlParam, getFilterData, selected, setSelected }
}

export const Toolbar = styled('div', {
  px: 24,
  py: 20,
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '1.25rem',
})

export const DataTable = styled('table', {
  borderSpacing: 0,
  '& *': {
    boxSizing: 'border-box',
  },
  width: '100%',
})
export const TableHead = styled('thead', {})
export const TableBody = styled('tbody', {
  overflow: 'auto',
})

export const Tr = styled('tr', {
  display: 'table-row',
  color: '$strong_graphite',
  'tbody &:nth-child(odd)': {
    backgroundColor: '#c4c4c41a',
  },
})
export const Th = styled('th', {
  px: 16,
  py: 24,
  textAlign: 'left',
  fontSize: 14,
  '&:first-child': {
    pl: 24,
  },
  '&:last-child': {
    pr: 24,
  },
})

export const Td = styled('td', {
  fontSize: 14,
  color: '$strong_graphite',
  px: 16,
  py: 8,
  '&:first-child': {
    pl: 24,
  },
  '&:last-child': {
    pr: 24,
  },
})

export const CheckboxRoot = styled(Checkbox.Root, {
  all: 'unset',
  width: 16,
  height: 16,
  borderRadius: 4,
  border: '1px solid $rich_moss',
  boxShadow: `0 2px 10px $text`,
  '&:hover': { backgroundColor: '$clear_silver' },
  '&:focus-visible': { boxShadow: `0 0 0 2px white, 0 0 0 4px $colors$rich_moss` },

  '&[data-state="checked"]': {
    backgroundColor: '$rich_moss',
  },
})

export const CheckboxIndicator = styled(Checkbox.Indicator, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
})

export const TableFooter = memo(({ offset, data, itemsPerPage, onPageChange, onItemsPerPageChange }: any) => {
  const { t } = useTranslation()
  return (
    <Flex align='center' justify='center' css={{ position: 'relative', width: '100%', pb: 32, gap: 24, flexWrap: 'wrap' }}>
      {!!data?.length && (
        <p>
          {offset + 1} - {Math.min(offset + itemsPerPage, data.length)} of {data.length}
        </p>
      )}
      {!!data?.length && (
        <ReactPaginate
          initialPage={offset / itemsPerPage}
          nextLabel={t('next')}
          onPageChange={onPageChange}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          pageCount={Math.ceil(data.length / itemsPerPage)}
          previousLabel={t('prev')}
          containerClassName='item-type-all-btn-container'
          pageClassName='item-type-btn'
          pageLinkClassName='item-type-link-btn'
          previousClassName='item-type-btn'
          previousLinkClassName='item-type-prev-btn'
          nextClassName='item-type-btn'
          nextLinkClassName='item-type-prev-btn'
          breakLabel='...'
          breakClassName='item-type-btn'
          breakLinkClassName='item-type-link-btn'
          activeClassName='item-type-active-link-btn'
        />
      )}
      <DropdownWithoutLabel
        options={ITEMS_PER_PAGE_OPTIONS}
        selected={ITEMS_PER_PAGE_OPTIONS.find(({ value }) => value === itemsPerPage)!}
        onChange={onItemsPerPageChange}
        toolTipTitle='Select Items per Page'
      />
    </Flex>
  )
})

export const toggleSortOrder = (sort: ISort) => {
  if (sort.order === 'asc') return { ...sort, order: 'desc' }
  if (sort.order === 'desc') return {}
  return { ...sort, order: 'asc' }
}

export const renderSortIcon = (sort: ISort, col: string) => {
  return (
    sort.by === col &&
    (sort.order === 'asc' ? <BsFillCaretUpFill className='flex-shrink-0 w-3 h-3' /> : <BsFillCaretDownFill className='flex-shrink-0 w-3 h-3' />)
  )
}
