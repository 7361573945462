import React from 'react'
import { Outlet } from 'react-router-dom'
import withTitle from '../../hoc/withTitle'

import i18next from '../../i18n/i18n'

const Containers = () => {
  return (
    <div className='organisation-settings-outer-container'>
      <Outlet />
    </div>
  )
}

export default withTitle(Containers, `${i18next.t('containers')} | ${i18next.t('appName')}`)
