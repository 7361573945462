import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from "react-toastify";
import LinkOpener from "../../components/Button/linkOpener";
import telegramIcon from '../../assets/images/telegram_icon.svg';
import downloadFileIcon from '../../assets/images/download_file_icon.svg';
import { formatDate } from "../../utils/helper";
import {t} from "i18next";

const showCopiedToast = () => {
  toast('Check report link copied to clipboard!', {
    position: 'bottom-center',
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  });
};

const handleSendEmail = (objectId: string, objectTitle: string, createdBy: string, createdOn: string, link: string) => {
  const subject = encodeURIComponent(`Emory: ${t('inspectionReport')}: ${objectId} - ${objectTitle}`);
  const body = encodeURIComponent(
    `${t('checkReportIsReady')}

${t('inspectionLink')}: ${link}

${t('inspectedBy')}: ${createdBy}
${t('createdOn')}: ${createdOn}`
  );
  window.location.href = `mailto:?subject=${subject}&body=${body}`;
};

const ReportItem = ({ report, objectId, objectTitle}: { report: any; objectId: string; objectTitle: string; }) => (
  <div>
    <div className='display_flex check-report-url-date-container'>
      <div className='display_flex share-report-container'>
        <CopyToClipboard text={report.clipboard_url}>
          <p onClick={showCopiedToast} className='cursor-pointer copy-to-clipboard-text'>
            {window.location.href.split('/dashboard/')[0] + '/cr/' +
          report.clipboard_url.split('/cr/')[1]}
          </p>
        </CopyToClipboard>
        <LinkOpener className='copy-to-clipboard-btn cursor_pointer' href={window.location.href.split('/dashboard/')[0] + '/cr/' +
          report.clipboard_url.split('/cr/')[1]} />
      </div>
      <div>
        <p className='check-report-date-text'>
          Generated on: <br />
          <span className='bold_text'>{report.created_at && formatDate(report.created_at)}</span>
        </p>
        <p className='check-report-date-text'>
          Version no: <span className='bold_text'>{report.version}</span>
        </p>
      </div>
    </div>

    <div className='display_flex share-report-options-container'>
      <div
        className='display_flex share-option-container'
        onClick={() => handleSendEmail(objectId, objectTitle, report.assignee_name, formatDate(report.created_at),
                                       report.clipboard_url)}
      >
        <img src={telegramIcon} className='share-option-icon' alt="Email" />
        <p className='share-option-text'>Email</p>
      </div>

      <a href={report.pdf_link} target='_blank' download className='download-link download-pdf-option' rel='noreferrer'>
        <div className='display_flex share-option-container'>
          <img src={downloadFileIcon} className='share-option-icon' alt="Download PDF" />
          <p className='share-option-text'>Download PDF</p>
        </div>
      </a>
    </div>
  </div>
);

export default ReportItem;
