import React, { useEffect } from 'react'
import { useState } from 'react'
import Button from '../../../components/Button/button'
import InputField from '../../../components/InputField/inputField'
import '../loginPage.scss'
import { useNavigate } from 'react-router-dom'
import { setPasswordValidation } from '../../../helpers/validations'
import Loader from '../../../components/Loader/loader'
import emoryLogo from '../../../assets/images/emory_logo.svg'
import variables from '../../themes.module.scss'
import { updatePassword } from '../../../api/apiCalls'
import './resetPassword.scss'
import withTitle from '../../../hoc/withTitle'

const ResetPassword = () => {
  const navigate = useNavigate()

  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [newPassword, setNewPassword] = useState<string>('')
  const [newPasswordError, setNewPasswordError] = useState<string>('')
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('')
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState<any>()
  const [disableForm, setDisableForm] = useState<boolean>(false)
  const [responseError, setResponseError] = useState<any>('')

  const onEnterNewPasswordHandler = (value: any) => {
    setNewPassword(value)
    setNewPasswordError(setPasswordValidation(value))
  }

  const onEnterConfirmNewPasswordHandler = (value: any) => {
    setConfirmNewPassword(value)
  }

  useEffect(() => {
    if (confirmNewPassword) {
      setConfirmNewPasswordError(newPassword === confirmNewPassword ? '' : 'Password does not match')
    } else {
      setConfirmNewPasswordError('')
    }
  }, [confirmNewPassword])

  const onSetNewpasswordClick = () => {
    const data = {
      password: newPassword,
      confirm_password: confirmNewPassword,
    }
    const token: any = window.location.pathname.split('/')[2]

    setShowLoader(true)
    updatePassword(data, token)
      .then((response) => {
        setShowLoader(false)
        // console.log(response)
        if (response.status === 200) {
          setNewPasswordError('')
          setConfirmNewPasswordError('')
          setNewPassword('')
          setConfirmNewPassword('')
          navigate('/login', { replace: true })
          setResponseError('')
        } else {
          // alert(response.data.errors.message)
          setResponseError('Invalid Reset Link')
          // console.log(response.data.errors.message)
          // setResponseError(response.data.errors.message)
        }
      })
      .catch((error) => {
        setShowLoader(false)
        alert(error?.response?.data?.errors?.code+' '+error?.response?.data?.errors?.message)
        console.log(error)
        navigate('/login', { replace: true })
      })
  }

  useEffect(() => {
    const token = window.location.pathname.split('/')[2]
    if (!token) {
      setDisableForm(true)
    } else {
      setDisableForm(false)
    }

    localStorage.removeItem('loggedIn')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('orgId')
    localStorage.removeItem('loggedInUser')
  }, [])

  return (
    <div className='login-page-container'>
      {showLoader && <Loader />}
      <div className='login-card-container'>
        <div className='login-page-emory-logo-container'>
          <img src={emoryLogo} className='login-page-emory-logo' />
        </div>
        <div className='login-details-outer-container'>
          <div className='login-page-input-field-container'>
            <InputField
              label='New Password'
              value={newPassword}
              placeholder='Enter new password'
              onChange={onEnterNewPasswordHandler}
              type='password'
              error={newPasswordError}
              className='login-inputs-style'
              disabled={disableForm}
            />
          </div>

          <div className='login-page-input-field-container'>
            <InputField
              label='Confirm New Password'
              value={confirmNewPassword}
              placeholder='Confirm new password'
              onChange={onEnterConfirmNewPasswordHandler}
              type='password'
              error={confirmNewPasswordError}
              disabled={disableForm}
              className='login-inputs-style'
            />
          </div>
        </div>

        <div className='login-btn-container'>
          <Button
            onClick={onSetNewpasswordClick}
            disabled={disableForm || !newPassword || !confirmNewPassword || newPassword !== confirmNewPassword}
          >
            Reset Password
          </Button>
        </div>
        {responseError && <p className='error-msg-style reset-password-error-msg'>{responseError}</p>}
      </div>
    </div>
  )
}

export default withTitle(ResetPassword, 'Emory - Reset Password')
