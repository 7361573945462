import React from 'react'
import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { HiCheck, HiChevronDown } from 'react-icons/hi2'
import { slugify } from '../../utils/helper'
import { DropdownProps } from '../../interfaces/dropdown'

const Dropdown: React.FC<DropdownProps> = ({ label, placeholder, options, onChange, value, id }) => (
  <div className="flex flex-col gap-[14px]">
    <label htmlFor="options" className="block text-sm font-medium text-black">
      {label}
    </label>
    <Listbox value={value} onChange={onChange} disabled={options?.length === 0}>
      {({ open, disabled }) => (
        <div className="relative">
          <Listbox.Button
            id={id || slugify(label)}
            className={`relative w-full cursor-pointer rounded-md bg-white h-14 py-2 pl-3 pr-10 text-left outline-none hover:ring-digital_mint hover:ring-2 focus:ring-2 focus:ring-digital_mint transition-all ease-in-out duration-300 transform border-1 border-calm_platinum/10 sm:text-sm ${
              open ? 'ring-digital_mint ring-2' : ''
            }
            ${disabled ? 'cursor-not-allowed hover:ring-calm_platinum/20' : ''}
            `}
          >
            {options?.length > 0 ? (
              <>
                <span className="block whitespace-nowrap">{value?.label || placeholder}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  {open ? (
                    <HiChevronDown className="h-5 w-5 text-black transform rotate-180" aria-hidden="true" />
                  ) : (
                    <HiChevronDown className="h-5 w-5 text-black" aria-hidden="true" />
                  )}
                </span>
              </>
            ) : (
              <span className="block truncate">No options available!</span>
            )}
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 px-0 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm list-none z-10">
              {options.map((option, optionIdx) => (
                <Listbox.Option
                  key={optionIdx}
                  className={({ active }) =>
                    `relative cursor-pointer mx-1 select-none py-2 pl-10 pr-4 rounded-md ${
                      active ? 'bg-digital_mint/10 text-rich_moss' : 'text-gray-900'
                    }`
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <>
                      <span className={`block whitespace-nowrap ${selected ? 'font-medium' : 'font-normal'}`}>
                        {option.label}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-digital_mint">
                          <HiCheck className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  </div>
)

export default Dropdown
