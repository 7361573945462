import React, { useContext } from 'react'
import { useEffect, useState } from 'react'
import { getItemTypes, updateItemType } from '../../api/apiCalls'
import '../AssignItemsPopup/assignItemsPopup.scss'
import './editItemTypePopup.scss'
import Button from '../Button/button'
import { useTranslation } from 'react-i18next'
import {useBasicFieldName} from "../../hooks/useBasicFieldName";
import { showAlertForError } from '../../helpers/validations'

const EditItemTypePopup = (props: any) => {
  const basicFieldName = useBasicFieldName();
  const [itemTypes, setItemTypes] = useState<any>(null)
  const [selectedItemType, setSelectedItemType] = useState<any>('')
  const [selectedItemTypeId, setSelectedItemTypeId] = useState<any>('')
  const [focusedItem, setFocusedItem] = useState<any>(null)

  useEffect(() => {
    setSelectedItemType(props.selectedItemType)
    document.body.style.overflow = 'hidden'
    // console.log(props.idList)
    props.showLoader(true)
    let token = localStorage.getItem('accessToken')
    getItemTypes(token, '')
      .then(response => {
        // console.log(response)
        props.showLoader(false)
        let tempArr: any = []
        if (response.item_types && response.item_types.length > 0) {
          response.item_types.forEach((item: any) => {
            let data = {
              value: item.id,
              label: item.name,
            }
            tempArr.push(data)
          })
          setItemTypes(tempArr)
        }
      })
      .catch(error => {
        props.showLoader(false)
        console.log(error)
        showAlertForError(error)
      })

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  const onItemClick = (item: any, id: any) => {
    setSelectedItemType(item)
    setSelectedItemTypeId(id)
  }

  const onSaveClick = () => {
    let token = localStorage.getItem('accessToken')
    let data = {
      id: props.itemId,
      item_type_id: selectedItemTypeId,
    }
    props.showLoader(true)
    updateItemType(data, token)
      .then(response => {
        props.showLoader(false)
        // console.log(response)
        if (response.status === 201) {
          props.refresh()
          props.dismissHandler()
        } else {
          props.dismissHandler()
        }
      })
      .catch(error => {
        props.showLoader(false)
        props.dismissHandler()
        showAlertForError(error)
        // console.log(error)
      })
  }

  const { t } = useTranslation()

  return (
    <div>
      <div className="assignee-list-component-outer-container" id="assignee-list-component-outer-container">
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 999999999,
          }}
        >
          <div className="assignee-list-card-outer-container assignee-list-card-outer-container-no-top-padding">
            <div className="assignee-list-card-sticky-top-container">
              <div className="display_flex assign-title-close-icon-container">
                <p className="assign-items-title">
                  Select New {basicFieldName('Item Type', 'item')}
                </p>
                {/* <p className="cross-icon cursor_pointer" onClick={() => props.dismissHandler()}>
                                    x
                                </p> */}
              </div>
            </div>

            <div>
              {itemTypes &&
                itemTypes.map((item: any) => {
                  return (
                    <div
                      // className={focusedItem === item.value ? "display_flex assignee-names-list-outer-container cursor_pointer focused-item-style" : "display_flex assignee-names-list-outer-container cursor_pointer"}
                      className="display_flex assignee-names-list-outer-container cursor_pointer"
                      onClick={() => onItemClick(item.label, item.value)}
                      onMouseOver={() => setFocusedItem(item.value)}
                      onMouseLeave={() => setFocusedItem(null)}
                    >
                      <div
                        // className="display_flex assignee-names-individual-container"
                        key={item.value}
                        className={
                          focusedItem === item.value
                            ? 'display_flex assignee-names-individual-container cursor_pointer focused-item-style'
                            : 'display_flex assignee-names-individual-container cursor_pointer'
                        }
                      >
                        {/* <img src={assigneeIcon} className="assignee-icon"/> */}
                        <p className="assigne-list-name">{item.label}</p>
                      </div>
                      {item.label === selectedItemType ? (
                        <p
                          className="reassign-btn-text"
                          // className={focusedItem === item.value ? "reassign-btn-text cursor_pointer focused-item-style" : "reassign-btn-text cursor_pointer"}
                        >
                          Selected
                        </p>
                      ) : null}
                    </div>
                  )
                })}
            </div>
          </div>
          <div className="display_flex edit-item-type-popup-btn-container">
            <Button
              backgroundColor="#EEEEEE"
              textColor="#333333"
              padding="0.75rem 2rem"
              fontSize="12px"
              height="fit-content"
              width="auto"
              onClick={() => props.dismissHandler()}
            >
              Cancel
            </Button>
            <Button
              padding="0.75rem 2rem"
              fontSize="12px"
              height="fit-content"
              width="auto"
              leftMargin="1.25rem"
              onClick={onSaveClick}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      )
    </div>
  )
}

export default EditItemTypePopup
