import React, { useContext, useState, useEffect } from 'react'
import 'react-tabs/style/react-tabs.css'
import './dashboard.scss'
import statsIcon from '../../assets/images/stats_tab_icon.svg'
import membersIcon from '../../assets/images/members_tab_icon.svg'
import containersIcon from '../../assets/images/containers_tab_icon.svg'
import itemsIcon from '../../assets/images/items_tab_icon.svg'
import organisationSettingsIcon from '../../assets/images/organisation_settings_tab_icon.svg'
import profileIcon from '../../assets/images/user_profile_icon.svg'
import dropdownIcon from '../../assets/images/dropdown_arrow_icon.svg'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'
import emoryLogo from '../../assets/images/emory_logo.svg'
import playStoreIcon from '../../assets/images/play-store-icon.png';
import appStoreIcon from '../../assets/images/app-store-badge.svg';
import Loader from '../../components/Loader/loader'
import { ScreenOrderForTranslationContext, UserDetailsContext } from '../../contexts/userDetailsContext'
import { toast } from 'react-toastify'
import UserAccount from '../userAccount/userAccount'
import { UserPermissionsContext } from '../../contexts/userPermissionsContext'
import { useTranslation } from 'react-i18next'
import DropdownSelect from '../../components/dropdownSelect/dropdownSelect'
import { updateCompanyDetails } from '../../api/apiCalls'
import { showAlertForError } from '../../helpers/validations'

const Dashboard = (props: any) => {
  const navigate = useNavigate()
  const { translations, translationsLoaded } = useContext(ScreenOrderForTranslationContext)
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [showAccountPopup, setShowAccountPopup] = useState<boolean>(false)
  const [preferredLanguage, setPreferredLanguage] = useState('')
  const { isSuccess, isError, data: userDetails, error, refetch } = useContext(UserDetailsContext)
  const { ...userPermissionsData } = useContext(UserPermissionsContext)
  const userPermissions = userPermissionsData?.data
  const { t } = useTranslation()
  const onProfileOptionsClick = () => { }

  const onLogoutClick = () => {
    localStorage.clear()
    navigate('/login', { replace: true })
  }

  const onAccountClick = () => {
    setShowAccountPopup(true)
  }

  const onAccountPageDismiss = () => {
    setShowAccountPopup(false)
  }

  useEffect(() => {
    refetch()
    userPermissionsData.refetch()
  }, [])

  useEffect(() => {
    if (isSuccess) {
      setPreferredLanguage(userDetails?.preferred_language)
    }
    if (isError) {
      console.error('Error loading data:', error);
    }
  }, [isSuccess, isError, userDetails, error]);

  const languageOptions = [
    { label: 'English', value: 'en', },
    { label: 'German', value: 'de', },
    { label: 'Spanish', value: 'es', },
  ]

  const showSuccessToast = () => {
    toast.success('Language changed!', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }
  const showFailureToast = () => {
    toast.error('An error occurred while changing language!', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  const onLangChange = (val: any) => {
    const token = localStorage.getItem('accessToken')
    setPreferredLanguage(val)
    const orgId = userPermissions && userPermissions.data.organization_id
    const data = {
      preferred_language: val
    }

    updateCompanyDetails(data, orgId, token)
      .then(response => {
        refetch()
        if (response.status === 200) {
          showSuccessToast()
        } else {
          showFailureToast()
        }
      })
      .catch(error => {
        console.log(error)
        showAlertForError(error)
      })
  }

  return (
    <>
      {showAccountPopup && <UserAccount dismissHandler={onAccountPageDismiss} showLoader={setShowLoader} />}
      {showLoader && <Loader />}
      <div className='dashboard-outer-container' id='dashboard-outer-container'>
        <div className='dashboard-tabs-container'>
          <div className='dashboard-side-panel-merchant-logo-container'>
            {userDetails && userDetails.wide_brand_logo && (
              <img src={userDetails.wide_brand_logo} className='dashboard-side-panel-merchant-logo' />
            )}
          </div>
          <div>
            <NavLink
              to=''
              end
              className={({ isActive }) =>
                isActive ? 'display_flex side-panel-tabs-btn-container side-panel-selected-tab' : 'display_flex side-panel-tabs-btn-container'
              }
            >
              {({ isActive }) => (
                <>
                  <img src={statsIcon} className={isActive ? 'tab-icon selected-tab-icon' : 'tab-icon'} />
                  <p className={isActive ? 'side-panel-tabs-btn-text side-panel-tabs-btn-selected-text' : 'side-panel-tabs-btn-text'}>
                    {t('stats')}
                  </p>
                </>
              )}
            </NavLink>

            {userPermissions &&
              (userPermissions.data.manage_organization ||
                userPermissions.data.manage_organization_access_flags ||
                userPermissions.data.manage_organization_item_type ||
                userPermissions.data.manage_organization_container) && (
                <NavLink
                  to='org'
                  className={({ isActive }) =>
                    isActive
                      ? 'display_flex side-panel-tabs-btn-container side-panel-selected-tab'
                      : 'display_flex side-panel-tabs-btn-container'
                  }
                >
                  {({ isActive }) => (
                    <>
                      <img src={organisationSettingsIcon} className={isActive ? 'tab-icon selected-tab-icon' : 'tab-icon'} />
                      <p className={isActive ? 'side-panel-tabs-btn-text side-panel-tabs-btn-selected-text' : 'side-panel-tabs-btn-text'}>
                        {t('organisationSettings')}
                      </p>
                    </>
                  )}
                </NavLink>
              )}

            {userPermissions && userPermissions.data.manage_member && (
              <NavLink
                to='members'
                className={({ isActive }) =>
                  isActive ? 'display_flex side-panel-tabs-btn-container side-panel-selected-tab' : 'display_flex side-panel-tabs-btn-container'
                }
              >
                {({ isActive }) => (
                  <>
                    <img src={membersIcon} className={isActive ? 'tab-icon selected-tab-icon' : 'tab-icon'} />
                    <p className={isActive ? 'side-panel-tabs-btn-text side-panel-tabs-btn-selected-text' : 'side-panel-tabs-btn-text'}>
                      {t('users')}
                    </p>
                  </>
                )}
              </NavLink>
            )}

            {userPermissions && userPermissions.data.manage_container && (
              <NavLink
                to='containers'
                className={({ isActive }) =>
                  isActive ? 'display_flex side-panel-tabs-btn-container side-panel-selected-tab' : 'display_flex side-panel-tabs-btn-container'
                }
              >
                {({ isActive }) => (
                  <>
                    <img src={containersIcon} className={isActive ? 'non-black-tab-icon selected-non-black-tab-icon' : 'non-black-tab-icon'} />
                    <p className={isActive ? 'side-panel-tabs-btn-text side-panel-tabs-btn-selected-text' : 'side-panel-tabs-btn-text'}>
                    {translations['containers']}
                    </p>
                  </>
                )}
              </NavLink>
            )}

            <NavLink
              to='items'
              className={({ isActive }) =>
                isActive ? 'display_flex side-panel-tabs-btn-container side-panel-selected-tab' : 'display_flex side-panel-tabs-btn-container'
              }
            >
              {({ isActive }) => (
                <>
                  <img src={itemsIcon} className={isActive ? 'non-black-tab-icon selected-non-black-tab-icon' : 'non-black-tab-icon'} />
                  <p className={isActive ? 'side-panel-tabs-btn-text side-panel-tabs-btn-selected-text' : 'side-panel-tabs-btn-text'}>
                  {translations['items']}
                  </p>
                </>
              )}
            </NavLink>
          </div>

          <div className='powered-by-emory-container'>
            <p className='powered-by-text'>Powered by</p>
            <img src={emoryLogo} />
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center', // Vertically center the icons
            marginLeft: '20px',
            gap: '10px', // Adds spacing between the icons
            width: '150px'
          }}>
            <img
              src={appStoreIcon}
              alt="App Store"
              style={{
                height: '100px',
                cursor: 'pointer',
                width: 'auto' // Ensures proportional scaling
              }}
              onClick={() => window.open('https://apps.apple.com/in/app/emory-pro/id6443503759', '_blank')}
            />
            <img
              src={playStoreIcon}
              alt="Play Store"
              style={{
                height: '118px',
                cursor: 'pointer',
                width: 'auto' // Ensures proportional scaling
              }}
              onClick={() => window.open('https://play.google.com/store/apps/details?id=pro.emory.inspections', '_blank')}
            />
          </div>
        </div>
        <div className='dashboard-contents-outer-container'>
          <div className='logged-in-member-name-top-container' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '10px' }}>
            {userPermissions?.data.manage_organization && <>
              <DropdownSelect
                options={languageOptions}
                onChange={(val: any) => onLangChange(val)}
                value={preferredLanguage}
                inputHeight="20px"
                noBg={true}
                inputClassName='language-dropdown'
                langIcon={true}/>
                <div style={{marginRight: '15px'}}></div>
            </>}
            <Popup
              className='profile-options-dropdown'
              trigger={<div className='display_flex member-name-icon-container' style={{alignItems: 'center'}}>
                <img src={profileIcon} className='member-profile-icon'
                     style={{width: '40px', height: '40px'}}/> {/* Set a specific size for consistency */}
                <p className='logged-in-member-name-text' style={{
                  margin: '0 10px',
                  lineHeight: '40px'
                }}>{userPermissions?.data.user_name}</p> {/* Center vertically with line height */}
                <img src={dropdownIcon} className='dropdown-icon' onClick={onProfileOptionsClick}
                     style={{cursor: 'pointer'}}/>
              </div>}
              position='bottom right'
            >
              <div className='profile-dropdown-options-container'>
                <div className='profile-options-item' onClick={onAccountClick}>
                  Account
                </div>
                <div className='profile-options-item' onClick={onLogoutClick}>
                  Logout
                </div>
              </div>
            </Popup>
          </div>

          <div className='dashboard-contents-bottom-container'>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
