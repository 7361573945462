import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import de from 'date-fns/locale/de';

registerLocale('es', es)
registerLocale('de', de)

// @ts-ignore
import resources from './translations.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: Object.fromEntries(Object.entries(resources).map(([key, value]) => [key, { translation: value }])) as any,
    fallbackLng: 'en',
  }).then(() => {
    setDefaultLocale(i18n.language)
  })

export default i18n
