import React, { useEffect, useState } from 'react'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import FilterAccordion from '../accordion/container'
import { Button } from '../Button'
import ScrollArea from '../scrollArea'
import { IFilterData, IOptions } from '../../pages/containers/containersList/containersList'
import moment from 'moment'
import { getContainerAssigneeList, getContainerStatusList, getContainerYardLocationList } from '../../api/apiCalls'
import { HiOutlineFilter } from 'react-icons/hi'
import { useTranslation } from 'react-i18next'

const MenuItem = ({ children, ...props }: { children: React.ReactNode }) => (
  <DropdownMenuPrimitive.Item className='w-full rdx-highlighted:outline-none' onSelect={e => e.preventDefault()}>
    {children}
  </DropdownMenuPrimitive.Item>
)

const FilterDropdown = ({
  children,
  setFilterCount: _setFilterCount,
  filterCount: _filterCount,
  filterData: _filterData,
  setFilterData: _setFilterData,
  applyFilter,
  onClearAll,
  ...props
}: {
  children?: React.ReactNode
  setFilterCount?: (count: number) => void
  filterCount?: number
  filterData?: IFilterData
  setFilterData?: (data: IFilterData) => void
  applyFilter: (data: IFilterData) => void
  onClearAll: () => void
}) => {
  const [filterCount, setFilterCount] = useState(0)
  const [filterData, setFilterData] = useState<any>(_filterData)

  const getContainerFilterDate = (field: string) => {
    const cacheData = localStorage.getItem('containerFilterCriteria')
    if (cacheData) {
      const date = JSON.parse(cacheData)[field]
      if (date.length > 0) {
        const d1 = new Date(date[0])
        const d2 = new Date(date[1])
        return `${d1.getDate()}-${d1.getMonth() + 1}-${d1.getFullYear()} ~ ${d2.getDate()}-${d2.getMonth() + 1}-${d2.getFullYear()}`
      }
    }
    return ''
  }
  const [isOpen, setIsOpen] = useState(false)
  const [isNewFilterConditions, setIsNewFilterConditions] = useState(false)
  const [tempFilterData, setTempFilterData] = useState({
    arrivalDate: getContainerFilterDate('arrivalDate'),
    departureDate: getContainerFilterDate('departureDate'),
  })
  const [assigneeList, setAssigneeList] = useState<string[]>([])
  const [yardLocationList, setYardLocationList] = useState<string[]>([])
  const [inspectionStatusList, setInspectionStatusList] = useState<string[]>([])

  useEffect(() => {
    let count = 0
    Object.keys(filterData).forEach(key => {
      if (`${filterData[key as keyof IFilterData]}`.length > 0) {
        count += 1
      }
    })
    setFilterCount(count)
    setIsNewFilterConditions(count > 0)
  }, [filterData])

  useEffect(() => {
    const getContainerStatus = async () => {
      const token = localStorage.getItem('accessToken')
      try {
        if (token) {
          const res = await getContainerStatusList(token)
          if (res.container_status) {
            setInspectionStatusList(res.container_status)
          }
        } else {
          window.location.href = '/login'
        }
      } catch (err: any) {
        alert(err.message)
      }
    }

    const getContainerAssignee = async () => {
      const token = localStorage.getItem('accessToken')
      try {
        if (token) {
          const res = await getContainerAssigneeList(token)
          if (res.assignees) {
            setAssigneeList(res.assignees)
          }
        } else {
          window.location.href = '/login'
        }
      } catch (err: any) {
        alert(err.message)
      }
    }

    const getContainerYardLocation = async () => {
      const token = localStorage.getItem('accessToken')
      try {
        if (token) {
          const res = await getContainerYardLocationList(token)
          if (res.yard_location) {
            setYardLocationList(res.yard_location)
          }
        } else {
          window.location.href = '/login'
        }
      } catch (err: any) {
        alert(err.message)
      }
    }

    getContainerStatus()
    getContainerAssignee()
    getContainerYardLocation()
  }, [])

  const validateAndApplyTempFilterData = (tempDateArr: typeof tempFilterData) => {
    const { departureDate, arrivalDate } = tempDateArr
    const departureDateArr = departureDate.length > 0 ? departureDate.split(' ~ ') : []
    const arrivalDateArr = arrivalDate.length > 0 ? arrivalDate.split(' ~ ') : []
    if (departureDateArr.length === 2 && !moment(departureDateArr[0], 'DD-MM-YYYY').isValid()) {
      alert('Invalid starting Creation Date, please enter in DD-MM-YYYY format')
      return
    }
    if (departureDateArr.length === 2 && !moment(departureDateArr[1], 'DD-MM-YYYY').isValid()) {
      alert('Invalid end Creation Date, please enter in DD-MM-YYYY format')
      return
    }
    if (arrivalDateArr.length === 2 && !moment(arrivalDateArr[0], 'DD-MM-YYYY').isValid()) {
      alert('Invalid starting Arrival Date, please enter in DD-MM-YYYY format')
      return
    }
    if (arrivalDateArr.length === 2 && !moment(arrivalDateArr[1], 'DD-MM-YYYY').isValid()) {
      alert('Invalid end Arrival Date, please enter in DD-MM-YYYY format')
      return
    }

    if (departureDateArr.length === 2 && moment(departureDateArr[0], 'DD-MM-YYYY').isAfter(moment(departureDateArr[1], 'DD-MM-YYYY'))) {
      alert('Creation Date From should be less than Creation Date To')
      return
    }

    if (arrivalDateArr.length === 2 && moment(arrivalDateArr[0], 'DD-MM-YYYY').isAfter(moment(arrivalDateArr[1], 'DD-MM-YYYY'))) {
      alert('Arrival Date From should be less than Arrival Date To')
      return
    }

    const newDepartureDateFrom: any =
      departureDateArr.length === 2
        ? moment(departureDateArr[0], 'DD-MM-YYYY').toDate() > new Date()
          ? new Date()
          : moment(departureDateArr[0], 'DD-MM-YYYY').toDate()
        : ''
    const newCreationDateTo: any =
      departureDateArr.length === 2
        ? moment(departureDateArr[1], 'DD-MM-YYYY').toDate() > new Date()
          ? new Date()
          : moment(departureDateArr[1], 'DD-MM-YYYY')
        : ''
    const newArrivalDateFrom: any =
      arrivalDateArr.length === 2
        ? moment(arrivalDateArr[0], 'DD-MM-YYYY').toDate() > new Date()
          ? new Date()
          : moment(arrivalDateArr[0], 'DD-MM-YYYY').toDate()
        : ''
    const newArrivalDateTo: any =
      arrivalDateArr.length === 2
        ? moment(arrivalDateArr[1], 'DD-MM-YYYY').toDate() > new Date()
          ? new Date()
          : moment(arrivalDateArr[1], 'DD-MM-YYYY').toDate()
        : ''

    const tempCreationDate =
      newDepartureDateFrom !== '' && newCreationDateTo !== ''
        ? [
            newDepartureDateFrom._isAMomentObject ? newDepartureDateFrom._d : newDepartureDateFrom,
            newCreationDateTo._isAMomentObject ? newCreationDateTo._d : newCreationDateTo,
          ]
        : ''
    if (newDepartureDateFrom !== '' && newCreationDateTo !== '') {
      setFilterData({
        ...filterData,
        departureDate: tempCreationDate as unknown as Date,
      })
    }

    const tempArrivalDate =
      newArrivalDateFrom !== '' && newArrivalDateTo !== ''
        ? [
            newArrivalDateFrom._isAMomentObject ? newArrivalDateFrom._d : newArrivalDateFrom,
            newArrivalDateTo._isAMomentObject ? newArrivalDateTo._d : newArrivalDateTo,
          ]
        : ''
    if (newArrivalDateFrom !== '' && newArrivalDateTo !== '') {
      setFilterData({
        ...filterData,
        arrivalDate: tempArrivalDate as unknown as Date,
      })
    }
    setIsOpen(false)
    applyFilter({
      ...filterData,
      departureDate: tempCreationDate ? tempCreationDate : filterData.departureDate || '',
      arrivalDate: tempArrivalDate ? tempArrivalDate : filterData.arrivalDate || '',
    })
    setTempFilterData({
      ...tempFilterData,
      departureDate: '',
      arrivalDate: '',
    })
  }

  const handleClearAll = () => {
    setFilterData({
      arrivalDate: '' as unknown as Date,
      departureDate: '' as unknown as Date,
      assigneeList: [],
      yardLocationList: [],
      inspectionStatusList: [],
    })
    setIsNewFilterConditions(true)
    onClearAll()
    setIsOpen(false)
  }

  const { t } = useTranslation()
  return (
    <DropdownMenuPrimitive.Root open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DropdownMenuPrimitive.Trigger asChild className='p-0 border-none outline-none ring-0'>
        <Button>
          <HiOutlineFilter className='w-4 h-4' /> {t('filters')}
          {filterCount > 0 ? (
            <span className='flex items-center justify-center w-5 h-5 text-xs font-semibold bg-white rounded-full text-rich_moss'>
              {filterCount}
            </span>
          ) : null}
        </Button>
      </DropdownMenuPrimitive.Trigger>
      <DropdownMenuPrimitive.Portal>
        <DropdownMenuPrimitive.Content
          sideOffset={2}
          collisionPadding={10}
          className='flex flex-col items-start justify-start gap-3 px-4 py-6 transition duration-300 ease-in-out transform bg-white rounded-lg shadow-lg text-strong_graphite peer font-nunito'
        >
          <div className='flex items-start justify-between w-full gap-5'>
            <div className='flex flex-col gap-1 mb-4 w-fit'>
              <span className='text-lg font-bold leading-6'>{t('filters')}</span>
              <span className='h-[1px] bg-strong_graphite w-full' />
            </div>
            <div className='flex items-center gap-2'>
              <Button intent='secondary' size='small' disabled={filterCount === 0} onClick={handleClearAll}>
                Clear All
              </Button>
              <Button
                intent='primary'
                size='small'
                disabled={!isNewFilterConditions}
                onClick={() => {
                  setIsOpen(false)
                  setIsNewFilterConditions(false)
                  validateAndApplyTempFilterData(tempFilterData)
                }}
              >
                Apply
              </Button>
            </div>
          </div>
          <ScrollArea rootClassName='h-80'>
            <MenuItem>
              <FilterAccordion
                filterData={filterData as unknown as IFilterData}
                onFilterDataChange={(data: IFilterData) => setFilterData(data as unknown as IFilterData)}
                tempFilterData={tempFilterData}
                onTempFilterDataChange={(data: typeof tempFilterData) => setTempFilterData(data)}
                options={{ assigneeList, yardLocationList, inspectionStatusList }}
                setIsNewFilterConditions={setIsNewFilterConditions}
              />
            </MenuItem>
          </ScrollArea>
          <DropdownMenuPrimitive.Arrow className='fill-rich_moss' />
        </DropdownMenuPrimitive.Content>
      </DropdownMenuPrimitive.Portal>
    </DropdownMenuPrimitive.Root>
  )
}

export default FilterDropdown
