import { API_CONSTANTS } from '../configs/constants'
import { del, delWithConfig, get, patch, post, put, putWithAuth } from '../api/apiTemplate'
import axios from 'axios'
import i18n from '../i18n/i18n'

const {
  SIGN_IN_ENDPOINT,
  GET_USER_DETAILS_ENDPOINT,
  GET_ORG_DETAILS_ENDPOINT,
  UPDATE_COMPANY_DETAILS_ENDPOINT,
  UPDATE_PRIMARY_CONTACT_ENDPOINT,
  UPDATE_ACCESS_FLAGS_ENDPOINT,
  ORGANIZATION_ITEM_TYPE_DATA_ENDPOINT,
  GET_DATA_TYPES_ENDPOINT,
  GET_ALL_ITEMS_LIST,
  GET_ITEM_DETAIL,
  GET_SCREENORDER_TRANSLATIONS,
  GET_ASSIGNEE_LIST,
  ASSIGN_ITEM_ENDPOINT,
  EDIT_ITEM_ENDPOINT,
  EDIT_CONTAINER,
  ADD_NEW_ITEM,
  GET_ITEM_TYPES,
  GET_ITEM_STATUS_LIST,
  GET_CONTAINER_STATUS_LIST,
  GET_CONTAINER_ASSIGNEE_LIST,
  GET_CONTAINER_YARD_LOCATION_LIST,
  GET_CONTAINER_FILTER_OPTIONS,
  GET_MEMBERS_ENDPOINT,
  GET_MARKINGS_ENDPOINT,
  GET_MEMBER_DETAILS,
  GET_CONTAINER_LIST,
  GET_DEFAULT_INSPECTION_FIELDS,
  UPDATE_CONTAINER_SETTINGS_ENDPOINT,
  UPDATE_ITEM_SETTINGS_ENDPOINT,
  GET_USER_ROLES,
  UPDATE_MEMBER_DETAILS,
  CREATE_MEMBER_ENDPOINT,
  GET_CONTAINER_DETAILS,
  ASSIGN_CONTAINER_ENDPOINT,
  GET_CONTAINER_ITEMS,
  SEND_PASSWORD_RESET_LINK,
  UPDATE_PASSWORD_ENDPOINT,
  SUSPEND_MEMBER_ENDPOINT,
  DELETE_MEMBER_ENDPOINT,
  CREATE_ITEM_CHECK_REPORT_ENDPOINT,
  GET_CHECK_REPORT_V2,
  CREATE_CONTAINER_CHECK_REPORT_ENDPOINT,
  STATS_DATA_ENDPOINT,
  SA_STATS_DATA_ENDPOINT,
  SUPERADMIN_SIGN_IN_ENDPOINT,
  SUPERADMIN_GET_ALL_ORGANISATIONS,
  SUPERADMIN_GET_MEMBERS,
  LOGGED_IN_USER_DETAILS,
  LOGGED_IN_USER_PERMISSIONS,
  BATCH_UPLOAD_ITEMS_ENDPOINT,
  BATCH_UPLOAD_CONTAINERS_ENDPOINT,
  BATCH_UPLOAD_USERS_ENDPOINT,
  EDIT_ITEM_TYPE_ENDPOINT,
  GET_STAGE_LIST,
  CREATE_NEW_STAGE,
  GET_SIGNED_URL,
  ARCHIVE_CONTAINER,
  ARCHIVE_ITEM,
  UNARCHIVE_CONTAINER,
  UNARCHIVE_ITEM,
  CREATE_UPDATE_MARKINGS_ENDPOINT,
  SIGNUP_ENDPOINT,
  GET_ALL_ITEM_LOCATIONS,
  TRACKING_CONTAINER
} = API_CONSTANTS

const configWithoutAuth = {
  headers: {
    'Content-Type': 'application/json',
    lang: i18n.language,
  },
}

const loginConfigWithoutAuth = {
  headers: {
    'Content-Type': 'application/json',
    source: 'web',
    lang: i18n.language,
  },
}

const configWithAuth = (token: string) => {
  return {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
      lang: i18n.language,
    },
  }
}

const uploadFilesConfigWithAuth = (token: string) => {
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
      lang: i18n.language,
    },
  }
}

export const signInUser = (data: any) => {
  return post(`${SIGN_IN_ENDPOINT}`, data, loginConfigWithoutAuth)
}

export const signup = (data: any) => {
  return post(`${SIGNUP_ENDPOINT}`, data, loginConfigWithoutAuth)
}

export const superAdminLogin = (data: any) => {
  return post(`${SUPERADMIN_SIGN_IN_ENDPOINT}`, data, configWithoutAuth)
}

export const getUserDetails = (token: any) => {
  const config = configWithAuth(token)
  return get(`${GET_USER_DETAILS_ENDPOINT}`, config)
}

export const getOrgDetails = (token: any) => {
  const config = configWithAuth(token)
  return get(`${GET_ORG_DETAILS_ENDPOINT}`, config)
}

export const updateCompanyDetails = (data: any, orgId: any, token: any) => {
  const config = configWithAuth(token)
  return putWithAuth(`${UPDATE_COMPANY_DETAILS_ENDPOINT}` + orgId, data, config)
}

export const addNewOrganisation = (data: any, token: any) => {
  const config = configWithAuth(token)
  return post(`${GET_USER_DETAILS_ENDPOINT}`, data, config)
}

export const updateOrganisation = (orgId: any, data: any, token: any) => {
  const config = configWithAuth(token)
  return putWithAuth(`${GET_USER_DETAILS_ENDPOINT}` + '?id=' + orgId, data, config)
}

export const updatePrimaryContact = (data: any, orgId: any, token: any) => {
  const config = configWithAuth(token)
  return putWithAuth(`${UPDATE_PRIMARY_CONTACT_ENDPOINT}` + orgId, data, config)
}

export const updateAccessFlags = (data: any, id: any, token: any) => {
  const config = configWithAuth(token)
  return putWithAuth(`${UPDATE_ACCESS_FLAGS_ENDPOINT}` + id, data, config)
}

export const updateOrgItemTypes = (data: any, orgId: any, token: any) => {
  const config = configWithAuth(token)
  return putWithAuth(`${ORGANIZATION_ITEM_TYPE_DATA_ENDPOINT}` + orgId, data, config)
}

export const getOrgItemTypeData = (itemTypeId: any, _token: any = '') => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  return get(`${ORGANIZATION_ITEM_TYPE_DATA_ENDPOINT}` + itemTypeId, config)
}

export const getDataTypes = (_token: any = '') => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  return get(`${GET_DATA_TYPES_ENDPOINT}`, config)
}

export const getAllItemsList = (token: any, searchParam: any) => {
  const config = configWithAuth(token)
  if (searchParam !== '') {
    return get(`${GET_ALL_ITEMS_LIST}` + '?search=' + searchParam, config)
  } else {
    return get(`${GET_ALL_ITEMS_LIST}`, config)
  }
}

export const getFilteredItemsList = (_token: any, searchParam: any, filterParam: any, showArchived: boolean) => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  const search = new URLSearchParams({
    ...(filterParam && { filter: filterParam }),
    ...(searchParam && { search: searchParam }),
    ...(showArchived && { show_archived: showArchived }),
  })

  return get(`${GET_ALL_ITEMS_LIST}?${search.toString()}`, config)
}

export const getItemDetail = (itemId: any, _token?: any) => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  return get(`${GET_ITEM_DETAIL}` + itemId, config)
}

export const getScreenOrderForTranslations = (_token?: any) => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  return get(`${GET_SCREENORDER_TRANSLATIONS}`, config)
}

export const getAssigneeList = (_token: any = '', isAllowed: boolean = true) => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  if(isAllowed) {
    return get(`${GET_ASSIGNEE_LIST}`, config)
  }
  else {
    return null
  }
}

export const assignItemsToUser = (data: any, token: any) => {
  const config = configWithAuth(token)
  return post(`${ASSIGN_ITEM_ENDPOINT}`, data, config)
}

export const editItemData = (data: any, token: any) => {
  const config = configWithAuth(token)
  return post(`${EDIT_ITEM_ENDPOINT}`, data, config)
}

export const addNewItem = (data: any, token: any) => {
  const config = configWithAuth(token)
  return post(`${ADD_NEW_ITEM}`, data, config)
}

export const uploadFile = async (file: File) => {
  try {
    const token = localStorage.getItem('accessToken') || ''

    const fileData = {
      file_name: file.name,
      content_type: file.type,
    }
    const config = configWithAuth(token)
    const res = await axios.post(`${GET_SIGNED_URL}`, fileData, config)
    const { upload_url, file_path } = res.data
    const res2 = await axios.put(upload_url, file)
    return { res2, file_path, file_name: file.name }
  } catch (error) {
    return error
  }
}

export const getItemTypes = (_token: any = '', searchParam: any, showInactive = false) => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  const search = new URLSearchParams({
    ...(searchParam && { search: searchParam }),
    ...(showInactive && { show_inactive: showInactive }),
  })

  return get(`${GET_ITEM_TYPES}` + `?${search.toString()}`, config)
}

export const createItemType = (_token: any, data: any) => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  return putWithAuth(`${GET_ITEM_TYPES}`, data, config)
}

export const deleteItemType = (_token: any, id: number) => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  return delWithConfig(`${CREATE_UPDATE_MARKINGS_ENDPOINT}/${id}`, config)
}

export const updateItemTypeV2 = (_token: any, id: number, data: any) => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  return putWithAuth(`${GET_ITEM_TYPES}/${id}`, data, config)
}

export const getItemStatusList = (token: any) => {
  const config = configWithAuth(token)
  return get(`${GET_ITEM_STATUS_LIST}`, config)
}

export const getContainerStatusList = (token: string) => {
  const config = configWithAuth(token)
  return get(`${GET_CONTAINER_STATUS_LIST}`, config)
}

export const getContainerAssigneeList = (token: string) => {
  const config = configWithAuth(token)
  return get(`${GET_CONTAINER_ASSIGNEE_LIST}`, config)
}

export const getContainerYardLocationList = (token: string) => {
  const config = configWithAuth(token)
  return get(`${GET_CONTAINER_YARD_LOCATION_LIST}`, config)
}

export const getContainerFilterOptions = (token: string) => {
  const config = configWithAuth(token)
  return get(`${GET_CONTAINER_FILTER_OPTIONS}`, config)
}

export const getMembers = (_token: any, searchParam: any) => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  if (searchParam !== '') {
    return get(`${GET_MEMBERS_ENDPOINT}` + '?search=' + searchParam, config)
  } else {
    return get(`${GET_MEMBERS_ENDPOINT}`, config)
  }
}

export const getMarkings = (_token: any = '', searchParam: any = '') => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  if (searchParam !== '') {
    return get(`${GET_MARKINGS_ENDPOINT}` + '?search=' + searchParam, config)
  } else {
    return get(`${GET_MARKINGS_ENDPOINT}`, config)
  }
}

export const createMarking = (_token: any, data: any) => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  return putWithAuth(`${CREATE_UPDATE_MARKINGS_ENDPOINT}`, data, config)
}

export const deleteMarking = (_token: any, id: number) => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  return delWithConfig(`${CREATE_UPDATE_MARKINGS_ENDPOINT}/${id}`, config)
}

export const updateMarking = (_token: any, id: number, data: any) => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  return putWithAuth(`${CREATE_UPDATE_MARKINGS_ENDPOINT}/${id}`, data, config)
}

export const getMemberDetails = (memberId: any, token: any) => {
  const config = configWithAuth(token)
  return get(`${GET_MEMBER_DETAILS}` + memberId, config)
}

export const getContainerList = (_token: any, searchParam: any, filterParam?: string, showArchived?: boolean) => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)

  const search = new URLSearchParams({
    ...(filterParam && { filter: filterParam }),
    ...(searchParam && { search: searchParam }),
    ...(showArchived && { show_archived: showArchived }),
  })

  return get(`${GET_CONTAINER_LIST}?${search.toString()}`, config)
}

export const getDefaultInspectionFields = (_token: any = '') => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  return get(`${GET_DEFAULT_INSPECTION_FIELDS}`, config)
}

export const updateContainer = (data: any, _token: any = '') => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  return putWithAuth(`${UPDATE_CONTAINER_SETTINGS_ENDPOINT}`, data, config)
}

export const updateItem = (data: any, _token: any = '') => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  return putWithAuth(`${UPDATE_ITEM_SETTINGS_ENDPOINT}`, data, config)
}

export const getUserRoles = (token: any) => {
  const config = configWithAuth(token)
  return get(`${GET_USER_ROLES}`, config)
}

export const updateMemberDetails = (memberId: any, data: any, token: any) => {
  const config = configWithAuth(token)
  return putWithAuth(`${UPDATE_MEMBER_DETAILS}` + memberId, data, config)
}

export const createNewMember = (data: any, token: any) => {
  const config = configWithAuth(token)
  return post(`${CREATE_MEMBER_ENDPOINT}`, data, config)
}

export const addNewContainer = (data: any, token: any) => {
  const config = configWithAuth(token)
  return post(`${GET_CONTAINER_LIST}`, data, config)
}

export const trackContainer = (data: any) => {
  return post(`${TRACKING_CONTAINER}`, data, configWithoutAuth)
}

export const getContainerDetails = (containerId: any, _token?: any) => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  return get(`${GET_CONTAINER_DETAILS}` + containerId, config)
}

export const assignContainerToUser = (data: any, token: any) => {
  const config = configWithAuth(token)
  return post(`${ASSIGN_CONTAINER_ENDPOINT}`, data, config)
}

export const getContainerItems = (containerId: any, _token?: any) => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  return get(`${GET_CONTAINER_ITEMS}` + containerId, config)
}

export const updateContainerDetails = (data: any, token: any) => {
  const config = configWithAuth(token)
  return putWithAuth(`${EDIT_CONTAINER}`, data, config)
}

export const sendResetLink = (data: any, token: any) => {
  const config = configWithAuth(token)
  return post(`${SEND_PASSWORD_RESET_LINK}`, data, config)
}

export const updatePassword = (data: any, token: any) => {
  const config = configWithAuth(token)
  return post(`${UPDATE_PASSWORD_ENDPOINT}` + token, data, config)
}

export const suspendMember = (memberId: any, token: any) => {
  const config = configWithAuth(token)
  return putWithAuth(`${SUSPEND_MEMBER_ENDPOINT}` + memberId, {}, config)
}

export const deleteMember = (memberId: any, token: any) => {
  const config = configWithAuth(token)
  return delWithConfig(`${DELETE_MEMBER_ENDPOINT}` + memberId, config)
}

export const createItemCheckReport = (itemId: any, token: any, data: any) => {
  const config = configWithAuth(token)
  return post(`${CREATE_ITEM_CHECK_REPORT_ENDPOINT}` + itemId, data, config)
}

export const createContainerCheckReport = (containerId: any, token: any, data: any) => {
  const config = configWithAuth(token)
  return post(`${CREATE_CONTAINER_CHECK_REPORT_ENDPOINT}` + containerId, data, config)
}

export const getCheckReportV2 = (identifier: any) => {
  return get(`${GET_CHECK_REPORT_V2}` + identifier, {})
}

export const getStats = (data: any, isAllowed: boolean, _token?: any) => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  const params = new URLSearchParams(data)
  if(isAllowed) {
    return get(`${STATS_DATA_ENDPOINT}?${params}`, config)
  }
  else {
    return null
  }
}

export const getSuperadminStats = () => {
  const token = localStorage.getItem('adminAccessToken')
  const config = configWithAuth(token || '')
  return get(`${SA_STATS_DATA_ENDPOINT}`, config)
}

export const getAdminOrgs = (token: any) => {
  const config = configWithAuth(token)
  return get(`${SUPERADMIN_GET_ALL_ORGANISATIONS}`, config)
}

export const getSuperadminMembers = (token: any) => {
  const config = configWithAuth(token)
  return get(`${SUPERADMIN_GET_MEMBERS}`, config)
}

export const createNewOrgAdmin = (data: any, token: any) => {
  const config = configWithAuth(token)
  return post(`${SUPERADMIN_GET_MEMBERS}`, data, config)
}

export const getLoggedInUserDetails = (token: any) => {
  const config = configWithAuth(token)
  return get(`${LOGGED_IN_USER_DETAILS}`, config)
}

export const getLoggedInUserPermissions = (token: any) => {
  const config = configWithAuth(token)
  return get(`${LOGGED_IN_USER_PERMISSIONS}`, config)
}

export const batchUploadItems = (data: any, token: any) => {
  const config = uploadFilesConfigWithAuth(token)
  return post(`${BATCH_UPLOAD_ITEMS_ENDPOINT}`, data, config)
}

export const batchUploadContainers = (data: any, token: any) => {
  const config = uploadFilesConfigWithAuth(token)
  return post(`${BATCH_UPLOAD_CONTAINERS_ENDPOINT}`, data, config)
}

export const batchUploadUsers = (data: any, token: any) => {
  const config = uploadFilesConfigWithAuth(token)
  return post(`${BATCH_UPLOAD_USERS_ENDPOINT}`, data, config)
}

export const updateItemType = (data: any, token: any) => {
  const config = configWithAuth(token)
  return putWithAuth(`${EDIT_ITEM_TYPE_ENDPOINT}`, data, config)
}

export const getStageList = (_token: any = '', search = '') => {
  const config = configWithAuth(localStorage.getItem('accessToken') || _token)

  if (search) {
    return get(`${GET_STAGE_LIST}&search=${search}`, config)
  }

  return get(`${GET_STAGE_LIST}`, config)
}

export const createNewStage = (data: any, token: any) => {
  const config = configWithAuth(token)
  return post(`${CREATE_NEW_STAGE}`, data, config)
}

export const updateStageName = (data: any, token: any, id: any) => {
  const config = configWithAuth(token)
  return putWithAuth(`${CREATE_NEW_STAGE}` + '/' + id, data, config)
}

export const archiveContainer = (id: number | string, _token?: any) => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  return post(`${ARCHIVE_CONTAINER}${id}`, {}, config)
}

export const unarchiveContainer = (id: number | string, _token?: any) => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  return post(`${UNARCHIVE_CONTAINER}${id}`, {}, config)
}

export const archiveItem = (id: number | string, _token?: any) => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  return post(`${ARCHIVE_ITEM}${id}`, {}, config)
}

export const unarchiveItem = (id: number | string, _token?: any) => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  return post(`${UNARCHIVE_ITEM}${id}`, {}, config)
}

export const getItemLocationListApi = (_token?: any) => {
  const token = localStorage.getItem('accessToken') || _token
  const config = configWithAuth(token)
  return get(`${GET_ALL_ITEM_LOCATIONS}`, config)
}
