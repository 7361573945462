import ChartDataLabels from 'chartjs-plugin-datalabels'
import React, { useContext, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useQuery } from 'react-query'
import * as RadioGroup from '@radix-ui/react-radio-group'
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js'
import { Bar, Pie } from 'react-chartjs-2'
import { getStats } from '../../api/apiCalls'
import Divider from '../../components/divider/Divider'
import Flex from '../../components/flex/Flex'
import Loader from '../../components/Loader/loader'
import { renderBreadCrumbs, useBreadCrumbs } from '../../contexts/breadcrumbs'
import { styled } from '../../stitches.config'
import { Button } from '../../components/Button'
import moment from 'moment'
import withTitle from '../../hoc/withTitle'
import i18n from '../../i18n/i18n'

import './stats.scss'
import { useTranslation } from 'react-i18next'
import { UserPermissionsContext } from "../../contexts/userPermissionsContext";
import { ScreenOrderForTranslationContext } from '../../contexts/userDetailsContext'

const RadioItem = styled('button', {
  height: 28,
  border: 'none',
  borderRadius: 2,
  fontSize: 12,
  px: '0.5rem',

  '&[data-state="checked"]': {
    color: '#fff',
    backgroundColor: '$rich_moss',
  },
})
type FilterDurations = '1w' | '2w' | '3w' | '1m' | '6m' | 'all' | 'from_to'

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

ChartJS.defaults.plugins.legend.position = 'bottom'
ChartJS.defaults.plugins.legend.labels.font = { size: 11 }
ChartJS.defaults.plugins.legend.labels.usePointStyle = true
ChartJS.defaults.plugins.legend.align = 'start'
ChartJS.defaults.plugins.legend.labels.color = '#000000'
ChartJS.defaults.plugins.legend.labels.padding = 24
ChartJS.defaults.color = '#000000'

const COLORS = ['#96bbbb', '#618985', '#414535', '#f2e3bc', '#c19875'] // ['#343e3d', '#607466', '#aedcc0', '#7bd389', '#38e4ae']

const pieChartOptions = {
  responsive: true,
  plugins: {
    tooltip: {
      enabled: true,
    },
    datalabels: {
      formatter: (value: any, ctx: any) => {
        let sum = 0
        const dataArr = ctx.dataset.data
        dataArr.map((data: any) => {
          sum += data
        })
        // let retVal = value + " (" + percentage+")"
        return ((value * 100) / sum).toFixed(0) + '%'
      },
      color: '#000000',
      font: {
        size: 14,
      },
      display: 'auto',
    },
    legend: {
      labels: {
        font: {
          size: 10,
        },
        display: 'auto',
        whiteSpace: 'nowrap',
      },
    },
  },
}

const barChartOptionsCurrency = {
  responsive: true,
  barThickness: 15,
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context: any) {
          let label = context.dataset.label || ''

          if (label) {
            label += ': '
          }
          if (context.parsed.y !== null) {
            label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(context.parsed.y)
          }
          return label
        },
      },
    },
  },
}

const barChartOptions = {
  responsive: true,
  barThickness: 15,
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context: any) {
          let label = context.dataset.label || ''

          if (label) {
            label += ': '
          }
          if (context.parsed.y !== null) {
            label += context.parsed.y
          }
          return label
        },
      },
    },
  },
}

function Stats() {

  const { ...userPermissionsData } = useContext(UserPermissionsContext)
  const userPermissions = userPermissionsData?.data
  const isAllowed: boolean = userPermissions && userPermissions.data.assign_objects

  const { t } = useTranslation()
  const breadcrumbs = useBreadCrumbs(t('organisationStats'))

  const [filter, setFilter] = useState<FilterDurations>('1w')
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [showArchived, setShowArchived] = useState(false)
  const [datesClickSource, setDatesClickSource] = useState<string | null>(null)

  useEffect(() => {
    if (filter && filter !== 'from_to') {
      setStartDate(getStartDate(filter))
      setEndDate(new Date())
      setDatesClickSource('radio')
    }
  }, [filter])

  useEffect(() => {
    if (datesClickSource === 'datePicker') {
      setFilter('from_to')
    }
  }, [startDate, endDate])

  const { isLoading, data } = useQuery(
    ['stats', startDate?.toISOString().substring(0, 10), endDate?.toISOString().substring(0, 10), showArchived],
    () => getStats({ start_date: startDate?.setUTCHours(12), end_date: endDate?.setUTCHours(12), show_archived: showArchived }, isAllowed),
    {
      enabled: localStorage.getItem('loggedIn') === 'true',
    }
  )

  const getStartDate = (filter: string): Date | null => {
    let startDate: Date | null = null
    if (filter === '1w') {
      const timeDuration = 60 * 24 * 7 // 1 days = 60 * 24
      startDate = moment(Date.now() - timeDuration * 60 * 1000).toDate()
    } else if (filter === '2w') {
      const timeDuration = 60 * 24 * 14 // 1 days = 60 * 24
      startDate = moment(Date.now() - timeDuration * 60 * 1000).toDate()
    } else if (filter === '3w') {
      const timeDuration = 60 * 24 * 21 // 1 days = 60 * 24
      startDate = moment(Date.now() - timeDuration * 60 * 1000).toDate()
    } else if (filter === '1m') {
      const timeDuration = 60 * 24 * 30 // 1 days = 60 * 24
      startDate = moment(Date.now() - timeDuration * 60 * 1000).toDate()
    } else if (filter === '6m') {
      const timeDuration = 60 * 24 * 30 * 6 // 1 days = 60 * 24
      startDate = moment(Date.now() - timeDuration * 60 * 1000).toDate()
    }
    return startDate
  }

  const statusesData = {
    labels: data?.section_1.statuses.map(Object.keys).flat(),
    datasets: [
      {
        label: 'Inspection Status',
        data: data?.section_1.statuses.map(Object.values).flat(),
        backgroundColor: COLORS,
        borderColor: COLORS,
        borderWidth: 1,
      },
    ],
  }

  const otherStages = data?.section_1.stages
    .map(Object.values)
    .flat()
    .slice(4)
    .reduce((acc: any, x: any) => +acc + x, 0)

  const stagesData = {
    labels: data?.section_1.stages
      .map(Object.keys)
      .flat()
      .slice(0, 4)
      .concat(otherStages ? ['OTHERS'] : []),

    datasets: [
      {
        label: t('stage'),
        data: data?.section_1.stages
          .map(Object.values)
          .flat()
          .slice(0, 4)
          .concat(otherStages || []),
        backgroundColor: COLORS,
        borderColor: COLORS,
        borderWidth: 1,
      },
    ],
  }
  const { translations, translationsLoaded } = useContext(ScreenOrderForTranslationContext)

  const itemsAndCheckReportsGeneratedData = {
    labels: data?.section_2.date,
    datasets: [
      {
        label: translations['containers'],
        data: data?.section_2.container,
        backgroundColor: COLORS[1],
      },
      {
        label: translations['items'],
        data: data?.section_2.item,
        backgroundColor: COLORS[0],
      },
      {
        label: t('checkReports'),
        data: data?.section_2.check_report,
        backgroundColor: COLORS[2],
      },
    ],
  }

  const billingsData = {
    labels: data?.section_3?.date,
    datasets: [
      {
        label: t('amount'),
        data: data?.section_3?.price,
        backgroundColor: COLORS[0],
      },
    ],
  }

  return <> {isAllowed && (
    <Flex
      direction='column'
      css={{
        background: '$paper',
        borderRadius: 5,
      }}
    >
      {isLoading && <Loader />}
      {renderBreadCrumbs(breadcrumbs)}
      <Divider />
      <Flex align={'end'} css={{ p: '2rem 4rem', gap: '1rem' }} justify={'end'}>
        <Button
          style={{ height: 28 }}
          onClick={() => setShowArchived(!showArchived)}
          intent={showArchived ? 'secondary' : 'primary'}
        >
          {showArchived ? t('hideArchived') : t('showArchived')}
        </Button>
        <RadioGroup.Root asChild onValueChange={setFilter as any} value={filter || undefined}>
          <Flex css={{ gap: '1rem' }}>
            <RadioGroup.Item asChild value='1w'>
              <RadioItem>1w</RadioItem>
            </RadioGroup.Item>
            <RadioGroup.Item asChild value='2w'>
              <RadioItem>2w</RadioItem>
            </RadioGroup.Item>
            <RadioGroup.Item asChild value='3w'>
              <RadioItem>3w</RadioItem>
            </RadioGroup.Item>
            <RadioGroup.Item asChild value='1m'>
              <RadioItem>1m</RadioItem>
            </RadioGroup.Item>
            <RadioGroup.Item asChild value='6m'>
              <RadioItem>6m</RadioItem>
            </RadioGroup.Item>
            <RadioGroup.Item asChild value='all'>
              <RadioItem>{t('sinceBeginning')}</RadioItem>
            </RadioGroup.Item>
          </Flex>
        </RadioGroup.Root>

        <Flex align={'center'} css={{ gap: '1rem' }}>
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <p className='chart-filter-calendar-picker-title'>{t('from')}</p>
            <DatePicker
              showYearDropdown
              showMonthDropdown
              dropdownMode='select'
              dateFormat='dd.MM.yyyy'
              className='chart-filter-calendar-input'
              selected={startDate}
              onChange={setStartDate}
              onInputClick={() => {
                setDatesClickSource('datePicker');
              }}
              placeholderText='dd.mm.yyyy'
              maxDate={new Date()}
            />
            {startDate && (
              <span
                onClick={() => {
                  setStartDate(null);
                  setEndDate(null);
                }}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '40%',
                  cursor: 'pointer',
                  color: 'grey',
                }}
              >
                &times;
              </span>
            )}
          </div>
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <p className='chart-filter-calendar-picker-title'>{t('to')}</p>
            <DatePicker
              showYearDropdown
              showMonthDropdown
              dropdownMode='select'
              dateFormat='dd.MM.yyyy'
              className='chart-filter-calendar-input'
              selected={endDate}
              onChange={setEndDate}
              onInputClick={() => {
                setDatesClickSource('datePicker');
              }}
              placeholderText='dd.mm.yyyy'
              maxDate={new Date()}
            />
            {endDate && (
              <span
                onClick={() => setEndDate(null)}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '40%',
                  cursor: 'pointer',
                  color: 'grey',
                }}
              >
                &times;
              </span>
            )}
          </div>

        </Flex>
      </Flex>
      <Flex
        direction={'column'}
        css={{
          background: '#fff',
          p: '2rem',
          m: '2rem',
          mt: 0,
          borderRadius: 8,
        }}
      >
        <Flex justify={'between'} wrap='wrap'>
          <Flex direction='column'>
            <p className='chart-title'>{t('inspectionStatus')}</p>
            {statusesData.datasets[0].data ? (
              // @ts-ignore
              <Pie data={statusesData} options={pieChartOptions} height={360} width={360} plugins={[ChartDataLabels]} />
            ) : (
              <p className='charts-no-data-text'>{t('noDataAvailable')}</p>
            )}
          </Flex>
          <Flex direction='column'>
            <p className='chart-title'>{t('stage')}</p>
            {stagesData.datasets[0].data ? (
              // @ts-ignore
              <Pie data={stagesData} options={pieChartOptions} height={360} width={360} plugins={[ChartDataLabels]} />
            ) : (
              <p className='charts-no-data-text'>{t('noDataAvailable')}</p>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex
        direction={'column'}
        css={{
          background: '#fff',
          p: '2rem',
          m: '2rem',
          mt: 0,
          borderRadius: 8,
        }}
      >
        <Flex direction={'column'}>
          <p className='chart-title items-check-reports-chart-title'>{translations['containersItemsCheckReportsGenerated']}</p>
          {itemsAndCheckReportsGeneratedData.datasets.every(dataset => !!dataset.data?.length) ? (
            <Bar data={itemsAndCheckReportsGeneratedData} options={barChartOptions} height={100} />
          ) : (
            <p className='charts-no-data-text'>{t('noDataAvailable')}</p>
          )}
        </Flex>
      </Flex>
      {data?.section_3 && (
        <Flex
          direction={'column'}
          css={{
            background: '#fff',
            p: '2rem',
            m: '2rem',
            mt: 0,
            borderRadius: 8,
          }}
        >
          <Flex direction={'column'}>
            <p className='chart-title items-check-reports-chart-title'>{t('billing')}</p>
            {billingsData.datasets[0].data && billingsData.datasets[0].data.length > 0 ? (
              <Bar data={billingsData} options={barChartOptionsCurrency} height={100} />
            ) : (
              <p className='charts-no-data-text'>{t('noDataAvailable')}</p>
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  )} </>
}

export default withTitle(Stats, `${i18n.t('organisationStats')} | ${i18n.t('appName')}`)
