import React, { useContext } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import '../../dashboard/dashboard.scss'
// import merchantLogo from '../../assets/images/maersk_logo3x.png';
import statsIcon from '../../../assets/images/stats_tab_icon.svg'
import membersIcon from '../../../assets/images/members_tab_icon.svg'
// import containersIcon from '../../assets/images/containers_tab_icon.svg';
// import itemsIcon from '../../assets/images/items_tab_icon.svg';
// import checkReportsIcon from '../../assets/images/check_reports_tab_icon.svg';
import organisationSettingsIcon from '../../../assets/images/organisation_settings_tab_icon.svg'
import profileIcon from '../../../assets/images/user_profile_icon.svg'
import dropdownIcon from '../../../assets/images/dropdown_arrow_icon.svg'
import { useState } from 'react'
// import OrganisationSettings from "../organisationSettings/organisationSettings";
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import { useNavigate } from 'react-router-dom'
// import Stats from "../stats/stats";
// import Members from "../Members/members";
import emoryLogo from '../../../assets/images/emory_logo.svg'
import Loader from '../../../components/Loader/loader'
// import Containers from "../containers/containers";
// import Items from "../../items/items";
// import CheckReports from "../../checkReports/checkReports";
// import AssignItemsPopup from "../../../components/AssignItemsPopup/assignItemsPopup";
import { useEffect } from 'react'
// import { OrgSettingsTabStateProvider } from "../../../contexts/orgSettingsTabStateContext";
import PageNotFound from '../../pageNotFound/pageNotFound'
import SuperadminOrganisations from '../superadminOrganisations/superadminOrganisations'
import SuperadminAddNewOrg from '../superadminAddNewOrg/superadminAddNewOrg'
import './superadminDashboard.scss'
import SuperadminOrgMembersList from '../superadminOrgMembersList/superadminOrgMembersList'
import SuperadminAddNewMember from '../superadminAddNewMember/superadminAddNewMember'
import withTitle from '../../../hoc/withTitle'
import SuperadminStats from '../superadminStats/superadminStats'

const SuperadminDashboard = (props: any) => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState<any>('organisation-settings')
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [userName, setUserName] = useState<any>('')

  const onOrganisationSettingsTabClick = () => {
    localStorage.setItem('currentBaseTab', 'organisation-settings')
    setActiveTab('organisation-settings')
    if (props.pageNotFound) {
      navigate('/superadmin/dashboard', { replace: true })
    }
  }

  const onMembersTabClick = () => {
    localStorage.setItem('currentBaseTab', 'members')
    setActiveTab('members')
    if (props.pageNotFound) {
      navigate('/superadmin/dashboard', { replace: true })
    }
  }

  const onStatsTabClick = () => {
    localStorage.setItem('currentBaseTab', 'stats')
    setActiveTab('stats')
    if (props.pageNotFound) {
      navigate('/superadmin/dashboard', { replace: true })
    }
  }

  const onLogoutClick = () => {
    localStorage.removeItem('adminLoggedIn')
    localStorage.removeItem('adminAccessToken')
    localStorage.removeItem('adminRefreshToken')
    localStorage.removeItem('currentBaseTab')
    navigate('/superadmin', { replace: true })
  }

  useEffect(() => {
    // refetch()
    // setUserName(localStorage.getItem('loggedInUser'))
    if (localStorage.getItem('currentBaseTab')) {
      setActiveTab(localStorage.getItem('currentBaseTab'))
    }
  }, [])

  const [editOrg, setEditOrg] = useState<boolean>(false)
  const [addNewOrg, setAddNewOrg] = useState<boolean>(false)
  const [selectedOrgId, setSelectedOrgId] = useState<any>(null)

  const [addNewMember, setAddNewMember] = useState<boolean>(false)
  const [hideDangerZone, setHideDangerZone] = useState<boolean>(false)
  const [memberId, setMemberId] = useState<any>(null)

  return (
    <>
      {showLoader && <Loader />}
      <div className='dashboard-outer-container' id='dashboard-outer-container'>
        <div className='dashboard-tabs-container'>
          <div className='dashboard-side-panel-merchant-logo-container'>
            <img src={emoryLogo} className='dashboard-side-panel-merchant-logo' />
          </div>
          <div>
            <div
              onClick={onOrganisationSettingsTabClick}
              className={
                activeTab === 'organisation-settings'
                  ? 'display_flex side-panel-tabs-btn-container side-panel-selected-tab side-panel-selected-tab-super-admin'
                  : 'display_flex side-panel-tabs-btn-container'
              }
            >
              <img
                src={organisationSettingsIcon}
                className={activeTab === 'organisation-settings' ? 'tab-icon selected-tab-icon' : 'tab-icon'}
              />
              <p
                className={
                  activeTab === 'organisation-settings'
                    ? 'side-panel-tabs-btn-text side-panel-tabs-btn-selected-text'
                    : 'side-panel-tabs-btn-text'
                }
              >
                Organisations
              </p>
            </div>
            <div
              onClick={onMembersTabClick}
              className={
                activeTab === 'members'
                  ? 'display_flex side-panel-tabs-btn-container side-panel-selected-tab side-panel-selected-tab-super-admin'
                  : 'display_flex side-panel-tabs-btn-container'
              }
            >
              <img src={membersIcon} className={activeTab === 'members' ? 'tab-icon selected-tab-icon' : 'tab-icon'} />
              <p
                className={activeTab === 'members' ? 'side-panel-tabs-btn-text side-panel-tabs-btn-selected-text' : 'side-panel-tabs-btn-text'}
              >
                Members
              </p>
            </div>
          </div>
          <div
            onClick={onStatsTabClick}
            className={
              activeTab === 'stats'
                ? 'display_flex side-panel-tabs-btn-container side-panel-selected-tab side-panel-selected-tab-super-admin'
                : 'display_flex side-panel-tabs-btn-container'
            }
          >
            <img src={statsIcon} className={activeTab === 'stats' ? 'tab-icon selected-tab-icon' : 'tab-icon'} />
            <p className={activeTab === 'stats' ? 'side-panel-tabs-btn-text side-panel-tabs-btn-selected-text' : 'side-panel-tabs-btn-text'}>
              Stats
            </p>
          </div>
        </div>

        <div className='dashboard-contents-outer-container'>
          <div className='logged-in-member-name-top-container'>
            <Popup
              className='profile-options-dropdown'
              trigger={
                <div className='display_flex member-name-icon-container super-admin-member-name-icon-container'>
                  <img src={profileIcon} className='member-profile-icon' />
                  <p className='logged-in-member-name-text'>{userName}</p>
                  <img src={dropdownIcon} className='dropdown-icon' />
                </div>
              }
              position='bottom right'
            >
              <div className='profile-dropdown-options-container'>
                {/* <div
                                className="profile-options-item super-admin-profile-options-item"
                                >
                                    Account
                                </div> */}
                <div className='profile-options-item super-admin-profile-options-item' onClick={onLogoutClick}>
                  Logout
                </div>
              </div>
            </Popup>
          </div>

          <div className='dashboard-contents-bottom-container'>
            {!props.pageNotFound ? (
              activeTab === 'organisation-settings' ? (
                editOrg ? (
                  <SuperadminAddNewOrg
                    setEditOrgStatus={setEditOrg}
                    editOrgStatus={editOrg}
                    addNewOrgStatus={addNewOrg}
                    orgId={selectedOrgId}
                    showLoader={setShowLoader}
                  />
                ) : addNewOrg ? (
                  <SuperadminAddNewOrg
                    setAddOrgStatus={setAddNewOrg}
                    showLoader={setShowLoader}
                    addNewOrgStatus={addNewOrg}
                    editOrgStatus={editOrg}
                    setOrgId={setSelectedOrgId}
                  />
                ) : (
                  <SuperadminOrganisations
                    showLoader={setShowLoader}
                    addOrgStatus={setAddNewOrg}
                    setOrgId={setSelectedOrgId}
                    editOrgStatus={setEditOrg}
                  />
                )
              ) : activeTab === 'members' ? (
                addNewMember ? (
                  <SuperadminAddNewMember
                    addMemberStatus={setAddNewMember}
                    hideDangerZoneFlag={hideDangerZone}
                    showLoader={setShowLoader}
                    memberId={memberId}
                    setMemberId={setMemberId}
                  />
                ) : (
                  <SuperadminOrgMembersList
                    showLoader={setShowLoader}
                    addMemberStatus={setAddNewMember}
                    hideDangerZone={setHideDangerZone}
                    setMemberId={setMemberId}
                  />
                )
              ) : activeTab === 'stats' ? (
                <SuperadminStats />
              ) : null
            ) : (
              <PageNotFound loggedIn />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default withTitle(SuperadminDashboard, 'Super Admin')
