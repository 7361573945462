import * as Dialog from '@radix-ui/react-dialog'
import React, { createRef, useContext, useState } from 'react'
import { QRCode } from 'react-qrcode-logo'
import '../QrCodePopup/qrCodePopup.scss'
import Button from '../Button/button'
import ReactToPrint from 'react-to-print'
import './downloadMultipleQrCodes.scss'
import emoryLogo from '../../assets/images/emory_icon.svg'
import {t} from "i18next";
import {useBasicFieldName} from "../../hooks/useBasicFieldName";

const DownloadMultipleQrCodes = (props: any) => {
  const componentRef = React.useRef(null)
  const basicFieldName = useBasicFieldName();
  const getQrData = () => {
    if (props.type === 'item') {
      if (props.itemsList) {
        const tempArr: any = []
        props.itemsList.forEach((item: any) => {
          const temp = {
            qrCodeData: item.qr_code,
            id: item.mark,
            title: item.title,
          }
          tempArr.push(temp)
        })
        return tempArr
      }
    } else if (props.type === 'container') {
      if (props.containersList) {
        const tempArr: any = []
        props.containersList.forEach((item: any) => {
          const temp = {
            qrCodeData: item.qr_code,
            id: item.external_container_id,
            title: item.title,
          }
          tempArr.push(temp)
          if (item.items && item.items.length > 0) {
            item.items.map((el: any) =>
              tempArr.push({
                qrCodeData: { id: el.id, type: 'item' },
                id: el.mark,
                title: el.title,
              })
            )
          }
        })
        return tempArr
      }
    }
  }

  const qrData = getQrData()

  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <Button padding="0.75rem 2rem" fontSize="12px" height="fit-content" width="fit-content">
        {t('printQRCode')}
      </Button>
    )
  }, [])

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current
  }, [componentRef.current])

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>{props.children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay
          style={{
            height: '100%',
            background: 'rgba(174,174,174,0.1)',
            zIndex: 99999,
            backdropFilter: 'blur(5px)',
            WebkitBackdropFilter: 'blur(5px)',
            position: 'fixed',
            width: '100%',
            top: '0',
            left: '0',
          }}
        />
        <Dialog.Content
          style={{
            fontFamily: 'Nunito',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 999999999,
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 999999999,
            }}
          >
            <div className="assignee-list-card-outer-container custom-message-popup-outer-container print-qr-codes-container">
              <div className="assign-title-close-icon-container print-qr-codes-top-container">
                <div className="custom-message-popup-dismiss-btn-container no_margin"></div>
                <Dialog.Close>
                  <p className="cross-icon cursor_pointer close-icon-qr-popup print-qr-codes-close-icon">x</p>
                </Dialog.Close>
              </div>
              <div ref={componentRef} className="flex flex-col items-center print-qr-codes-data-container">
                {qrData &&
                  qrData.length > 0 &&
                  qrData.map((val: any) => (
                    <div
                      className="max-w-xs custom-popup-message-text print-qr-codes-data"
                      key={val.qrCodeData.id + val.qrCodeData.type}
                    >
                      <QRCode
                        value={JSON.stringify(val.qrCodeData)}
                        size={props.size || 225}
                        logoImage={props.logo || emoryLogo}
                        logoHeight={props.hight || 50}
                        logoWidth={props.width || 50}
                        removeQrCodeBehindLogo={true}
                        ecLevel="H"
                      />
                      <p className="qr-item-id-text">
                        {val.qrCodeData.type === 'item' ? basicFieldName('Mark', 'item')+': ' :
                          basicFieldName('Container ID', 'container')+': '} {val.id}
                      </p>
                      <p className="mt-0 max-w-[290px] text-center text-sm">
                        {val.qrCodeData.type === 'item' ? basicFieldName('Title', 'item')+': ' :
                          basicFieldName('Title', 'container')+': '}{val.title}
                      </p>
                    </div>
                  ))}
              </div>
              <div className="print-multiple-codes-btn-container">
                <ReactToPrint
                  content={reactToPrintContent}
                  documentTitle="QR Code"
                  removeAfterPrint
                  trigger={reactToPrintTrigger}
                />
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default DownloadMultipleQrCodes
