import React, { useState, useEffect, createContext } from 'react'
import { useQuery } from 'react-query'
import { getContainerList } from '../api/apiCalls'

export const ContainerListContext = createContext<any>({})

// TODO: this context provider is getting used at emory_fe\src\pages\items\addNewItem\addNewItem.tsx only.
// Needs to be considered for refactoring.
export const ContainerListProvider: React.FC<any> = (props) => {
  const [containerList, setContainerList] = useState()

  const getContainers = async () => {
    const token = localStorage.getItem('accessToken')
    const res = await getContainerList(token, '')
    return res
  }

  const { isLoading, isSuccess, isError, data, error, refetch } = useQuery('containersList', getContainers, {
    enabled: localStorage.getItem('loggedIn') === 'true' ? true : false,
  })

  useEffect(() => {
    if (data && localStorage.getItem('loggedIn') === 'true') {
      setContainerList(data.containers)
    }
  }, [data])
  return (
    <ContainerListContext.Provider value={[containerList, setContainerList, { isLoading, isSuccess, isError, data, error, refetch }]}>
      {props.children}
    </ContainerListContext.Provider>
  )
}
