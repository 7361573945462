import React, { useContext } from 'react'
import { useState } from 'react'
import InputField from '../../../../components/InputField/inputField'
import { getEmailErrorMsg, phoneNumberValidation, showAlertForError } from '../../../../helpers/validations'
import '../companyDetails/companyDetails.scss'
import './primaryContactDetails.scss'
import DropdownSelect from '../../../../components/dropdownSelect/dropdownSelect'
import Button from '../../../../components/Button/button'
import { OrgDetailsContext } from '../../../../contexts/orgDetailsContext'
import { useEffect } from 'react'
import { updatePrimaryContact } from '../../../../api/apiCalls'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { UserPermissionsContext } from '../../../../contexts/userPermissionsContext'
import { useTranslation } from 'react-i18next'
import Loader from '../../../../components/Loader/loader'
import { useBreadCrumbs } from '../../../../contexts/breadcrumbs'

const PrimaryContactDetails = () => {
  const [contactName, setContactName] = useState<string>('')
  const [companyName, setCompanyName] = useState<string>('')
  const [contactEmail, setContactEmail] = useState<string>('')
  const [contactType, setContactType] = useState<string>('')
  // const [companyType, setCompanyType] = useState<String>("");
  const [preferredLanguage, setPreferredLanguage] = useState<string>('')
  const [contactEmailError, setContactEmailError] = useState<string>('')
  const [contactAddress, setContactAddress] = useState<string>('')
  const [contactPhnNum, setContactPhnNum] = useState<any>('')

  const { isLoading, isSuccess, isError, data: orgDetails, error, refetch } = useContext(OrgDetailsContext)
  const { ...userPermissionsData } = useContext(UserPermissionsContext)
  const userPermissions = userPermissionsData?.data

  const onContactEmailChangeHandler = (value: string) => {
    setContactEmail(value)
    setContactEmailError(getEmailErrorMsg(value))
  }

  const onContactPhnNumChangeHandler = (value: any) => {
    if (phoneNumberValidation(value)) {
      setContactPhnNum(value)
    }
  }

  const contactTypeOptions = [
    { value: 'phone', label: 'phone' },
    { value: 'email', label: 'email' },
  ]

  const languageOptions = [
    { value: 'English', label: 'English' },
    { value: 'German', label: 'German' },
    { value: 'Spanish', label: 'Spanish' },
  ]

  const onCancelClick = () => {
    refetch()
    if (orgDetails) {
      setContactName(orgDetails.contact_name)
      setContactEmail(orgDetails.contact_email)
      setContactPhnNum(orgDetails.contact_phone_number)
      setContactAddress(orgDetails.contact_address)
      setContactType(orgDetails.contact_type)
      setCompanyName(orgDetails.company_name)
      setPreferredLanguage(orgDetails.company_preferred_language)
    }
  }

  const onSaveChangesClick = () => {
    if (!contactName || !contactEmail) {
      if (!contactName && !contactEmail) {
        alert('Please enter contact name and contact email!')
      } else if (!contactName) {
        alert('Please enter contact name')
      } else if (!contactEmail) {
        alert('Please enter contact email')
      }
    } else {
      // let orgId = localStorage.getItem('orgId');
      const orgId = userPermissions && userPermissions.data.organization_id
      // let orgId = userPermissions && userPermissions.data.org_uuid;
      const data = {
        contact_name: contactName,
        contact_email: contactEmail,
        contact_phone_number: contactPhnNum,
        contact_address: contactAddress,
        contact_type: contactType,
        company_name: companyName,
        company_preferred_language: preferredLanguage,
      }
      // console.log(data)
      const token = localStorage.getItem('accessToken')
      updatePrimaryContact(data, orgId, token)
        .then(response => {
          refetch()

          if (response.status === 200) {
            showSuccessToast()
          } else {
            showFailureToast()
          }
        })
        .catch(error => {
          console.log(error)
          showAlertForError(error)
        })
    }
  }

  const showSuccessToast = () => {
    toast.success('Primary contact details saved!', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  const showFailureToast = () => {
    toast.error('An error occurred while saving primary contact details!', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  useEffect(() => {
    if (orgDetails) {
      if (orgDetails.contact_name) {
        setContactName(orgDetails.contact_name)
      } else {
        setContactName('')
      }

      if (orgDetails.contact_email) {
        setContactEmail(orgDetails.contact_email)
      } else {
        setContactEmail('')
      }
      if (orgDetails.contact_phone_number) {
        setContactPhnNum(orgDetails.contact_phone_number)
      } else {
        setContactPhnNum('')
      }
      if (orgDetails.contact_address) {
        setContactAddress(orgDetails.contact_address)
      } else {
        setContactAddress('')
      }
      if (orgDetails.contact_type) {
        setContactType(orgDetails.contact_type)
      } else {
        setContactType('')
      }
      if (orgDetails.company_name) {
        setCompanyName(orgDetails.company_name)
      } else {
        setCompanyName('')
      }
      if (orgDetails.company_preferred_language) {
        setPreferredLanguage(orgDetails.company_preferred_language)
      } else {
        setPreferredLanguage('')
      }
    }
  }, [orgDetails])

  const { t } = useTranslation()
  useBreadCrumbs(t('primaryContactDetails'), null)

  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer
        position='bottom-center'
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme='colored'
      />
      <div className='sub-tab-content-container company-details-tab-outer-container'>
        <div className='display_flex flex-row-input-fields-container'>
          <div className='add-new-item-left-col-container'>
            <div className='row-left-field-container'>
              <InputField
                label={t('contactName')}
                value={contactName}
                placeholder='Enter Contact Name'
                onChange={(val: string) => setContactName(val)}
                type='text'
                error={false}
              />
            </div>
            <div className='row-left-field-container row-input-fields-container-margin-top'>
              <InputField
                label={t('contactPhoneNumber')}
                value={contactPhnNum}
                placeholder='Enter Contact Phone Number'
                onChange={onContactPhnNumChangeHandler}
                type='text'
                error={false}
              />
            </div>
            <div className='row-left-field-container row-input-fields-container-margin-top'>
              <DropdownSelect
                label={t('contactType')!}
                placeholder='Select Contact Type'
                options={contactTypeOptions}
                onChange={(val: any) =>
                  setContactType(val)}
                id='company-type-dropdown'
                // defaultValue={contactType && contactType}
                value={contactType}
                index={0}
              />
            </div>
            <div className='row-left-field-container row-input-fields-container-margin-top'>
              <DropdownSelect
                label={t('preferredLanguage')!}
                placeholder={t('selectPreferredLang')!}
                options={languageOptions}
                onChange={(val: any) => setPreferredLanguage(val)}
                id='preferred-language-dropdown'
                value={preferredLanguage}
                index={1}
                // className="preferred-payment-channel-dropdown"
              />
            </div>
          </div>

          <div className='add-new-item-right-col-container org-settings-company-details-right-col'>
            <div className='row-right-field-container'>
              <InputField
                label={t('contactEmail')}
                value={contactEmail}
                placeholder='Enter Primary Contact Email'
                onChange={onContactEmailChangeHandler}
                type='email'
                error={contactEmailError}
              />
            </div>
            <div className='row-right-field-container row-input-fields-container-margin-top'>
              <InputField
                label={t('contactAddress')}
                value={contactAddress}
                error={false}
                placeholder='Enter Contact Address'
                onChange={(val: string) => setContactAddress(val)}
                textarea={true}
                className='member-address-textarea'
                // className="user-profile-input-field user-profile-textarea-style"
              />
            </div>
            <div className='row-right-field-container row-input-fields-container-margin-top'>
              <InputField
                label={t('companyName')}
                value={companyName}
                placeholder='Enter Company Name'
                onChange={(val: string) => setCompanyName(val)}
                type='text'
                error={false}
              />
            </div>
          </div>
        </div>

        <div className='company-details-bottom-btn-container'>
          <div className='display_flex company-details-bottom-btn-inner-container'>
            <Button
              backgroundColor='#EEEEEE'
              textColor='#333333'
              padding='0.75rem 2rem'
              fontSize='12px'
              height='fit-content'
              width='auto'
              onClick={onCancelClick}
            >
              {t('discard')}
            </Button>

            <Button padding='0.75rem 2rem' fontSize='12px' height='fit-content' width='auto' leftMargin='1.25rem' onClick={onSaveChangesClick}>
              {t('saveChanges')}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrimaryContactDetails
