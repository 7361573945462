import React, { memo, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import { getFilteredItemsList } from '../../../api/apiCalls'
import editIcon from '../../../assets/images/edit_icon.svg'
import downloadIcon from '../../../assets/images/download_file_dark_icon.svg'
import AssignItemsPopup from '../../../components/AssignItemsPopup/assignItemsPopup'
import BatchUploadPopup from '../../../components/BatchUploadPopup/batchUploadPopup'
import { Button } from '../../../components/Button'
import TextButton from '../../../components/Button/button'
import QrCodeIconButton from '../../../components/Button/qrCodeIconButton'
import {
  CheckboxIndicator,
  CheckboxRoot,
  DataTable,
  ISort,
  renderSortIcon,
  TableBody,
  TableFooter,
  TableHead,
  Td,
  Th,
  toggleSortOrder,
  Toolbar,
  Tr,
  useDataTable,
} from '../../../components/dataTable/DataTable'
import Divider from '../../../components/divider/Divider'
import DownloadMultipleQrCodes from '../../../components/DownloadMultipleQrCodes/downloadMultipleQrCodes'
import FilterDropdown, { FilterData } from '../../../components/filterDropdown'
import Flex from '../../../components/flex/Flex'
import Loader from '../../../components/Loader/loader'
import QrCodePopup from '../../../components/QrCodePopup/qrCodePopup'
import { SearchBar, SearchContainer } from '../../../components/searchbar/SearchBar'
import { renderBreadCrumbs, useBreadCrumbs } from '../../../contexts/breadcrumbs'
import {useBasicFieldName} from "../../../hooks/useBasicFieldName";
import {downloadCSV, formatDate} from "../../../utils/helper";
import {HiCheck} from "react-icons/hi2";
import { ScreenOrderForTranslationContext } from '../../../contexts/userDetailsContext'

const TableHeader = memo(({ checked, onCheckAll, sort, setSort }: any) => {
  const basicFieldName = useBasicFieldName();
  const sortBy = (column: string) => {
    if (sort.by === column) {
      setSort(toggleSortOrder(sort))
    } else {
      setSort({
        by: column,
        order: 'asc',
      })
    }
  }

  const { t } = useTranslation()

  return (
    <TableHead>
      <Tr>
        <Th css={{ width: 60, maxWidth: 60 }}>
          <Flex as="span" align="center" justify="center">
            <CheckboxRoot checked={checked} onCheckedChange={onCheckAll}>
              <CheckboxIndicator>
                <HiCheck />
              </CheckboxIndicator>
            </CheckboxRoot>
          </Flex>
        </Th>
        <Th>{basicFieldName('Mark', 'item')}</Th>
        <Th>{basicFieldName('Title', 'item')}</Th>
        <Th css={{ cursor: 'pointer' }} onClick={() => sortBy('item_type')}>
          <Flex align={'center'} css={{ gap: 8 }}>
            {basicFieldName('Item Type', 'item')}
            {renderSortIcon(sort, 'item_type')}
          </Flex>
        </Th>
        <Th css={{ cursor: 'pointer' }} onClick={() => sortBy('arrival')}>
          <Flex align={'center'} css={{ gap: 8 }}>
            {basicFieldName('Arrival Date', 'item')}
            {renderSortIcon(sort, 'arrival')}
          </Flex>
        </Th>
        <Th css={{ cursor: 'pointer' }} onClick={() => sortBy('item_status')}>
          <Flex align={'center'} css={{ gap: 8 }}>
            {t('inspectionStatus')}
            {renderSortIcon(sort, 'item_status')}
          </Flex>
        </Th>
        <Th css={{ cursor: 'pointer' }} onClick={() => sortBy('assignee_name')}>
          <Flex align={'center'} css={{ gap: 8 }}>
            {t('assignedTo')}
            {renderSortIcon(sort, 'assignee_name')}
          </Flex>
        </Th>
        <Th>
          <Flex align={'center'} css={{ gap: 8 }}>
            {basicFieldName('Stage', 'item')}
          </Flex>
        </Th>
        <Th css={{ cursor: 'pointer' }} onClick={() => sortBy('location')}>
          <Flex align={'center'} css={{ gap: 8 }}>
            {basicFieldName('Location', 'item')}
            {renderSortIcon(sort, 'location')}
          </Flex>
        </Th>
        <Th css={{ cursor: 'pointer' }} onClick={() => sortBy('updated_at')}>
          <Flex align={'center'} css={{ gap: 8 }}>
            {t('updatedAt')}
            {renderSortIcon(sort, 'updated_at')}
          </Flex>
        </Th>
      </Tr>
    </TableHead>
  )
})

const TableRow = memo(({ rowData, isSelected, onSelect, to, refetch }: any) => {
  const navigate = useNavigate()
  return (
    <>
      <Tr
        css={{ cursor: 'pointer', opacity: rowData.is_archived ? 0.6 : 1 }}
        onClick={() => {
          navigate(to)
        }}
      >
        <Td
          onClick={e => e.stopPropagation()}
          css={{
            width: 55,
            maxWidth: 55,
            borderLeft: '3px solid',
            borderColor: rowData.is_archived ? '$emory_orange' : 'transparent',
          }}
        >
          <Flex as="span" align={'center'} justify="center">
            <CheckboxRoot
              checked={isSelected}
              onCheckedChange={checked => {
                if (checked) {
                  onSelect((s: number[]) => s.concat(rowData.id))
                } else {
                  onSelect((s: number[]) => s.filter(id => id !== rowData.id))
                }
              }}
            >
              <CheckboxIndicator>
                <HiCheck />
              </CheckboxIndicator>
            </CheckboxRoot>
          </Flex>
        </Td>
        <Td>
          <Flex css={{ gap: 8 }} as="span" align={'center'}>
            <img src={rowData.image} className="item-image" alt="" />
            {rowData.mark}
          </Flex>
        </Td>
        <Td>
          <Flex as="span" align={'center'}>
            {rowData.title}
          </Flex>
        </Td>
        <Td>
          <Flex as="span" align={'center'}>
            {rowData.item_type}
          </Flex>
        </Td>

        <Td>
          <Flex as="span" align={'center'}>
            {rowData.arrival ? formatDate(rowData.arrival) : ''}
          </Flex>
        </Td>
        <Td>
          <Flex as="span" align={'center'}>
            {rowData.item_status}
          </Flex>
        </Td>
        <Td>
          <Flex
            onClick={(e: any) => {
              e.stopPropagation()
              e.preventDefault()
            }}
            css={{ gap: 8 }}
            as="span"
            align={'center'}
          >
            <Flex css={{ flexGrow: 1 }}>{rowData.assignee_name}</Flex>
            <AssignItemsPopup idList={[rowData.id]} onAssign={refetch}>
              <span className="edit-assigned-person-icon">
                <img src={editIcon} alt="" className="item-edit-icon" />
              </span>
            </AssignItemsPopup>
          </Flex>
        </Td>
        <Td>
          <Flex
            onClick={(e: any) => {
              e.stopPropagation()
              e.preventDefault()
            }}
            css={{ gap: 8 }}
            as="span"
            align={'center'}
          >
            <Flex css={{ flexGrow: 1 }}>{rowData.stage_name}</Flex>
          </Flex>
        </Td>
        <Td>
          <Flex as="span" align={'center'}>
            {rowData.location_status}
          </Flex>
        </Td>
        <Td>
          <Flex as="span" align={'center'}>
            {rowData.updated_at ? formatDate(rowData.updated_at) : ''}
          </Flex>
        </Td>
        <Td css={{ width: 80, maxWidth: 80 }} onClick={e => e.stopPropagation()}>
          <QrCodePopup id={rowData.mark} title={rowData.title} data={rowData.qr_code} type="item">
            <QrCodeIconButton />
          </QrCodePopup>
        </Td>
      </Tr>
    </>
  )
})

function ItemsList() {
  const { t } = useTranslation()
  const { translations, translationsLoaded } = useContext(ScreenOrderForTranslationContext)
  const basicFieldName = useBasicFieldName();
  const breadcrumbs = useBreadCrumbs(translations['items'])
  const {
    search,
    filters,
    offset,
    showArchived,
    itemsPerPage,
    sortBy,
    order,
    setUrlParam,
    getFilterData,
    selected,
    setSelected,
  } = useDataTable({
    cacheKey: 'item',
    initialFilterData: {
      creationDate: '',
      inspectionStatus: [],
      itemType: [],
      assignedTo: [],
      stage: [],
      arrivalDate: '',
      location: []
    },
  })

  const { isLoading, data, refetch } = useQuery(
    ['itemsList', search, filters, showArchived],
    () => getFilteredItemsList('', search.toLowerCase(), filters, !!showArchived),
    {
      enabled: localStorage.getItem('loggedIn') === 'true',
    }
  )

  const onClearAllFilters = () => setUrlParam('filter')
  const onApplyFilter = (modifiedFilters: FilterData) => {
    setUrlParam('filter', JSON.stringify(modifiedFilters))
  }

  const items = useMemo(() => {
    if (sortBy) {
      return (
        data
          ?.slice()
          .sort((a: any, b: any) => (order === 'asc' ? -1 : 1) * (a[sortBy] < b[sortBy] ? 1 : -1))
          .slice(offset, offset + itemsPerPage) || []
      )
    }

    return data?.slice(offset, offset + itemsPerPage) || []
  }, [data, offset, itemsPerPage, sortBy, order])

  const handlePageChange = useCallback(
    (selectedItem: { selected: number }) => {
      setSelected([])
      const newOffset = (selectedItem.selected * itemsPerPage) % data?.length
      setUrlParam('offset', newOffset)
    },
    [itemsPerPage, data?.length, setUrlParam]
  )

  const handleItemsPerPageChange = useCallback(e => setUrlParam('show', e.value), [setUrlParam])

  const handleCheckAll = useCallback(
    (checked: boolean) => {
      if (checked) {
        setSelected(items.map((c: any) => c.id))
      } else {
        setSelected([])
      }
    },
    [items]
  )

  return (
    <>
      {isLoading && <Loader />}
      {renderBreadCrumbs(breadcrumbs)}
      <Divider />
      <Toolbar>
        <SearchContainer>
          <SearchBar
            defaultValue={search}
            onChange={e => setUrlParam('search', e.target.value?.toLocaleLowerCase())}
            placeholder={translations['searchItems']!}
            type="search"
          />
        </SearchContainer>
        <FilterDropdown
          filterData={getFilterData as unknown as FilterData}
          applyFilter={onApplyFilter}
          onClearAll={onClearAllFilters}
        />
        <Link to="new" className="outline-none">
          <Button>+ {translations['addNewItem']}</Button>
        </Link>
        <Button
          onClick={() => setUrlParam('show_archived', showArchived ? undefined : 1)}
          intent={showArchived ? 'secondary' : 'primary'}
        >
          {showArchived ? t('hideArchived') : t('showArchived')}
        </Button>
        <BatchUploadPopup onSuccess={refetch} type="items">
          <Button intent="primary">{translations['batchUploadItems']}</Button>
        </BatchUploadPopup>
        <AssignItemsPopup idList={selected} onAssign={refetch}>
          {!!selected.length && (
            <TextButton
              btnStyle="textOnlyBtn"
              textColor="var(--colors-text)"
              width="auto"
              textDecoration="underline"
              padding="0.5rem 0"
            >
              {translations['assignItemsInBulk']}
            </TextButton>
          )}
        </AssignItemsPopup>
        <DownloadMultipleQrCodes type="item" itemsList={items.filter((item: any) => selected.includes(item.id))}>
          {!!selected.length && (
            <TextButton
              btnStyle="textOnlyBtn"
              textColor="var(--colors-text)"
              width="auto"
              textDecoration="underline"
              padding="0.5rem 0"
            >
              {t('printQRCodes')}
            </TextButton>
          )}
        </DownloadMultipleQrCodes>
        <div style={{ flexGrow: 1 }} />
        <img src={downloadIcon} onClick={()=>{downloadCSV(data, 'ITEM', basicFieldName)}} alt={t('download')||'Download'} />
      </Toolbar>
      <Flex css={{ overflow: 'auto' }}>
        <DataTable>
          <TableHeader
            checked={selected.length === items.length}
            onCheckAll={handleCheckAll}
            sort={{
              by: sortBy,
              order: order,
            }}
            setSort={(sort: ISort) => {
              setUrlParam('offset')
              setUrlParam('order', sort.order)
              setUrlParam('sortBy', sort.by)
            }}
          />
          <TableBody>
            {items.length ? (
              items.map((item: any, index: number) => {
                const isSelected = selected.some(id => item.id === id)
                return (
                  <TableRow
                    key={item.id}
                    index={index}
                    rowData={item}
                    isSelected={isSelected}
                    onSelect={setSelected}
                    to={`/dashboard/items/${item.id}`}
                    refetch={refetch}
                  />
                )
              })
            ) : (
              <Tr>
                <Td colSpan={100} css={{ color: '$calm_platinum', fontSize: 18, fontFamily: 500 }}>
                  <Flex align={'center'} justify="center">
                    {t('noDataAvailable')}!
                  </Flex>
                </Td>
              </Tr>
            )}
          </TableBody>
        </DataTable>
      </Flex>
      <TableFooter
        offset={offset}
        itemsPerPage={itemsPerPage}
        data={data}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </>
  )
}

export default ItemsList
