import React, {useEffect} from "react";
import withTitle from "../../hoc/withTitle";
import i18n from "../../i18n/i18n";


const TermsTrialPage = () => {
  useEffect(() => {
    document?.getElementById('trialTermsPDF')?.click();
  });
  return (
    <a id='trialTermsPDF' href={'https://emory-files-prod.s3.eu-central-1.amazonaws.com/static+files/signup_trial_terms_dec23.pdf'} ></a>
    )
}
export default withTitle(TermsTrialPage, `Emory - ${i18n.t("termsAndConditions")}`)
