import React, { useContext } from 'react'
import { useState } from 'react'
import InputField from '../../../components/InputField/inputField'
import { getEmailErrorMsg, showAlertForError } from '../../../helpers/validations'
import '../../organisationSettings/tabs/companyDetails/companyDetails.scss'
import DropdownSelect from '../../../components/dropdownSelect/dropdownSelect'
import Button from '../../../components/Button/button'
import { useEffect } from 'react'
import { addNewItem, getItemTypes } from '../../../api/apiCalls'
import arrowRight from '../../../assets/images/arrow_right_icon.svg'
import { ContainerListContext } from '../../../contexts/containerListContext'
import 'react-calendar/dist/Calendar.css'
import './addNewItem.scss'
import CustomMessagePopup from '../../../components/CustomMessagePopup/customMessagePopup'
import { css } from '@emotion/react'
import ClipLoader from 'react-spinners/ClipLoader'
import variables from '../../../themes.module.scss'
import { UserPermissionsContext } from '../../../contexts/userPermissionsContext'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { StageListContext } from '../../../contexts/stageListContext'
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../../../components/Loader/loader'
import useImageCompressorAndUploader from '../../../hooks/useImageCompressorAndUploader'
import { APP_CONSTANTS } from '../../../configs/constants'
import { t } from "i18next";
import { useBasicFieldName } from "../../../hooks/useBasicFieldName";
import { ScreenOrderForTranslationContext } from '../../../contexts/userDetailsContext'

const override = css`
  display: block;
  margin: 0 auto;
`

const AddNewItem = (props: any) => {
  const { translations, translationsLoaded } = useContext(ScreenOrderForTranslationContext)
  const { compressAndUploadImage } = useImageCompressorAndUploader()
  const [loading, showLoader] = useState(false)
  const [containerList, setContainerList, { isLoading, isSuccess, isError, data, error, refetch }] = useContext(ContainerListContext)
  const [itemTypeId, setItemTypeId] = useState<any>()
  const [stageId, setStageId] = useState<any>()
  const [containerTypeId, setContainerTypeId] = useState<any>()
  const [itemTypes, setItemTypes] = useState<any>()
  const [stagesList, setStagesList] = useState<any>()
  const [itemType, setItemType] = useState<any>()
  const [containerTypes, setContainerTypes] = useState<any>()
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [contactEmail, setContactEmail] = useState<string>('')
  const [contactEmailError, setContactEmailError] = useState<string>('')
  const [markNum, setMarkNum] = useState<string>('')
  const [locationStatus, setLocationStatus] = useState<string>('')
  const [arrival, setArrival] = useState<any>(null)
  const [arrivalError, setArrivalError] = useState('')
  const [arrivalValue, setArrivalValue] = useState<any>(null)
  const [itemImage, setItemImage] = useState<any>(null)
  const [itemId, setItemId] = useState<string>('')
  const [showItemCreatedPopup, setShowItemCreatedPopup] = useState<boolean>(false)
  const [imgLoading, setImgLoading] = useState<boolean>(false)
  const { ...userPermissionsData } = useContext(UserPermissionsContext)
  const userPermissions = userPermissionsData?.data
  const [stageList, { ...stagesData }] = useContext(StageListContext)
  const basicFieldName = useBasicFieldName();

  const onArrivalDateChange = (val: any) => {
    val.setHours(12)
    setArrivalValue(val)
    setArrival(val.getTime())
  }

  const onItemImageSelect = async (event: any) => {
    if (event.target.files[0]) {
      setImgLoading(true)
      const res: any = await compressAndUploadImage(event.target.files[0])
      if (res && res.file_path) {
        setItemImage(res.file_path)
      }
      setImgLoading(false)
    }
  }


  const onContactEmailChangeHandler = (val: any) => {
    setContactEmail(val.toString())
    setContactEmailError(getEmailErrorMsg(val))
  }


  const onItemTypeChange = (val: any) => {
    setItemTypeId(val)
  }

  const onStageChange = (val: any) => {
    setStageId(val)
  }

  const onContainerTypeChange = (val: any) => {
    setContainerTypeId(val)
  }

  const navigate = useNavigate()

  const onSaveChangesClick = () => {
    if (!title || !markNum || contactEmailError || arrivalError || !itemTypeId) {
      if (!title && !itemTypeId) {
        alert(`Please enter ${basicFieldName('Title', 'item')} and
        select ${basicFieldName('Item Type', 'item')}`)
      } else if (!title) {
        alert('Please enter Title!')
      } else if (!markNum) {
        alert(`Please enter ${basicFieldName('Mark', 'item')}`)
      } else if (contactEmailError) {
        alert(`Please enter ${basicFieldName('Customer Email', 'item')} in correct format!`)
      } else if (!itemTypeId) {
        alert('Please select item type!')
      }
    } else {
      const token = localStorage.getItem('accessToken')
      const data = {
        item_type_id: itemTypeId,
        title: title,
        description: description,
        customer_email: contactEmail,
        mark: markNum,
        image: itemImage,
        stage_id: stageId,
        location_status: locationStatus,
        arrival: arrival,
        container_id: containerTypeId,
      }
      console.log(data)
      showLoader(true)
      addNewItem(data, token)
        .then(response => {
          showLoader(false)
          if (response.status === 201) {
            navigate(`/dashboard/items/${response.data.id}`)
            setItemId(response.data.id)
          } else {
            alert(response.data.errors.message)
          }
        })
        .catch(error => {
          showLoader(false)
          console.log(error)
          showAlertForError(error)
        })
    }
  }

  const onCustomPopupDismiss = () => {
    setShowItemCreatedPopup(false)
    navigate(`/dashboard/items/${itemId}`)
  }

  useEffect(() => {
    const token = localStorage.getItem('accessToken')
    getItemTypes(token, '')
      .then(response => {
        const tempArr: any = []
        if (response.item_types && response.item_types.length > 0) {
          response.item_types.forEach((item: any) => {
            const data = {
              value: item.id,
              label: item.name,
            }
            tempArr.push(data)
          })
          setItemTypes(tempArr)
        }
      })
      .catch(error => {
        console.log(error)
        showAlertForError(error)
      })

    refetch()
    userPermissionsData.refetch()
    stagesData.refetch()
  }, [])

  useEffect(() => {
    if (containerList) {
      const tempArr: any = []
      containerList.forEach((item: any) => {
        const data = {
          value: item.id,
          label: item.title,
        }
        tempArr.push(data)
      })
      setContainerTypes(tempArr)
    }
    if (stageList?.data) {
      const tempStageArr: any = []
      stageList.data.forEach((item: any) => {
        const data = {
          value: item.id,
          label: item.name,
        }
        tempStageArr.push(data)
      })
      setStagesList(tempStageArr)
    }
  }, [containerList, stageList])

  return (
    <>
      {loading && <Loader />}
      {showItemCreatedPopup && (
        <CustomMessagePopup dismissHandler={onCustomPopupDismiss} message={'New Item with id: ' + itemId + ' created!'} />
      )}
      <div className='sub-tab-content-container'>
        <div className='display_flex add-member-header-container'>
          <Link className='selected-tab-page-title cursor_pointer' to='../'>
            {translations['items']}
          </Link>
          <img src={arrowRight} className='arrow-right-icon' />
          <p className='sub-tab-selected-title'>{translations['createNewItem']}</p>
        </div>
        <p className='tabs-page-title-bottom-border' />
        <div className='display_flex flex-row-input-fields-container row-input-fields-container-margin-top'>
          <div className='row-left-field-container width_50'>
            <InputField
              label={`${basicFieldName('Title', 'item')}*`}
              value={title}
              placeholder={`Enter ${basicFieldName('Title', 'item')}`}
              onChange={(val: string) => setTitle(val)}
              type='text'
              error={false}
            />
          </div>
          <div className='row-right-field-container width_50'>
            <InputField
              label={`${basicFieldName('Mark', 'item')}*`}
              value={markNum}
              placeholder={`Enter ${basicFieldName('Mark', 'item')}`}
              onChange={(val: string) => setMarkNum(val)}
              type='text'
              error={false}
            />
          </div>
        </div>

        <div className='display_flex flex-row-input-fields-container row-input-fields-container-margin-top'>
          <div className='row-left-field-container width_50'>
            <InputField
              label={basicFieldName('Description', 'item')}
              value={description}
              placeholder={`Enter ${basicFieldName('Description', 'item')}`}
              onChange={(val: any) => setDescription(val)}
              type='text'
              error={false}
            />
          </div>
          <div className='row-right-field-container width_50'>
            <InputField
              label={basicFieldName('Customer Email', 'item')}
              value={contactEmail}
              placeholder={`Enter ${basicFieldName('Customer Email', 'item')}`}
              onChange={onContactEmailChangeHandler}
              type='email'
              error={contactEmailError}
            />
          </div>
        </div>

        <div className='display_flex flex-row-input-fields-container row-input-fields-container-margin-top'>
          <div className='row-left-field-container width_50'>
            <DropdownSelect
              label={`${basicFieldName('Item Type', 'item')}*`}
              placeholder={`Select ${basicFieldName('Item Type', 'item')}`}
              options={itemTypes}
              onChange={(val: any) => onItemTypeChange(val)}
              id='com-type-dropdown'
              value={itemTypeId}
              index={0}
            />
          </div>
          <div className='row-right-field-container width_50'>
            <DropdownSelect
              label={basicFieldName('Stage', 'item')}
              placeholder={`Select ${basicFieldName('Stage', 'item')}`}
              options={stagesList}
              onChange={(val: any) => onStageChange(val)}
              id='company-type-dropdown'
              value={stageId}
              index={2}
            />
          </div>
        </div>
        <div className='display_flex flex-row-input-fields-container row-input-fields-container-margin-top'>
          <div className='row-left-field-container width_50'>
            <p className='input-field-label'>{basicFieldName('Arrival Date', 'item')} (dd/mm/yyyy)</p>
            <div style={{ display: 'grid' }}>
              <DatePicker
                dateFormat='dd MMM yyyy'
                showYearDropdown
                showMonthDropdown
                dropdownMode='select'
                className='input-field'
                selected={arrivalValue}
                onChange={(date: Date) => onArrivalDateChange(date)}
                placeholderText={`Select ${basicFieldName('Arrival Date', 'item')}`}
              />
            </div>
          </div>

          <div className='row-right-field-container width_50'>
            <InputField
              label={basicFieldName('Location', 'item')}
              value={locationStatus}
              placeholder={`Enter ${basicFieldName('Location', 'item')}`}
              onChange={(val: string) => setLocationStatus(val)}
              type='text'
              error={false}
            />
          </div>
        </div>

        <div className='display_flex flex-row-input-fields-container row-input-fields-container-margin-top'>
          {userPermissions && userPermissions.data.manage_container && (
            <div className='row-left-field-container width_50'>
              <DropdownSelect
                label={basicFieldName('Container Title', 'item')}
                placeholder={`Select ${basicFieldName('Container Title', 'item')}`}
                options={containerTypes}
                onChange={(val: any) => onContainerTypeChange(val)}
                id='company-type-dropdown'
                value={containerTypeId}
                index={1}
              />
            </div>
          )}

          {/* <div className="row-right-field-container row-input-fields-container-margin-top"> */}
          <div
            className={
              userPermissions && userPermissions.data.manage_container
                ? 'row-right-field-container width_50'
                : 'row-left-field-container width_50'
            }
          >
            <p className='input-field-label'>{translations['itemImage']}</p>
            <div className='add-logo-field-container'>
              <input type='file' className='logo-upload-input' onChange={onItemImageSelect} accept={APP_CONSTANTS.ACCEPTED_IMAGE_EXTENSIONS} />
              {imgLoading ? (
                <ClipLoader color={variables.digitalMint} loading={imgLoading} css={override} size={18} />
              ) : itemImage ? (
                <img src={itemImage} className='square-brand-logo-img' alt='item' />
              ) : (
                '+'
              )}
            </div>
          </div>
        </div>

        <div className='company-details-bottom-btn-container'>
          <div className='display_flex company-details-bottom-btn-inner-container'>
            <Link to='..'>
              <Button backgroundColor='#EEEEEE' textColor='#333333' padding='0.75rem 2rem' fontSize='12px' height='fit-content' width='auto'>
                {t('cancel')}
              </Button>
            </Link>
            <Button padding='0.75rem 2rem' fontSize='12px' height='fit-content' width='auto' leftMargin='1.25rem' onClick={onSaveChangesClick}>
              {translations['createItem']}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddNewItem
