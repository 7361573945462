import React, { useContext } from 'react'
import { useState } from 'react'
import InputField from '../../../components/InputField/inputField'
import { decimalNumberInputValidation, getEmailErrorMsg, phoneNumberValidation, showAlertForError } from '../../../helpers/validations'
import '../../organisationSettings/tabs/companyDetails/companyDetails.scss'
import DropdownSelect from '../../../components/dropdownSelect/dropdownSelect'
import Button from '../../../components/Button/button'
import variables from '../../../themes.module.scss'
import { useEffect } from 'react'
import { addNewOrganisation, updateCompanyDetails, uploadFile } from '../../../api/apiCalls'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { css } from '@emotion/react'
import ClipLoader from 'react-spinners/ClipLoader'
import './superadminAddNewOrg.scss'
import arrowRight from '../../../assets/images/arrow_right_icon.svg'
import '../../organisationSettings/organisationSettings.scss'
import { SuperadminSelectedOrgContext } from '../../../contexts/superadminSelectedOrgContext'
import { useTranslation } from 'react-i18next'

const override = css`
  display: block;
  margin: 0 auto;
`

const SuperadminAddNewOrg = (props: any) => {
  const [companyName, setCompanyName] = useState<string>('')
  const [companyEmail, setCompanyEmail] = useState<string>('')
  const [companyAddress, setCompanyAddress] = useState<string>('')
  const [mainPhoneNumber, setMainPhoneNumber] = useState<string>('')
  const [companyType, setCompanyType] = useState('')
  const [companyWebsite, setCompanyWebsite] = useState<string>('')
  const [companyVATid, setCompanyVATid] = useState<string>('')
  const [companyTAXid, setCompanyTAXid] = useState<string>('')
  const [preferredLanguage, setPreferredLanguage] = useState<string>('')
  const [companyRegistrationNum, setCompanyRegistrationNum] = useState<string>('')
  const [paymentChannel, setPaymentChannel] = useState<string>('')
  const [companyEmailError, setCompanyEmailError] = useState<string>('')
  const [wideBrandLogo, setWideBrandLogo] = useState<any>(null)
  const [squareBrandLogo, setSquareBrandLogo] = useState<any>(null)
  const [editable, setEditable] = useState<boolean>(false)
  const [imgLoading, setImgLoading] = useState<boolean>(false)
  const [imgUploadingId, setImgUploadingId] = useState<any>('')
  const [orgId, setOrgId] = useState<any>(null)
  const [checkreportPrice, setCheckreportPrice] = useState<any>('')
  const [orgDetails, setOrgDetails] = useContext(SuperadminSelectedOrgContext)

  const onCompanyEmailChangeHandler = (value: string) => {
    setCompanyEmail(value)
    setCompanyEmailError(getEmailErrorMsg(value))
  }

  const onWideBrandLogoSelect = async (event: any) => {
    setImgUploadingId('wide-logo')
    if (event.target.files[0]) {
      // console.log(event.target.files[0])
      setImgLoading(true)
      const res: any = await uploadFile(event.target.files[0])
      const imgUrl = res.file_path || ''
      setWideBrandLogo(imgUrl)
      setImgLoading(false)
      setImgUploadingId('')
    }
  }

  const onSquareBrandLogoSelect = async (event: any) => {
    setImgUploadingId('square-logo')
    if (event.target.files[0]) {
      setImgLoading(true)
      const res: any = await uploadFile(event.target.files[0])
      const imgUrl = res.file_path || ''
      setSquareBrandLogo(imgUrl)
      setImgLoading(false)
      setImgUploadingId('')
    }
  }

  const onMainPhoneNumberChangeHandler = (value: any) => {
    if (phoneNumberValidation(value)) {
      setMainPhoneNumber(value)
    }
  }

  const onCompanyWebsiteChangeHandler = (value: any) => {
    setCompanyWebsite(value)
  }

  const onCompanyVATidChangeHandler = (value: any) => {
    setCompanyVATid(value)
  }

  const onCompanyTAXidChangeHandler = (value: any) => {
    setCompanyTAXid(value)
  }

  const onCompanyRegistrationNumChangeHandler = (value: any) => {
    setCompanyRegistrationNum(value)
  }

  const companyTypeOptions = [
    { value: 'Private', label: 'Private' },
    { value: 'Business', label: 'Business' },
  ]

  const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'de', label: 'German' },
    { value: 'es', label: 'Spanish' },
  ]

  const paymentChannelOptions = [
    { value: 'card', label: 'card' },
    { value: 'netbanking', label: 'netbanking' },
  ]

  const onEditDataClick = () => {
    setEditable(true)
    // setDisableTabs(true)
  }

  const onCancelClick = () => {
    setEditable(false)
  }

  const onSaveChangesClick = () => {
    if (!companyName || !companyEmail) {
      if (!companyName) {
        alert('Please Enter Company Name!')
      }
      if (!companyEmail) {
        alert('Please Enter Company Email!')
      }
    } else {
      const data = {
        name: companyName,
        email: companyEmail,
        wide_brand_logo: wideBrandLogo,
        square_brand_logo: squareBrandLogo,
        address: companyAddress,
        main_phone: mainPhoneNumber,
        type: companyType,
        website: companyWebsite,
        vat_id: companyVATid,
        tax_id: companyTAXid,
        registration_name: companyRegistrationNum,
        preferred_language: preferredLanguage,
        preferred_payment_channel: paymentChannel,
        checkreport_price: parseFloat(checkreportPrice),
      }
      // console.log(data)
      const token = localStorage.getItem('adminAccessToken')
      if (props.addNewOrgStatus) {
        props.showLoader(true)
        addNewOrganisation(data, token)
          .then(response => {
            props.showLoader(false)
            setEditable(false)
            if (response.status === 201) {
              showSuccessToast()
              props.setAddOrgStatus(false)
            } else {
              showFailureToast()
            }
          })
          .catch(error => {
            props.showLoader(false)
            setEditable(false)
            console.log(error)
            showAlertForError(error)
          })
      } else {
        props.showLoader(true)
        updateCompanyDetails(data, orgId, token)
          .then(response => {
            // console.log(response)
            // refetch();
            props.showLoader(false)
            setEditable(false)
            // setDisableTabs(false)
            if (response.status === 200) {
              showSuccessToast()
              props.setEditOrgStatus(false)
            } else {
              showFailureToast()
            }
            // console.log(response)
          })
          .catch(error => {
            props.showLoader(false)
            setEditable(false)
            // setDisableTabs(false)
            console.log(error)
            showAlertForError(error)
          })
      }
    }
  }

  const onTitleClick = () => {
    if (props.editOrgStatus) {
      props.setEditOrgStatus(false)
    } else {
      props.setAddOrgStatus(false)
    }
  }

  const onCheckReportPriceChange = (val: any) => {
    if (val === '' || decimalNumberInputValidation(val)) {
      setCheckreportPrice(val)
    } else if (!checkreportPrice) {
      setCheckreportPrice('')
    }
  }

  const showSuccessToast = () => {
    toast.success('Company details saved!', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  const showFailureToast = () => {
    toast.error('An error occurred while saving company details!', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  useEffect(() => {
    if (props.editOrgStatus) {
      if (orgDetails) {
        setOrgId(orgDetails.id)
        setCompanyName(orgDetails.name)
        setCompanyEmail(orgDetails.email)
        setWideBrandLogo(orgDetails.wide_brand_logo)
        setCompanyAddress(orgDetails.address)
        setSquareBrandLogo(orgDetails.square_brand_logo)
        setMainPhoneNumber(orgDetails.main_phone)
        setCompanyType(orgDetails.type)
        setCompanyWebsite(orgDetails.website)
        setCompanyVATid(orgDetails.vat_id)
        setCompanyTAXid(orgDetails.tax_id)
        setCompanyRegistrationNum(orgDetails.registration_name)
        setPreferredLanguage(orgDetails.preferred_language)
        setPaymentChannel(orgDetails.preferred_payment_channel)
        setCheckreportPrice(orgDetails.checkreport_price)
      }
    } else {
      setEditable(true)
    }
  }, [])

  const { t } = useTranslation()

  return (
    <>
      <div className='organisation-settings-outer-container'>
        <ToastContainer
          position='bottom-center'
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          theme='colored'
        />

        <div className='sub-tab-content-container company-details-tab-outer-container'>
          <div className='display_flex add-edit-org-title-container'>
            <p
              className='selected-tab-page-title manage-members-page-title members-list-page-title cursor_pointer no_left_right_padding'
              onClick={onTitleClick}
            >
              Organisations
            </p>
            <img src={arrowRight} className='arrow-right-icon' />
            <p className='sub-tab-selected-title'>{props.editOrgStatus ? 'Edit Organisation Details' : 'Add New Organisation'}</p>
          </div>
          <p className='tabs-page-title-bottom-border' />
          <div className={!editable ? 'edit-data-btn add-org-edit-btn' : 'edit-data-btn add-org-edit-btn reduced_opacity_4'}>
            <Button
              btnStyle='textOnlyBtn'
              textColor='#000000'
              width='auto'
              textDecoration={!editable && 'underline'}
              padding='0.5rem 0'
              // onClick={!editable ? onEditDataClick : onCancelClick}
              onClick={onEditDataClick}
              className={editable && 'cursor_default'}
            >
              {!editable ? t('edit') : t('inEditMode')}
            </Button>
          </div>

          <div className='display_flex flex-row-input-fields-container'>
            <div className='add-new-item-left-col-container'>
              <div className='row-left-field-container'>
                <InputField
                  label='Company Name'
                  value={companyName}
                  placeholder='Enter Customer Name'
                  onChange={(val: string) => setCompanyName(val)}
                  type='text'
                  error={false}
                  disabled={!editable}
                  // required={true}
                  className='super-admin-input-field'
                />
              </div>
              {props.editOrgStatus && (
                <>
                  <div className='row-left-field-container row-input-fields-container-margin-top'>
                    <p className='input-field-label'>Company Wide Brand Logo</p>
                    <div className='display_flex org-settings-img-upload-container'>
                      <div className={editable ? 'add-logo-field-container' : 'add-logo-field-container disabled-background'}>
                        <input type='file' className='logo-upload-input' onChange={onWideBrandLogoSelect} disabled={!editable} />
                        {imgUploadingId === 'wide-logo' ? (
                          <ClipLoader color={variables.digitalMint} loading={imgLoading} css={override} size={18} />
                        ) : wideBrandLogo ? (
                          <img src={wideBrandLogo} className='wide-brand-logo-img' />
                        ) : (
                          '+ ADD LOGO'
                        )}
                      </div>
                      <p className='img-upload-req-text'>460 x 200 | JPEG, PNG supported</p>
                    </div>
                  </div>

                  <div className='row-left-field-container row-input-fields-container-margin-top'>
                    <p className='input-field-label'>Company Square Brand Logo</p>
                    <div className='display_flex org-settings-img-upload-container'>
                      <div className={editable ? 'add-logo-field-container' : 'add-logo-field-container disabled-background'}>
                        <input type='file' className='logo-upload-input' onChange={onSquareBrandLogoSelect} disabled={!editable} />
                        {imgUploadingId === 'square-logo' ? (
                          <ClipLoader color={variables.digitalMint} loading={imgLoading} css={override} size={18} />
                        ) : squareBrandLogo ? (
                          <img src={squareBrandLogo} className='square-brand-logo-img' />
                        ) : (
                          '+'
                        )}
                      </div>
                      <p className='img-upload-req-text'>250 x 250 | JPEG, PNG supported</p>
                    </div>
                  </div>
                </>
              )}

              <div className='row-left-field-container row-input-fields-container-margin-top'>
                <DropdownSelect
                  label='Company Type'
                  placeholder='Select Company Type'
                  options={companyTypeOptions}
                  onChange={(val: any) => setCompanyType(val)}
                  id='company-type-dropdown'
                  value={companyType}
                  index={0}
                  disabled={!editable}
                  className='super-admin-input-field'
                />
              </div>

              <div className='row-left-field-container row-input-fields-container-margin-top'>
                <InputField
                  label='Company VAT ID'
                  value={companyVATid}
                  placeholder='Enter Company VAT ID'
                  onChange={onCompanyVATidChangeHandler}
                  type='text'
                  error={false}
                  disabled={!editable}
                  className='super-admin-input-field'
                />
              </div>

              <div className='row-left-field-container row-input-fields-container-margin-top'>
                <InputField
                  label='Company Registration Number'
                  value={companyRegistrationNum}
                  placeholder='Company Registration Number'
                  onChange={onCompanyRegistrationNumChangeHandler}
                  type='text'
                  error={false}
                  disabled={!editable}
                  className='super-admin-input-field'
                />
              </div>

              <div className='row-left-field-container row-input-fields-container-margin-top no-right-margin'>
                <DropdownSelect
                  label='Preferred Payment Channel'
                  placeholder='Select Preferred Payment Channel'
                  options={paymentChannelOptions}
                  onChange={(val: any) => setPaymentChannel(val)}
                  value={paymentChannel}
                  id='preferred-payment-channel'
                  index={1}
                  className='preferred-payment-channel-dropdown super-admin-input-field'
                  disabled={!editable}
                />
              </div>

              {!props.editOrgStatus && (
                <div className='row-left-field-container row-input-fields-container-margin-top'>
                  <InputField
                    label='Check Report Price (in Euros)'
                    value={checkreportPrice}
                    placeholder='Enter Check Report Price'
                    onChange={(val: any) => onCheckReportPriceChange(val)}
                    type='text'
                    error={false}
                    disabled={!editable}
                    className='super-admin-input-field'
                  />
                </div>
              )}
            </div>

            <div className='add-new-item-right-col-container org-settings-company-details-right-col'>
              <div className='row-right-field-container'>
                <InputField
                  label='Company Email'
                  value={companyEmail}
                  placeholder='Enter Primary Contact Email'
                  onChange={onCompanyEmailChangeHandler}
                  type='email'
                  error={companyEmailError}
                  disabled={!editable}
                  className='super-admin-input-field'
                />
              </div>

              <div className='row-right-field-container row-input-fields-container-margin-top'>
                <InputField
                  label='Company Address'
                  value={companyAddress}
                  // error={memberAddressError}
                  placeholder='Enter Company Address'
                  onChange={(val: string) => setCompanyAddress(val)}
                  textarea={true}
                  disabled={!editable}
                  className='member-address-textarea super-admin-input-field'
                  // className="user-profile-input-field user-profile-textarea-style"
                />
              </div>

              <div className='row-right-field-container row-input-fields-container-margin-top'>
                <InputField
                  label='Add Main Phone Number'
                  value={mainPhoneNumber}
                  placeholder='Enter Main Phone Number'
                  onChange={onMainPhoneNumberChangeHandler}
                  type='text'
                  error={false}
                  disabled={!editable}
                  className='super-admin-input-field'
                />
              </div>

              <div className='row-right-field-container row-input-fields-container-margin-top'>
                <InputField
                  label='Company Website'
                  value={companyWebsite}
                  placeholder='Enter Company Website'
                  onChange={onCompanyWebsiteChangeHandler}
                  type='text'
                  error={false}
                  disabled={!editable}
                  className='super-admin-input-field'
                />
              </div>

              <div className='row-right-field-container row-input-fields-container-margin-top'>
                <InputField
                  label='Company Tax ID'
                  value={companyTAXid}
                  placeholder='Enter Company Tax ID'
                  onChange={onCompanyTAXidChangeHandler}
                  type='text'
                  error={false}
                  disabled={!editable}
                  className='super-admin-input-field'
                />
              </div>

              <div className='row-right-field-container row-input-fields-container-margin-top'>
                <DropdownSelect
                  label='Preferred Language'
                  placeholder={t('selectPreferredLang')!}
                  options={languageOptions}
                  onChange={(val: any) => setPreferredLanguage(val)}
                  id='preferred-language-dropdown'
                  value={preferredLanguage}
                  index={2}
                  disabled={!editable}
                  className='super-admin-input-field'
                />
              </div>
              {props.editOrgStatus && (
                <div className='row-right-field-container row-input-fields-container-margin-top'>
                  <InputField
                    label='Check Report Price (in Euros)'
                    value={checkreportPrice}
                    placeholder='Enter Check Report Price'
                    onChange={(val: any) => onCheckReportPriceChange(val)}
                    type='text'
                    error={false}
                    disabled={!editable}
                    className='super-admin-input-field'
                  />
                </div>
              )}
            </div>
          </div>

          <div className='company-details-bottom-btn-container'>
            {editable && (
              <div className='display_flex company-details-bottom-btn-inner-container'>
                <Button
                  backgroundColor={variables.clearSilver}
                  textColor='#333333'
                  padding='0.75rem 2rem'
                  fontSize='12px'
                  height='fit-content'
                  width='auto'
                  onClick={onCancelClick}
                >
                  {t('discard')}
                </Button>

                <Button
                  padding='0.75rem 2rem'
                  fontSize='12px'
                  height='fit-content'
                  width='auto'
                  leftMargin='1.25rem'
                  onClick={onSaveChangesClick}
                  disabled={!editable}
                  backgroundColor='#212e4a'
                >
                  Save Changes
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default SuperadminAddNewOrg
