import { t } from 'i18next'
import QueryString from 'qs'
import React, { FormEvent, useContext, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { updateContainer, getDataTypes } from '../../../../api/apiCalls'
import trashIcon from '../../../../assets/images/trash_icon.svg'
import Button from '../../../../components/Button/button'
import { DataTable, TableBody, TableHead, Td, Th, Tr } from '../../../../components/dataTable/DataTable'
import DropdownSelect from '../../../../components/dropdownSelect/dropdownSelect'
import Flex from '../../../../components/flex/Flex'
import ImageField from '../../../../components/ImageField/ImageField'
import InputField from '../../../../components/InputField/inputField'
import Loader from '../../../../components/Loader/loader'
import Tooltip from '../../../../components/tooltip'
import Text from '../../../../components/typography/Text'
import { useBreadCrumbs } from '../../../../contexts/breadcrumbs'
import { ScreenOrderForTranslationContext, UserDetailsContext } from '../../../../contexts/userDetailsContext'
import withTitle from '../../../../hoc/withTitle'
import { styled } from '../../../../stitches.config'

const Title = styled(Text, {
  fontWeight: 700,
  fontSize: 16,
  lineHeight: '21px',
  marginTop: '2rem',
  flex: 1,
  mb: 16,
})

const info = (
  <svg
    height={15}
    width={15}
    className="iv-aspect-square"
    fill="currentColor"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 1.77748C4.6996 1.77748 1.82143 4.65565 1.82143 8.20605C1.82143 11.7565 4.6996 14.6346 8.25 14.6346C11.8004 14.6346 14.6786 11.7565 14.6786 8.20605C14.6786 4.65565 11.8004 1.77748 8.25 1.77748ZM0.75 8.20605C0.75 4.06392 4.10786 0.706055 8.25 0.706055C12.3921 0.706055 15.75 4.06392 15.75 8.20605C15.75 12.3482 12.3921 15.7061 8.25 15.7061C4.10786 15.7061 0.75 12.3482 0.75 8.20605Z"
      fill="currentColor"
      fillOpacity="0.4"
    />
    <path
      d="M8.78571 6.2138V11.1486H9.32143C9.6173 11.1486 9.85714 11.3884 9.85714 11.6843C9.85714 11.9801 9.6173 12.22 9.32143 12.22H7.17857C6.8827 12.22 6.64286 11.9801 6.64286 11.6843C6.64286 11.3884 6.8827 11.1486 7.17857 11.1486H7.71429V7.28523H7.17857C6.8827 7.28523 6.64286 7.04538 6.64286 6.74952C6.64286 6.45365 6.8827 6.2138 7.17857 6.2138H8.78571Z"
      fill="currentColor"
      fillOpacity="0.4"
    />
    <path
      d="M9.05357 4.83019C9.05357 5.27399 8.6938 5.63376 8.25 5.63376C7.8062 5.63376 7.44643 5.27399 7.44643 4.83019C7.44643 4.38639 7.8062 4.02662 8.25 4.02662C8.6938 4.02662 9.05357 4.38639 9.05357 4.83019Z"
      fill="currentColor"
      fillOpacity="0.4"
    />
  </svg>
)

type LayoutVector = {
  is_active: 0 | 1
  id?: string
  image?: string
  name?: string
}

type ItemTypeField = {
  is_active: 0 | 1
  id?: string
  data_type_id: number
  field_name?: string
  field_name_i18n?: any
}

const TableRow = ({ prefix, item, index, fieldTypeOptions, isActiveDefaultValue, supportedLocales, hideDelete }: any) => {
  const [isActive, setIsActive] = useState(isActiveDefaultValue)
  const [selectedItemType, setSelectedItemType] = useState(item.data_type_id)

  if (!isActive) return null

  return (
    <Tr css={{ height: 60 }} key={`index-${index}-${item.id}`}>
      <Td>
        <input type="hidden" name={`${prefix}[${index}].id`} value={item.id} />
        <input type="hidden" name={`${prefix}[${index}].key`} value={item.key} />
        <input type="hidden" name={`${prefix}[${index}].sequence`} value={index + 1} />
        <InputField
          required
          containerClassName="add-standard-field-input-container"
          className="add-new-item-field-value upload-item-layout-front-view-text show-input-bg"
          defaultValue={item.field_name}
          placeholder="--"
          name={`${prefix}[${index}].field_name`}
          type="text"
          readOnly={hideDelete}
        />
      </Td>
      {supportedLocales.map((locale: string) => (
        <Td key={locale}>
          <InputField
            containerClassName="add-standard-field-input-container"
            className="add-new-item-field-value upload-item-layout-front-view-text show-input-bg"
            defaultValue={JSON.parse(item.field_name_i18n || '{}')[locale]}
            placeholder="--"
            name={`${prefix}[${index}].field_name_i18n.${locale}`}
            type="text"
          />
        </Td>
      ))}
      <Td>
        <DropdownSelect
          placeholder="Field Type"
          options={fieldTypeOptions}
          defaultValue={+item.data_type_id as any}
          name={`${prefix}[${index}].data_type_id`}
          index={item.id}
          className="add-standard-field-dropdown-container"
          inputClassName="add-new-item-field-value upload-item-layout-front-view-text show-input-bg"
          inputHeight="h-9"
          disabled={hideDelete}
          value={selectedItemType}
          onChange={(val: any) => setSelectedItemType(val)}
        />
      </Td>
      {!hideDelete && (
        <Td>
          <Flex
            as="button"
            align="center"
            justify="center"
            css={{ all: 'unset', cursor: 'pointer' }}
            type="button"
            aria-label="delete"
          >
            <img src={trashIcon} onClick={() => setIsActive(0)} />
          </Flex>
        </Td>
      )}
    </Tr>
  )
}

const localeMap = {
  en: 'English',
  de: 'German',
  es: 'Spanish',
}

function EditContainer() {
  const navigate = useNavigate()
  const { translations, translationsLoaded } = useContext(ScreenOrderForTranslationContext)

  const { data: userDetails, isLoading, queryKey } = useContext(UserDetailsContext)

  const supported_locales = JSON.parse(userDetails?.supported_locales || '[]')
  useBreadCrumbs(translations['container'], null)

  const container = userDetails?.container

  const [newLayoutVectors, setNewLayoutVectors] = useState<LayoutVector[]>([])
  const [newInspectionFields, setNewInspectionFields] = useState<ItemTypeField[]>([])
  const [newStandardFields, setNewStandardFields] = useState<ItemTypeField[]>([])

  const queryClient = useQueryClient()

  const updateMutation = useMutation(updateContainer, {
    onSuccess: (response, variables) => {
      if (response.data.message === 'success') {
        showSuccessToast()

        queryClient.setQueryData(queryKey, (data: any) => {
          return {
            ...data,
            container: {
              ...data?.container,
              ...variables,
              basic_fields: variables?.basic_fields?.map((s: any) => ({
                ...s,
                field_name_i18n: JSON.stringify(s.field_name_i18n || {}),
              })),
              standard_fields: variables?.standard_fields?.map((s: any) => ({
                ...s,
                field_name_i18n: JSON.stringify(s.field_name_i18n || {}),
              })),
              inspection_fields: variables?.inspection_fields?.map((i: any) => ({
                ...i,
                field_name_i18n: JSON.stringify(i.field_name_i18n || {}),
              })),
            },
          }
        })

        setNewLayoutVectors([])
        setNewInspectionFields([])
        setNewStandardFields([])
      } else {
        showFailureToast()
      }
    },
    onError: () => showFailureToast(),
  })

  const showSuccessToast = () => {
    toast.success('Successfully completed operation!', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  const showFailureToast = () => {
    toast.error('An unknown error occurred. Please retry in sometime.', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  const { data: fieldTypesData } = useQuery('dataTypesList', getDataTypes)

  const fieldTypeOptions = fieldTypesData?.data_types.map((item: any) => ({ value: item.id, label: item.name }))

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    const target = event.target as HTMLFormElement
    const formValues = QueryString.parse(QueryString.stringify(Object.fromEntries(new FormData(target).entries())), {
      allowDots: true,
    })
    updateMutation.mutate(formValues)
  }

  return (
    <div>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="colored"
      />
      {isLoading && <Loader />}
      <Flex as="form" onSubmit={handleSubmit} direction="column" css={{ py: 32, px: 54 }}>
        <Flex direction="column">
          <Title css={{ display: 'flex', alignItems: 'center', gap: 4, flex: 'initial' }}>
            {translations['uploadContainerDefaultIcon']}{' '}
            <Tooltip title={t('175x175JpegPngSupported')}>{info}</Tooltip>
          </Title>

          <ImageField
            clearable
            key={`default_image-${userDetails?.id}`}
            defaultValue={container?.default_image}
            css={{ width: 160, height: 160 }}
            onDelete
            name="default_image"
          />
        </Flex>
        <Flex align="baseline">
          <Title>{translations['uploadContainerLayoutVectors']}</Title>

          <Button
            type="button"
            btnStyle="textOnlyBtn"
            textColor="var(--colors-text)"
            width="auto"
            padding="0.5rem 0"
            onClick={() => {
              setNewLayoutVectors(newLayoutVectors.concat({ is_active: 1 }))
            }}
          >
            + {t('addNewView')}
          </Button>
        </Flex>
        <Flex css={{ gap: 16, overflow: 'auto' }}>
          {[...(container?.layout_vectors || []), ...newLayoutVectors].map((item: LayoutVector, index: number) => {
            return (
              <ImageField
                prepend={
                  <>
                    <input type="hidden" name={`layout_vectors[${index}].id`} value={item.id} />
                    <input type="hidden" name={`layout_vectors[${index}].sequence`} value={index + 1} />
                  </>
                }
                append={
                  <InputField
                    placeholder="name"
                    style={{ padding: 8 }}
                    defaultValue={item.name}
                    name={`layout_vectors[${index}].name`}
                  />
                }
                key={`index-${index}-${item.id}`}
                css={{ width: 240, height: 180, px: 24, py: 36, flex: 1 }}
                name={`layout_vectors[${index}].image`}
                defaultValue={item.image}
                isActiveDefaultValue={item.is_active || 1}
              />
            )
          })}
        </Flex>

        <Flex align="baseline">
          <Title>
            {translations['containerFieldsForBasicDetails']}
          </Title>
        </Flex>
        <DataTable>
          <TableHead>
            <Tr>
              <Th>{t('defaultText')}*</Th>
              {supported_locales.map((locale: keyof typeof localeMap) => (
                <Th key={locale}>{localeMap[locale]}</Th>
              ))}
              <Th>{t('fieldType')}*</Th>
              <Th></Th>
            </Tr>
          </TableHead>
          <TableBody>
            {(container?.basic_fields || []).map((item: any, index: number) => {
              return (
                <TableRow
                  supportedLocales={supported_locales}
                  isActiveDefaultValue={item.is_active || 1}
                  item={item}
                  fieldTypeOptions={fieldTypeOptions}
                  prefix="basic_fields"
                  index={index}
                  key={`index-${index}-${item.id}`}
                  hideDelete
                />
              )
            })}
          </TableBody>
        </DataTable>

        <Flex align="baseline">
          <Title>{translations['containerFieldsForStandardDetails']}</Title>
          <Button
            type="button"
            btnStyle="textOnlyBtn"
            textColor="var(--colors-text)"
            width="auto"
            padding="0.5rem 0"
            onClick={() =>
              setNewStandardFields(
                newStandardFields.concat({ field_name: '', data_type_id: fieldTypeOptions[0].value, is_active: 1 })
              )
            }
          >
            + {t('addNewField')}
          </Button>
        </Flex>
        <DataTable>
          <TableHead>
            <Tr>
              <Th>{t('defaultText')}*</Th>
              {supported_locales.map((locale: keyof typeof localeMap) => (
                <Th key={locale}>{localeMap[locale]}</Th>
              ))}
              <Th>{t('fieldType')}*</Th>
              <Th></Th>
            </Tr>
          </TableHead>
          <TableBody>
            {[...(container?.standard_fields || []), ...newStandardFields].map((item: any, index: number) => {
              return (
                <TableRow
                  supportedLocales={supported_locales}
                  isActiveDefaultValue={item.is_active || 1}
                  item={item}
                  fieldTypeOptions={fieldTypeOptions}
                  prefix="standard_fields"
                  index={index}
                  key={`index-${index}-${item.id}`}
                />
              )
            })}
          </TableBody>
        </DataTable>

        <Flex align="baseline">
          <Title>{translations['containerFieldsForInspectionDetails']}</Title>
          <Button
            type="button"
            btnStyle="textOnlyBtn"
            textColor="var(--colors-text)"
            width="auto"
            padding="0.5rem 0"
            onClick={() =>
              setNewInspectionFields(
                newInspectionFields.concat({ field_name: '', data_type_id: fieldTypeOptions[0].value, is_active: 1 })
              )
            }
          >
            + {t('addNewField')}
          </Button>
        </Flex>
        <DataTable>
          <TableHead>
            <Tr>
              <Th>{t('inspectionField')}*</Th>
              {supported_locales.map((locale: keyof typeof localeMap) => (
                <Th key={locale}>{localeMap[locale]}</Th>
              ))}
              <Th>{t('fieldType')}*</Th>
              <Th></Th>
            </Tr>
          </TableHead>
          <TableBody>
            {[...(container?.inspection_fields || []), ...newInspectionFields].map((item: any, index: number) => {
              return (
                <TableRow
                  supportedLocales={supported_locales}
                  isActiveDefaultValue={item.is_active || 1}
                  item={item}
                  fieldTypeOptions={fieldTypeOptions}
                  prefix="inspection_fields"
                  index={index}
                  key={`index-${index}-${item.id}`}
                />
              )
            })}
          </TableBody>
        </DataTable>
        <div className="company-details-bottom-btn-container z-10">
          <div className="display_flex company-details-bottom-btn-inner-container">
            <Button
              type="button"
              backgroundColor="#EEEEEE"
              textColor="var(--colors-text)"
              padding="0.75rem 2rem"
              fontSize="12px"
              height="fit-content"
              width="auto"
              onClick={() => navigate('/dashboard/org/item-types')}
              disabled={updateMutation.isLoading}
            >
              {t('discard')}
            </Button>

            <Button
              type="submit"
              padding="0.75rem 2rem"
              fontSize="12px"
              height="fit-content"
              width="auto"
              leftMargin="1.25rem"
              disabled={updateMutation.isLoading}
            >
              {t('saveChanges')}
            </Button>
          </div>
        </div>
      </Flex>
    </div>
  )
}

export { TableRow, localeMap }

export default withTitle(EditContainer, `${t('container')} | ${t('appName')}`)
