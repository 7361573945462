import React, { useState, useMemo, useCallback } from 'react'
import Button from '../../../../components/Button/button'
import '../itemType/itemType.scss'
import AddNewStage from './addNewStage/addNewStage'
import { useTranslation } from 'react-i18next'
import { DataTable, TableBody, TableFooter, TableHead, Td, Th, Toolbar, Tr, useDataTable } from '../../../../components/dataTable/DataTable'
import { useQuery } from 'react-query'
import { getStageList } from '../../../../api/apiCalls'
import Loader from '../../../../components/Loader/loader'
import { SearchBar, SearchContainer } from '../../../../components/searchbar/SearchBar'
import Flex from '../../../../components/flex/Flex'
import { useBreadCrumbs } from '../../../../contexts/breadcrumbs'

const Stage = () => {
  const [addNewItemFlag, setAddNewItemFlag] = useState<boolean>(false)
  const [editExistingData, setEditExistingData] = useState<boolean>(false)
  const [itemId, setItemId] = useState<any>(null)
  const [itemName, setItemName] = useState('')

  const { t } = useTranslation()
  useBreadCrumbs(t('stage'), null)

  const { search, offset, itemsPerPage, sortBy, order, setUrlParam } = useDataTable()

  const { isLoading, data, refetch } = useQuery(['stageList', search], () => getStageList('', search.toLowerCase()), {
    enabled: localStorage.getItem('loggedIn') === 'true' ? true : false,
  })

  const stages = useMemo(() => {
    if (sortBy) {
      return (
        data?.data
          .slice()
          .sort((a: any, b: any) => (order === 'asc' ? -1 : 1) * (a[sortBy] < b[sortBy] ? 1 : -1))
          .slice(offset, offset + itemsPerPage) || []
      )
    }

    return data?.data.slice(offset, offset + itemsPerPage) || []
  }, [data, offset, itemsPerPage, sortBy, order])

  const handlePageChange = useCallback(
    (selectedItem: { selected: number }) => {
      const newOffset = (selectedItem.selected * itemsPerPage) % data?.data.length
      setUrlParam('offset', newOffset)
    },
    [itemsPerPage, data?.data.length, setUrlParam]
  )

  const handleItemsPerPageChange = useCallback(e => setUrlParam('show', e.value), [setUrlParam])

  const onAddNewStage = () => {
    setAddNewItemFlag(true)
  }

  const onAddNewItemSaveClick = () => {
    setAddNewItemFlag(false)
    setEditExistingData(false)
    setItemId(null)
    setItemName('')
    refetch()
  }

  const onCancelClick = () => {
    setAddNewItemFlag(false)
    setEditExistingData(false)
    setItemId(null)
    setItemName('')
    refetch()
  }

  const onEditItemTypeClick = (id: any, name: string) => {
    setAddNewItemFlag(true)
    setEditExistingData(true)
    setItemId(id)
    setItemName(name)
  }

  return (
    <>
      {addNewItemFlag ? (
        <div className='sub-tab-content-container'>
          <AddNewStage
            onSaveClick={onAddNewItemSaveClick}
            onCancelClick={onCancelClick}
            editExistingData={editExistingData}
            itemId={itemId}
            itemName={itemName}
          />
        </div>
      ) : (
        <>
          {isLoading && <Loader />}
          <Toolbar>
            <SearchContainer>
              <SearchBar
                defaultValue={search}
                onChange={e => setUrlParam('search', e.target.value?.toLocaleLowerCase())}
                placeholder={'Search Stages'}
                type='search'
              />
            </SearchContainer>
            <Button padding='10px 20px' width='auto' onClick={onAddNewStage}>
              + {t('addNewStage')}
            </Button>
          </Toolbar>
          <Flex css={{ overflow: 'auto' }}>
            <DataTable>
              <TableHead>
                <Tr>
                  <Th>{t('stageID')}</Th>
                  <Th>{t('stageName')}</Th>
                  <Th>{t('actions')}</Th>
                </Tr>
              </TableHead>
              <TableBody>
                {stages.map((item: any) => {
                  return (
                    <Tr css={{ height: 60 }} key={item.id}>
                      <Td>{item.id}</Td>
                      <Td>{item.name}</Td>
                      <Td>
                        <span className='item-type-action-text no-padding' onClick={() => onEditItemTypeClick(item.id, item.name)}>
                          {t('editStageName')}
                        </span>
                      </Td>
                    </Tr>
                  )
                })}
              </TableBody>
            </DataTable>
          </Flex>
          <TableFooter
            offset={offset}
            itemsPerPage={itemsPerPage}
            data={data?.data}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        </>
      )}
    </>
  )
}

export default Stage
