import React, { useContext, useState } from 'react'
import Button from '../../../components/Button/button'
import variables from '../../../themes.module.scss'
import InputField from '../../../components/InputField/inputField'
import { getEmailErrorMsg, phoneNumberValidation, showAlertForError } from '../../../helpers/validations'
import DropdownSelect from '../../../components/dropdownSelect/dropdownSelect'
import arrowRight from '../../../assets/images/arrow_right_icon.svg'
import '../../Members/addNewMember/addNewMember.scss'
import { useEffect } from 'react'
import {
  createNewMember,
  createNewOrgAdmin,
  deleteMember,
  getMemberDetails,
  getUserRoles,
  suspendMember,
  updateMemberDetails,
} from '../../../api/apiCalls'
import CustomMessagePopup from '../../../components/CustomMessagePopup/customMessagePopup'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { SuperadminOrganisationsContext } from '../../../contexts/superadminOrganisationsContext'
import { useTranslation } from 'react-i18next'

const SuperadminAddNewMember = (props: any) => {
  const [memberId, setMemberId] = useState<any>(null)
  const [memberName, setMemberName] = useState<string>('')
  const [memberNameError, setMemberNameError] = useState<string>('')
  const [memberEmail, setMemberEmail] = useState<string>('')
  const [preferredLanguage, setPreferredLanguage] = useState<string>('')
  const [memberEmailError, setMemberEmailError] = useState<string>('')
  const [memberAddress, setMemberAddress] = useState<string>('')
  const [memberAddressError, setMemberAddressError] = useState<string>('')
  const [memberPhnNum, setContactPhnNum] = useState<any>('')
  const [memberPhnNumError, setContactPhnNumError] = useState<any>('')
  const [memberDisplayCode, setMemberDisplayCode] = useState<string>('')
  const [userOrg, setUserOrg] = useState<string>('')
  const [userOrgOptions, setUserOrgOptions] = useState([])
  const [existingMemberRoleId, setExistingMemberRoleId] = useState<any>(null)
  const [suspendedStatus, setSuspendedStatus] = useState<boolean>(false)
  const [showMemberCreatedPopup, setShowMemberCreatedPopup] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [itemsList, setItemsList, { isLoading, isSuccess, isError, data, error, refetch }] = useContext(SuperadminOrganisationsContext)
  const { t } = useTranslation()
  const onMemberEmailChangeHandler = (value: string) => {
    setMemberEmail(value)
    setMemberEmailError(getEmailErrorMsg(value))
  }

  const onMemberPhnNumChangeHandler = (value: any) => {
    if (phoneNumberValidation(value)) {
      setContactPhnNum(value)
    }
  }

  const languageOptions = [
    { value: 1, label: 'English' },
    { value: 2, label: 'German' },
  ]

  const getOrg = (val: any) => {
    let label = ''
    userOrgOptions.find((item: any, ind: any) => {
      if (item.value === val) {
        label = item.label
      }
    })
    return label
  }

  const getOrgId = (val: any) => {
    let id = null
    userOrgOptions.find((item: any, ind: any) => {
      if (item.label === val) {
        id = item.value
      }
    })
    return id
  }

  const getLanguageId = (val: any) => {
    let id = null
    languageOptions.find((item: any, ind: any) => {
      if (item.label === val) {
        id = item.value
      }
    })
    return id
  }

  const getLanguage = (val: any) => {
    let label = ''
    languageOptions.find((item: any, ind: any) => {
      if (item.value === val) {
        label = item.label
      }
    })
    return label
  }

  useEffect(() => {
    if (existingMemberRoleId && userOrgOptions.length > 0) {
      setUserOrg(getOrg(existingMemberRoleId))
    }
  }, [existingMemberRoleId, userOrgOptions])

  useEffect(() => {
    getDetailsOnLoad()
    refetch()

    return () => {
      props.addMemberStatus(false)
      props.setMemberId(null)
    }
  }, [])

  const getDetailsOnLoad = () => {
    const token = localStorage.getItem('adminAccessToken')
    if (props.memberId) {
      setMemberId(props.memberId)
      props.showLoader(true)
      getMemberDetails(props.memberId, token)
        .then(response => {
          props.showLoader(false)
          if (response.user) {
            setMemberName(response.user.full_name)
            setMemberEmail(response.user.email)
            setContactPhnNum(response.user.phone)
            setMemberAddress(response.user.address)
            setExistingMemberRoleId(response.user.user_role)
            setPreferredLanguage(response.user.language_id)
            setMemberDisplayCode(response.user.employee_id)
            setSuspendedStatus(response.user.status === 1 ? false : true)
          }
        })
        .catch(error => {
          props.showLoader(false)
          console.log(error)
          showAlertForError(error)
        })
    }

    if (itemsList) {
      const tempArr: any = []
      itemsList.forEach((item: any) => {
        const data = {
          value: item.id,
          label: item.name,
        }
        tempArr.push(data)
      })
      setUserOrgOptions(tempArr)
    }
  }

  const onCancelClick = () => {
    props.addMemberStatus(false)
    props.setMemberId(null)
  }

  const onSaveClick = () => {
    if (!memberName) {
      alert('Please enter member name')
    } else if (!memberEmail || memberEmailError) {
      if (memberEmail && memberEmailError) {
        alert('Please enter member email in correct format')
      } else {
        alert('Please enter member email')
      }
    } else if (!userOrg) {
      alert('Please select user organization!')
    } else if (!memberDisplayCode) {
      alert('Please enter member display code')
    } else {
      const token = localStorage.getItem('adminAccessToken')
      if (props.memberId) {
        const data = {
          full_name: memberName,
          email: memberEmail,
          phone: memberPhnNum,
          address: memberAddress,
          // user_role: getOrgId(userOrg),
          language_id: preferredLanguage,
          employee_id: memberDisplayCode,
        }
        props.showLoader(true)
        updateMemberDetails(memberId, data, token)
          .then(response => {
            // console.log(response)
            props.showLoader(false)
            if (response.status === 200) {
              props.addMemberStatus(false)
              props.setMemberId(null)
              // setShowMemberCreatedPopup(true)
              showSuccessToast()
              setMessage('Member details updated for ' + memberName)
            } else {
              // alert(response.data.errors.message)
              showFailureToast()
            }
          })
          .catch(error => {
            console.log(error)
            props.showLoader(false)
            showAlertForError(error)
          })
      } else {
        props.showLoader(true)
        const data = {
          full_name: memberName,
          email: memberEmail,
          organization_id: userOrg,
          phone: memberPhnNum,
          address: memberAddress,
          // user_role: getOrgId(userOrg),
          language_id: preferredLanguage,
          employee_id: memberDisplayCode,
        }
        createNewOrgAdmin(data, token)
          .then(response => {
            // console.log(response)
            props.showLoader(false)
            if (response.status === 201) {
              props.addMemberStatus(false)
              setMessage('New Member ' + memberName + ' created!')
              // setShowMemberCreatedPopup(true)
              showSuccessToast()
            } else {
              showFailureToast()
              // alert(response.data.errors.message)
            }
          })
          .catch(error => {
            console.log(error)
            props.showLoader(false)
            showAlertForError(error)
          })
      }
    }
  }

  const onSuspendClick = () => {
    props.showLoader(true)
    const token = localStorage.getItem('adminAccessToken')
    suspendMember(props.memberId, token)
      .then(response => {
        props.showLoader(false)
        // console.log(response)
        if (response.status !== 200) {
          alert(response.data.errors.message)
        } else {
          props.addMemberStatus(false)
          props.setMemberId(null)
        }
      })
      .catch(error => {
        props.showLoader(false)
        showAlertForError(error)
        console.log(error)
      })
  }

  const onDeleteClick = () => {
    props.showLoader(true)
    const token = localStorage.getItem('adminAccessToken')
    deleteMember(props.memberId, token)
      .then(response => {
        props.showLoader(false)
        // console.log(response)
        if (response.status !== 200) {
          alert(response.data.errors.message)
        } else {
          props.addMemberStatus(false)
          props.setMemberId(null)
        }
      })
      .catch(error => {
        props.showLoader(false)
        showAlertForError(error)
        console.log(error)
      })
  }

  const onCustomPopupDismiss = () => {
    setShowMemberCreatedPopup(false)
    props.addMemberStatus(false)
    props.setMemberId(null)
  }

  const showSuccessToast = () => {
    toast.success(props.memberId ? 'Member details saved!' : 'New member created!', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  const showFailureToast = () => {
    toast.error(props.memberId ? 'An error occurred while saving member details!' : 'An error occurred while creating new member!', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  return (
    <>
      <div className='organisation-settings-outer-container'>
        <ToastContainer
          position='bottom-center'
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          theme='colored'
        />

        {showMemberCreatedPopup && <CustomMessagePopup dismissHandler={onCustomPopupDismiss} message={message} />}
        <div className='sub-tab-content-container'>
          <div className='display_flex add-member-header-container'>
            <p className='selected-tab-page-title cursor_pointer' onClick={() => props.addMemberStatus(false)}>
              Manage Org Admins
            </p>
            <img src={arrowRight} className='arrow-right-icon' />
            <p className='sub-tab-selected-title'>{props.memberId ? 'Edit Org Admin Details' : 'Add Org Admin'}</p>
          </div>
          <p className='tabs-page-title-bottom-border' />

          <div className='display_flex flex-row-input-fields-container'>
            <div className='add-new-item-left-col-container'>
              <div className='row-left-field-container'>
                <InputField
                  label='Member Name'
                  value={memberName}
                  placeholder='Enter Member Name'
                  onChange={(val: string) => setMemberName(val)}
                  type='text'
                  error={memberNameError}
                  className='super-admin-input-field'
                />
              </div>

              <div className='row-left-field-container row-input-fields-container-margin-top'>
                <InputField
                  label='Member Phone Number'
                  value={memberPhnNum}
                  placeholder='Enter Member Phone Number'
                  onChange={onMemberPhnNumChangeHandler}
                  type='text'
                  error={memberPhnNumError}
                  className='super-admin-input-field'
                />
              </div>

              <div className='row-left-field-container row-input-fields-container-margin-top'>
                <DropdownSelect
                  label='Preferred Language'
                  placeholder={t('selectPreferredLang')!}
                  options={languageOptions}
                  value={preferredLanguage}
                  onChange={(val: any) => setPreferredLanguage(val)}
                  id='preferred-language-dropdown'
                  index={2}
                  className='super-admin-input-field'
                />
              </div>

              {!props.memberId && (
                <div className='row-left-field-container row-input-fields-container-margin-top'>
                  <DropdownSelect
                    label='Organization'
                    placeholder='Select User Organization'
                    options={userOrgOptions}
                    value={userOrg}
                    onChange={(val: any) => {
                      setUserOrg(val)
                    }}
                    id='user-role-dropdown'
                    index={1}
                    className='super-admin-input-field'
                    disabled={props.memberId}
                  />
                </div>
              )}
            </div>

            <div className='add-new-item-right-col-container org-settings-company-details-right-col'>
              <div className='row-right-field-container'>
                <InputField
                  label='Member Email'
                  value={memberEmail}
                  placeholder='Enter Member Email'
                  onChange={onMemberEmailChangeHandler}
                  type='email'
                  error={memberEmailError}
                  className='super-admin-input-field'
                />
              </div>

              <div className='row-right-field-container row-input-fields-container-margin-top'>
                <InputField
                  label='Member Address'
                  value={memberAddress}
                  error={memberAddressError}
                  placeholder='Enter Member Address'
                  onChange={(val: string) => setMemberAddress(val)}
                  textarea={true}
                  className='super-admin-input-field member-address-textarea'
                  // className="user-profile-input-field user-profile-textarea-style"
                />
              </div>

              <div className='row-right-field-container row-input-fields-container-margin-top'>
                <InputField
                  label='Member Display Code'
                  value={memberDisplayCode}
                  placeholder='Enter Member Display Code'
                  onChange={(val: string) => setMemberDisplayCode(val)}
                  type='text'
                  error={false}
                  className='super-admin-input-field'
                />
              </div>
            </div>
          </div>

          <div className='company-details-bottom-btn-container'>
            <div className='display_flex company-details-bottom-btn-inner-container'>
              <Button
                backgroundColor='#EEEEEE'
                textColor='#333333'
                padding='0.75rem 2rem'
                fontSize='12px'
                height='fit-content'
                width='auto'
                onClick={onCancelClick}
              >
                Cancel
              </Button>

              <Button
                padding='0.75rem 2rem'
                fontSize='12px'
                height='fit-content'
                width='auto'
                leftMargin='1.25rem'
                onClick={onSaveClick}
                backgroundColor='#212e4a'
              >
                Save Changes
              </Button>
            </div>
          </div>
        </div>

        {!props.hideDangerZoneFlag && (
          <>
            <div className='add-member-divider-div'></div>
            <div className='sub-tab-content-container add-member-danger-zone-container'>
              <p className='selected-tab-page-title manage-members-page-title'>Danger Zone</p>
              <p className='tabs-page-title-bottom-border' />

              <p className='suspend-member-acc-text'>Suspend Member Account - this freezes the customer’s account - CAN be Undone</p>
              <Button
                btnStyle={!suspendedStatus && 'transparentBtn'}
                textColor={suspendedStatus ? '#ffffff' : variables.emoryDanger}
                width='35%'
                onClick={onSuspendClick}
                backgroundColor='#212e4a'
                // disabled={suspendedStatus}
              >
                {suspendedStatus ? 'Activate Member Account' : 'Suspend Member Account'}
              </Button>

              <p className='suspend-member-acc-text'>Delete Member Account - this freezes the customer’s account - CANNOT be Undone</p>
              <Button textColor={variables.clearSilver} width='35%' backgroundColor={variables.emoryDanger} onClick={onDeleteClick}>
                Delete Member Account Permanently
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default SuperadminAddNewMember
