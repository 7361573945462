import React, { useState, useEffect } from 'react'
import withTitle from '../../hoc/withTitle'
import Button from '../../components/Button/button'
import { trackContainer } from '../../api/apiCalls'
import InputField from '../../components/InputField/inputField'
import './tracking.scss'
import { MapContainer, TileLayer, Marker, useMap, Polyline, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import markerIconPng from 'leaflet/dist/images/marker-icon.png'
import finishIcon from '../../assets/images/finish-flag.png'
import truckIcon from '../../assets/images/truck.png'
import noProfilePic from '../../assets/images/no_profile_pic.png'
import L, { Icon, LatLngTuple } from 'leaflet'
import emoryLogo from '../../assets/images/emory_logo.svg'
import axios from 'axios'
import 'leaflet-routing-machine'
import Flex from '../../components/flex/Flex'
import { styled } from '../../stitches.config'
const Box = styled('div')
const Tracking = (props: any) => {
  const [coordinate, setCoordinate] = useState<any>(null)
  const [trackId, setTrackId] = useState('')
  const [zoom, setZoom] = useState(5)
  const [errorMessage, setErrorMessage] = useState('')
  const [driverName, setDriverName] = useState<string | null>(null)
  const [driverPhone, setDriverPhone] = useState<string | null>(null)
  const [driverPhoto, setDriverPhoto] = useState<string | null>(null)
  const [fromLocation, setFromLocation] = useState<string | null>(null)
  const [deliveryLocation, setDeliveryLocation] = useState<string | null>(null)
  const [fromCoord, setFromCoord] = useState<LatLngTuple | null>(null)
  const [deliveryCoord, setDeliveryCoord] = useState<LatLngTuple | null>(null)

  const onTrackButtonClick = () => {
    if (!trackId.trim()) {
      setErrorMessage('Tracking ID is required.')
      return
    }

    const data = {
      trackingId: trackId,
    }
    trackContainer(data)
      .then(({ data }) => {
        if (data.status === 200) {
          const { processedGpsData, driverName, driverPhoto, phone, fromLocation, deliveryLocation } = data
          setCoordinate(processedGpsData.map((coord: any) => [coord.lat, coord.long]))
          setZoom(5)
          setErrorMessage('')
          setDriverName(driverName)
          setDriverPhone(phone)
          setDriverPhoto(driverPhoto)
          setFromLocation(fromLocation)
          setDeliveryLocation(deliveryLocation)
          fetchCoordinates(fromLocation).then((coord: LatLngTuple | null) => setFromCoord(coord))
          fetchCoordinates(deliveryLocation).then((coord: LatLngTuple | null) => setDeliveryCoord(coord))
        } else {
          setErrorMessage(data.message)
        }
      })
      .catch(error => {
        alert(error)
      })
  }

  // Component to adjust map bounds based on polyline
  const FitBoundsToMarkers = () => {
    const map = useMap()

    useEffect(() => {
      if (fromCoord && deliveryCoord) {
        map.fitBounds([fromCoord, deliveryCoord], { padding: [50, 50] }) // Optional padding
      }
    }, [map, coordinate])

    return null
  }

  // Function to fetch coordinates from address
  const fetchCoordinates = async (address: string): Promise<LatLngTuple | null> => {
    try {
      const response = await axios.get('https://nominatim.openstreetmap.org/search', {
        params: {
          q: address,
          format: 'json',
          limit: 1,
        },
      })
      if (response.data.length > 0) {
        const { lat, lon } = response.data[0]
        return [parseFloat(lat), parseFloat(lon)]
      } else {
        console.error('Address not found')
        return null
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error)
      return null
    }
  }

  interface RoutingMachineProps {
    start: LatLngTuple
    end: LatLngTuple
  }

  const RoutingMachine: React.FC<RoutingMachineProps> = ({ start, end }) => {
    const map = useMap()

    useEffect(() => {
      const routingControl = L.Routing.control({
        waypoints: [L.latLng(start[0], start[1]), L.latLng(end[0], end[1])],
        routeWhileDragging: false,
        lineOptions: {
          styles: [
            {
              color: '#000000',
              weight: 4,
              dashArray: '5, 10', // Makes the line dotted
            },
          ],
          extendToWaypoints: false,
          missingRouteTolerance: 20,
        },
        addWaypoints: false, // Disables waypoint markers
        waypointMode: 'snap', // Avoids creating markers at the waypoints
        show: false, // Hides route instructions,
        fitSelectedRoutes: false,
      }).addTo(map)
      const css = document.createElement('style')
      css.innerText = `
        .leaflet-routing-container {
          display: none !important;
        }
      `

      document.head.appendChild(css)
      return () => {
        map.removeControl(routingControl)
        document.head.removeChild(css)
      }
    }, [])

    return null // Component returns null because it renders no JSX
  }

  return (
    <div className="tracking-page">
      <div className="tracking-header">
        <div className="logo-container">
          <img src={emoryLogo} className="check-report-emory-logo" alt="" />
        </div>
      </div>

      <div className="main-content">
        <Flex direction={{ '@medium': 'row', '@initial': 'column' }} css={{ width: '100%' }}>
          <Box
            css={{
              width: '100%', // Full width on mobile
              '@medium': { width: '25%' }, // 25% width on medium and larger screens
            }}
          >
            <div className="sidebar">
              <h2>Track your Shipment</h2>
              <div className="display_flex_2 search-input">
                <div className="display_flex">
                  <div className="aa">
                    <InputField
                      value={trackId}
                      placeholder="Enter Shipment VIN"
                      onChange={(val: string) => setTrackId(val)}
                      type="text"
                    />
                  </div>
                  <Button
                    padding="0.75rem 2rem"
                    fontSize="12px"
                    height="fit-content"
                    width="auto"
                    leftMargin="1.25rem"
                    onClick={onTrackButtonClick}
                  >
                    Track
                  </Button>
                </div>
                {errorMessage && (
                  <div className="error-message">
                    <p>{errorMessage}</p>
                  </div>
                )}
              </div>

              {driverPhoto ? (
                <img src={driverPhoto} alt="Driver" className="driver-photo" />
              ) : (
                <div className="driver-photo no-photo" style={{ backgroundImage: `url(${noProfilePic})` }} />
              )}
              <div className="info-item">
                <p>
                  <strong>Driver Name:</strong> {driverName || ''}
                </p>
              </div>
              <div className="info-item">
                <p>
                  <strong>Driver's Phone:</strong> {driverPhone || ''}
                </p>
              </div>
              <div className="info-item">
                <p>
                  <strong>Tracking ID:</strong> {coordinate ? trackId || '' : ''}
                </p>
              </div>
              <div className="info-item">
                <p>
                  <strong>Pickup Location:</strong> {fromLocation || ''}
                </p>
              </div>
              <div className="info-item">
                <p>
                  <strong>Delivery Location:</strong> {deliveryLocation || ''}
                </p>
              </div>
            </div>
          </Box>

          <Box
            css={{
              width: '100%', // Full width on mobile
              '@medium': { width: '75%' },
            }}
          >
            <div className="map-container">
              <MapContainer center={[52.237049, 21.017532]} zoom={zoom} style={{ height: 650 }}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {coordinate && <Polyline positions={coordinate} color="red" />}
                {fromCoord && (
                  <>
                    <Marker
                      position={fromCoord}
                      zIndexOffset={10}
                      icon={
                        new Icon({
                          iconUrl: markerIconPng,
                          iconSize: [25, 41],
                          iconAnchor: [12, 41],
                        })
                      }
                    >
                      <Popup>
                        Pickup <br /> {fromLocation}
                      </Popup>
                    </Marker>
                    <Marker
                      position={coordinate[coordinate.length - 1]}
                      zIndexOffset={10}
                      icon={
                        new Icon({
                          iconUrl: truckIcon,
                          iconSize: [70, 70],
                          iconAnchor: [30, 60],
                        })
                      }
                    ></Marker>
                  </>
                )}
                {deliveryCoord && (
                  <>
                    <Marker
                      position={deliveryCoord}
                      zIndexOffset={10}
                      icon={
                        new Icon({
                          iconUrl: finishIcon,
                          iconSize: [50, 50],
                          iconAnchor: [12, 41],
                        })
                      }
                    >
                      <Popup>
                        Destination <br /> {deliveryLocation}
                      </Popup>
                    </Marker>
                    <RoutingMachine start={coordinate[coordinate.length - 1]} end={deliveryCoord as LatLngTuple} />
                  </>
                )}
                {/* Adjust bounds to fit the polyline */}
                <FitBoundsToMarkers />
              </MapContainer>
            </div>
          </Box>
        </Flex>
      </div>
    </div>
  )
}
export default withTitle(Tracking, 'Track Shipments | Emory Pro')
