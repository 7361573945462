import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Flex from '../flex/Flex'
import Text from '../typography/Text'

function ToggleInput(props: any) {
  const [checked, setChecked] = useState(props.defaultChecked || false)
  const { t } = useTranslation()

  return (
    <Flex align='center'>
      <Text css={{ fontSize: 14, my: 14 }}>{props.label}</Text>
      <label className='acc-flag-icon-container'>
        <input
          type='checkbox'
          style={{ display: 'none' }}
          value={checked ? 1 : 0}
          checked={checked}
          onChange={() => setChecked(!checked)}
          name={props.name}
        />
        <div className={checked ? 'acc-flag-icon-border-active' : 'acc-flag-icon-border-inactive'}>
          <div className={checked ? 'acc-flag-icon-dot-active' : 'acc-flag-icon-dot-inactive'}></div>
        </div>
        <Text css={{
          fontSize: 16,
          ml: 10,
          color: checked ? '$text' : '$slate10'
        }}>
          {checked ? t('active').toLocaleUpperCase() : t('inactive').toLocaleUpperCase()}
        </Text>
      </label>
    </Flex>
  )
}

export default ToggleInput
