import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import { RxChevronRight, RxChevronLeft } from 'react-icons/rx';
import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi2';

type Props = {
    value: Date;
    onChange: (date: Date) => void;
    minDate?: Date;
    maxDate?: Date;
};

const DatePicker = (props: Props) => {
  return (
      <Calendar onChange={props.onChange} value={props.value}
        className="border-none font-nunito"
        next2Label={<HiChevronDoubleRight className="w-4 h-4 stroke-1" />}
        nextLabel={<RxChevronRight className="w-4 h-4 stroke-1" />}
        prev2Label={<HiChevronDoubleLeft className="w-4 h-4 stroke-1" />}
        prevLabel={<RxChevronLeft className="w-4 h-4 stroke-1" />}
        tileClassName="text-sm font-medium leading-4 text-strong_graphite transition-colors duration-300 ease-in-out rounded-md"
        selectRange={true}
        minDate={props.minDate}
        maxDate={props.maxDate}
      />
  );
}

export default DatePicker;
