import React, { createContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import {getOrgDetails} from '../api/apiCalls'
import { setDefaultLocale } from 'react-datepicker'

export const OrgDetailsContext = createContext<any>({})

const localeMap = {
  English: 'en',
  German: 'de',
  Spanish: 'es',
}

const queryKey = 'orgDetails'

const orgDetailsInfo = async () => {
  const token = localStorage.getItem('accessToken')
  const res = await getOrgDetails(token)
  return res
}

export const OrgDetailsProvider: React.FC<any> = props => {
  const { isLoading, isSuccess, isError, data, error, refetch } = useQuery(queryKey, orgDetailsInfo, {
    enabled: localStorage.getItem('loggedIn') === 'true',
    refetchOnWindowFocus: false,
  })

  const { i18n } = useTranslation()

  useEffect(() => {
    if (!data) return

    localStorage.setItem('i18nextLng', data.preferred_language)
    i18n.changeLanguage(localeMap[data.preferred_language as keyof typeof localeMap]).then()
    setDefaultLocale(i18n.language)
  }, [data])

  return (
    <OrgDetailsContext.Provider value={{ isLoading, isSuccess, isError, data, error, refetch, queryKey }}>
      {props.children}
    </OrgDetailsContext.Provider>
  )
}
