import React, { forwardRef, Ref } from 'react'
import './button.scss'
import variables from '../../themes.module.scss'

const Button = forwardRef((props: any, ref: Ref<any>) => {
  const styles = {
    btnStyle: {
      // fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: props.fontSize ? props.fontSize : '14px',
      backgroundColor:
        props.btnStyle === 'textOnlyBtn' || props.btnStyle === 'transparentBtn'
          ? 'transparent'
          : props.disabled
          ? 'rgb(200,200,200)'
          : props.backgroundColor
          ? props.backgroundColor
          : variables.richMoss,
      borderRadius: '5px',
      justifyContent: 'center',
      alignItems: 'center',
      padding: props.padding ? props.padding : props.btnStyle === 'transparentBtn' ? '1.137rem 0px' : '1.2rem 0px',
      width: props.width ? props.width : '100%',
      height: props.height,
      marginTop: props.topMargin ? props.topMargin : 0,
      marginLeft: props.leftMargin ? props.leftMargin : 0,
      marginRight: props.rightMargin ? props.rightMargin : 0,
      marginBottom: props.bottomMargin ? props.bottomMargin : 0,
      outline: 'none',
      color: props.textColor ? props.textColor : 'white',
      border: props.border
        ? props.border
        : props.btnStyle === 'transparentBtn' && !props.disabled
        ? '1px solid ' + props.textColor
        : props.btnStyle === 'transparentBtn' && props.disabled
        ? '1px solid gray'
        : 'none',
      // border: "none",
      textDecoration: props.textDecoration,
      cursor: 'pointer',
    },
  }

  return (
    <button
      ref={ref}
      className={
        // props.btnStyle !== "textOnlyBtn" && props.btnStyle !== "transparentBtn" && !props.disabled ?
        // "ripple" + " " + props.className :
        // props.btnStyle === "transparentBtn" && !props.disabled ?
        // "ripple-transparent" + " "+ props.className : "ripple-no-background" + " " + props.className
        'ripple ' + props.className
      }
      style={styles.btnStyle}
      disabled={props.disabled}
      onClick={props.onClick}
      type={props.type}
    >
      {props.children}
    </button>
  )
})

export default Button
