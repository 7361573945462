import React from 'react'
import './pageNotFound.scss'
import { Link } from 'react-router-dom'
import emoryLogo from '../../assets/images/emory_logo.svg'
import pageNotFoundImg from '../../assets/images/404Img.svg'
import withTitle from '../../hoc/withTitle'

const PageNotFound = ({ loggedIn = false }) => {
  return (
    <div>
      {!loggedIn && (
        <div className='page-not-found-emory-logo-container'>
          <Link to='/'>
            <img src={emoryLogo} className='page-not-found-emory-logo' />
          </Link>
        </div>
      )}
      <div className={!loggedIn ? 'img-404-container display_flex' : 'img-404-container-logged-in display_flex'}>
        <img src={pageNotFoundImg} className={!loggedIn ? 'img-404' : 'img-404-logged-in'} />
        <div className='page-not-found-text-container'>
          <p className='page-not-found-title'>Page not found.</p>
          <p className='page-not-found-subtitle'>We were unable to find the page you are looking for.</p>
        </div>
      </div>
    </div>
  )
}

export default withTitle(PageNotFound, '404 Page Not Found | Emory')
