import React, { useState, createContext } from "react";

export const SuperadminSelectedOrgContext = createContext<any>({});
export const SuperadminSelectedOrgProvider: React.FC<any> = (props) => {
    
    const [orgDetails, setOrgDetails] = useState<any>(); 
    
    return (    
        <SuperadminSelectedOrgContext.Provider value={[orgDetails, setOrgDetails]}>
            {props.children}
        </SuperadminSelectedOrgContext.Provider>
    );
};