import React from "react";

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    value: string | number;
    label: string;
};

const CheckBox = (props: Props) => {
    return (
        <label className="flex items-center gap-2 text-sm font-semibold leading-4 cursor-pointer group text-strong_graphite">
            {/* @ts-ignore */}
            <input {...props} type="checkbox" value={props.value} className="transition duration-300 ease-in-out transform border-none rounded outline-none bg-calm_platinum/30 checked:ring-digital_mint form-checkbox text-digital_mint focus:ring-1 focus:ring-digital_mint group-hover:ring-offset-1 group-hover:ring-digital_mint group-hover:ring-1" />
            <span>{props.label}</span>
        </label>
    );
};

export default CheckBox;
