import React, { createContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { getScreenOrderForTranslations, getUserDetails } from '../api/apiCalls'
import { setDefaultLocale } from 'react-datepicker'

export const UserDetailsContext = createContext<any>({})
export const ScreenOrderForTranslationContext = createContext<any>({})

const localeMap = {
  English: 'en',
  German: 'de',
  Spanish: 'es',
}

const queryKey = 'userDetails'
const translationsKey = 'translations'

const userDetailsInfo = async () => {
  const token = localStorage.getItem('accessToken')
  const res = await getUserDetails(token)
  return res
}

const translationsInfo = async () => {
  const token = localStorage.getItem('accessToken')
  const res = await getScreenOrderForTranslations(token)
  return res
}

const transformTranslations = (response: any, locale: string) => {
  const transformedTranslations: { [key: string]: string } = {}

  if (response?.translations) {
    response.translations.forEach((entry: any) => {
      Object.entries(entry).forEach(([key, value]: [string, any]) => {
        if (!value[locale] || value[locale] === 'null' || value[locale] === '') {
          transformedTranslations[key] = value['en']
        } else {
          transformedTranslations[key] = value[locale]
        }
      })
    })
  }
  return transformedTranslations
}

export const UserDetailsProvider: React.FC<any> = (props) => {
  const { isLoading, isSuccess, isError, data, error, refetch } = useQuery(queryKey, userDetailsInfo, {
    enabled: localStorage.getItem('loggedIn') === 'true',
    refetchOnWindowFocus: false,
  })

  const { i18n } = useTranslation()

  useEffect(() => {
    if (!data) return

    localStorage.setItem('i18nextLng', data.preferred_language)
    i18n.changeLanguage(localeMap[data.preferred_language as keyof typeof localeMap])
    setDefaultLocale(i18n.language)
  }, [data])

  return (
    <UserDetailsContext.Provider value={{ isLoading, isSuccess, isError, data, error, refetch, queryKey }}>
      {props.children}
    </UserDetailsContext.Provider>
  )
}

export const ScreenOrderForTranslationProvider: React.FC<any> = (props) => {
  const [translations, setTranslations] = useState<{ [key: string]: string }>({})

  const { i18n } = useTranslation()
  const locale = i18n.language // Get current locale

  const { data: translationData, isSuccess: translationsLoaded } = useQuery(translationsKey, translationsInfo, {
    enabled: localStorage.getItem('loggedIn') === 'true',
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (!translationData) return

    // Transform translations based on locale
    const transformed = transformTranslations(translationData, locale)
    setTranslations(transformed)
  }, [translationData, locale])

  return (
    <ScreenOrderForTranslationContext.Provider value={{ translations, translationsLoaded }}>
      {props.children}
    </ScreenOrderForTranslationContext.Provider>
  )
}
