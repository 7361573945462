import axios from "axios";
import { tokenAndRedirectHelper } from "../utils/helper";

export const get = (url: any, config: any) => {
  return axios
    .get(url, config)
    .then((res) => res.data)
    .catch((err) => {
      tokenAndRedirectHelper(err.response.status);
      return err.response
    });
};

export const post = (url: any, data: any, config: any) => {
  return axios
    .post(url, data, config)
    .then((res) => res)
    .catch((err) => {
      if(!url.endsWith('user/authenticate')) {
        tokenAndRedirectHelper(err.response.status);
      }
      throw err
    });
};

export const patch = (url: any, data: any, config: any) => {
  return axios
    .patch(url, data, config)
    .then((res) => res)
    .catch((err) => {
      tokenAndRedirectHelper(err.response.status);
      return err.response
    });
};

export const del = (url: any) => {
  return axios
    .delete(url)
    .then((res) => res)
    .catch((err) => {
      tokenAndRedirectHelper(err.response.status);
      return err.response
    });
};

export const delWithConfig = (url: any, config: any) => {
  return axios
    .delete(url, config)
    .then((res) => res)
    .catch((err) => {
      tokenAndRedirectHelper(err.response.status);
      return err.response
    });
};

export const put = (url: any, data: any) => {
  return axios.put(url, data)
    .then((res) => res)
    .catch((err) => {
      tokenAndRedirectHelper(err.response.status);
      return err.response
    });
}

export const putWithAuth = (url: any, data: any, config: any) => {
  return axios.put(url, data, config)
    .then((res) => res)
    .catch((err) => {
      tokenAndRedirectHelper(err.response.status);
      return err.response
    });
}
