import * as Popover from '@radix-ui/react-popover'
import React, { Fragment, useCallback, useContext, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { createMarking, deleteMarking, getMarkings, updateMarking } from '../../../api/apiCalls'
import { DataTable, TableBody, TableFooter, TableHead, Td, Th, Toolbar, Tr, useDataTable } from '../../../components/dataTable/DataTable'
import Loader from '../../../components/Loader/loader'
import { SearchBar, SearchContainer } from '../../../components/searchbar/SearchBar'
import { Button } from '../../../components/Button'
import Flex from '../../../components/flex/Flex'
import { IconButton, Sheet, SheetContent, SheetTitle } from '../../../components/sheet/Sheet'
import InputField from '../../../components/InputField/inputField'
import ColorInput from '../../../components/ColorInput/ColorInput'
import Text from '../../../components/typography/Text'
import QueryString from 'qs'
import ToggleInput from '../../../components/ToggleInput/ToggleInput'
import { FaTrash } from 'react-icons/fa'
import { PopoverArrow, PopoverContent } from '../../../components/popover/Popover'
import { css } from '../../../stitches.config'
import { useTranslation } from 'react-i18next'
import { UserDetailsContext } from '../../../contexts/userDetailsContext'
import { useBreadCrumbs } from '../../../contexts/breadcrumbs'

const localeMap = {
  en: 'English',
  de: 'German',
  es: 'Spanish',
}

const getMarkingsList = (search = '') => {
  return getMarkings('', search)
}

const symbolClass = css({
  '&&': {
    width: 128,

    p: {
      mr: 0,
    },
  },
})

function Markings() {
  const { t } = useTranslation()
  useBreadCrumbs(t('markings'), null)
  const [open, setOpen] = useState(false)
  const [marking, setMarking] = useState<any>(null)
  const { search, offset, itemsPerPage, sortBy, order, setUrlParam } = useDataTable()
  const { data: userDetails } = useContext(UserDetailsContext)
  const supported_locales = JSON.parse(userDetails?.supported_locales || '[]')
  const queryKey = ['markingsList', search]

  const { isLoading, data, refetch } = useQuery(queryKey, () => getMarkingsList(search.toLowerCase()), {
    enabled: localStorage.getItem('loggedIn') === 'true' ? true : false,
  })

  const markings = useMemo(() => {
    if (sortBy) {
      return (
        data?.markings
          .slice()
          .sort((a: any, b: any) => (order === 'asc' ? -1 : 1) * (a[sortBy] < b[sortBy] ? 1 : -1))
          .slice(offset, offset + itemsPerPage) || []
      )
    }

    return data?.markings.slice(offset, offset + itemsPerPage) || []
  }, [data, offset, itemsPerPage, sortBy, order])

  const handlePageChange = useCallback(
    (selectedItem: { selected: number }) => {
      const newOffset = (selectedItem.selected * itemsPerPage) % data?.markings.length
      setUrlParam('offset', newOffset)
    },
    [itemsPerPage, data?.markings.length, setUrlParam]
  )

  const handleItemsPerPageChange = useCallback(e => setUrlParam('show', e.value), [setUrlParam])

  const updateMutation = useMutation((data: any) => updateMarking('', marking.id, data))
  const createMutation = useMutation((data: any) => createMarking('', data))
  const deleteMutation = useMutation((id: number) => deleteMarking('', id))
  const queryClient = useQueryClient()

  const handleSubmit = (event: any) => {
    event.preventDefault()
    const target = event.target as HTMLFormElement
    const formValues = QueryString.parse(QueryString.stringify(Object.fromEntries(new FormData(target).entries())), { allowDots: true })
    const onSuccess = () => {
      refetch()
      setOpen(false)
      setMarking(null)
    }

    if (marking?.id) {
      updateMutation.mutate(formValues, {
        onSuccess,
      })
    } else {
      createMutation.mutate(formValues, {
        onSuccess,
      })
    }
  }

  const text_i18n = JSON.parse(marking?.text_i18n || '{}')
  const symbol_i18n = JSON.parse(marking?.symbol_i18n || '{}')
  return (
    <>
      {isLoading && <Loader />}
      <Toolbar>
        <SearchContainer>
          <SearchBar
            defaultValue={search}
            onChange={e => setUrlParam('search', e.target.value?.toLocaleLowerCase())}
            placeholder={t('searchMarkings')!}
            type='search'
          />
        </SearchContainer>
        <Button onClick={() => setOpen(true)}>+ {t('addNewMarking')}</Button>
        <p>
          {t('totalMarkings')}: {data?.markings?.length}
        </p>
      </Toolbar>
      <Flex css={{ overflow: 'auto' }}>
        <DataTable>
          <TableHead>
            <Tr>
              <Th>{t('defaultText')}</Th>
              <Th>{t('symbol')}</Th>
              <Th>{t('color')}</Th>
              <Th>{t('active')}</Th>
              <Th css={{ width: 90 }}></Th>
            </Tr>
          </TableHead>
          <TableBody>
            {markings.map((marking: any) => {
              return (
                <Tr
                  onClick={() => {
                    setMarking(marking)
                    setOpen(true)
                  }}
                  css={{ height: 60, cursor: 'pointer' }}
                  key={marking.id}
                >
                  <Td>{marking.default_text}</Td>
                  <Td css={{ fontFamily: 'monospace' }}>{marking.default_symbol}</Td>
                  <Td>
                    <Flex align='center' css={{ gap: 8, fontFamily: 'monospace' }}>
                      <Flex
                        css={{
                          backgroundColor: `#${marking.color}`,
                          width: 24,
                          height: 24,
                          borderRadius: 8,
                          border: `2px solid white`,
                        }}
                      />
                      #{marking.color}
                    </Flex>
                  </Td>
                  <Td>
                    <span className={marking.status === 1 ? 'item-type-active-status-text' : 'item-type-suspentend-status-text'}>
                      {marking.status === 1 ? t('active').toLocaleUpperCase() : t('inactive').toLocaleUpperCase()}
                    </span>
                  </Td>
                  <Td onClick={e => e.stopPropagation()}>
                    <Popover.Root>
                      <Popover.Trigger asChild>
                        <IconButton
                          css={{
                            p: 8,
                            cursor: 'pointer',
                            borderRadius: '50%',
                            transition: '0.3s',
                            color: '$slate10',
                            '&:hover': {
                              backgroundColor: '$slate3',
                              color: '$emory_danger',
                            },
                          }}
                        >
                          <FaTrash />
                        </IconButton>
                      </Popover.Trigger>
                      <Popover.Portal>
                        <PopoverContent>
                          <Flex direction='column' css={{ p: 12, gap: 12 }}>
                            <Text>Delete {marking.default_text}?</Text>
                            <Flex css={{ gap: 12 }}>
                              <Popover.Close asChild>
                                <Button intent='secondary'>{t('no')}</Button>
                              </Popover.Close>
                              <Button
                                disabled={deleteMutation.isLoading}
                                onClick={() =>
                                  deleteMutation.mutate(marking.id, {
                                    onSuccess: () => {
                                      queryClient.setQueryData(queryKey, {
                                        ...data,
                                        markings: data.markings.filter((m: any) => m.id !== marking.id),
                                      })
                                      refetch()
                                    },
                                  })
                                }
                                intent='danger'
                              >
                                {t('yes')}
                              </Button>
                            </Flex>
                          </Flex>
                          <PopoverArrow />
                        </PopoverContent>
                      </Popover.Portal>
                    </Popover.Root>
                  </Td>
                </Tr>
              )
            })}
          </TableBody>
        </DataTable>
      </Flex>
      <TableFooter
        offset={offset}
        itemsPerPage={itemsPerPage}
        data={data?.markings}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
      <Sheet
        open={open}
        onOpenChange={open => {
          setOpen(open)

          if (!open) setMarking(null)
        }}
      >
        <SheetContent css={{ overflow: 'auto', width: 480 }}>
          <SheetTitle>
            <Text css={{ px: 20 }}>{marking ? `Edit ${marking.default_text}` : `Create Marking`}</Text>
          </SheetTitle>
          <Flex as='form' direction='column' onSubmit={handleSubmit} css={{ px: 20, pb: 80, gap: 10, overflow: 'auto' }}>
            <Text className='input-field-label'>
              {t('symbol')} & {t('defaultText')} <span className='input-field-required-asterisk'> *</span>
            </Text>
            <Flex css={{ gap: 10 }}>
              <InputField
                containerClassName={symbolClass()}
                maxLength={1}
                placeholder={t('symbol')}
                name='default_symbol'
                defaultValue={marking?.default_symbol}
                required
              ></InputField>
              <InputField required placeholder={t('defaultText')} name='default_text' defaultValue={marking?.default_text}></InputField>
            </Flex>
            <ColorInput required label={t('color')} name='color' defaultValue={marking?.color}></ColorInput>
            <ToggleInput label={t('markingStatus')} defaultChecked={!!marking?.status} name='status' />
            <Text css={{ fontWeight: 600, mt: 24 }}>{t('textTranslationsOptional')}</Text>
            {supported_locales.map((locale: keyof typeof localeMap) => (
              <Fragment key={locale}>
                <Text className='input-field-label'>{localeMap[locale]}</Text>
                <Flex css={{ gap: 10 }}>
                  <InputField
                    containerClassName={symbolClass()}
                    maxLength={1}
                    placeholder='Symbol'
                    name={`symbol_i18n.${locale}`}
                    defaultValue={symbol_i18n[locale]}
                  ></InputField>
                  <InputField placeholder='Text' name={`text_i18n.${locale}`} defaultValue={text_i18n[locale]}></InputField>
                </Flex>
              </Fragment>
            ))}
            <Flex
              direction='column'
              css={{
                position: 'fixed',
                bottom: 0,
                right: 0,
                width: 480,
                background: '$panel',
                padding: 20,
                boxSizing: 'border-box',
              }}
            >
              <Button disabled={createMutation.isLoading || updateMutation.isLoading} type='submit'>
                {t('submit')}
              </Button>
            </Flex>
          </Flex>
        </SheetContent>
      </Sheet>
    </>
  )
}

export default Markings
