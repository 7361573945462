import i18n, {t} from "i18next";
import {useBasicFieldName} from "../hooks/useBasicFieldName";

const tokenAndRedirectHelper = (code: number) => {
  if (code === 401) {
    alert('Error: User not logged in')
    localStorage.clear();
    window.location.href = '/login';
  }
}

const slugify = (text: string) => {
  if (text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }
  return "";
};

const langToLocale = {
  'en': 'en-US',
  'de': 'de-DE',
  'es': 'es-ES'
}

const formatDate = (val: any, includeTime: boolean = false) => {
  try {
    if (!val) {
      return ''
    }
    // input date can be a number, a number as a string, or date as string
    let tempDate: Date
    if ((typeof val === 'string') && val.search('[^0-9]') != -1) {
      // string containing chars other than just numbers
      tempDate = new Date(val)
    } else {
      tempDate = new Date(parseInt(val))
    }
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    const locale: string = langToLocale[i18n.language as keyof typeof langToLocale]
    const dateOptions: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      timeZone: tz,
    }
    if (includeTime) {
      dateOptions.hour = 'numeric';
      dateOptions.minute = 'numeric';
      dateOptions.second = 'numeric';
      dateOptions.hour12 = false;
    }
    const dateFormatter = new Intl.DateTimeFormat(locale, dateOptions)
    return dateFormatter.format(tempDate)
  } catch (e) {
    console.log(e);
    return ''
  }
}

 const downloadCSV = (arrayOfObjects: any, objectType: 'ITEM'|'CONTAINER', basicFieldName: any, filename = 'Emory_export.csv') => {
  let headers, keys
  const itemHeaders = [basicFieldName('Mark', 'item'), basicFieldName('Title', 'item'),
    basicFieldName('Item Type', 'item'), basicFieldName('Arrival Date', 'item'),
    t('inspectionStatus'), t('assignedTo'), basicFieldName('Stage', 'item'),
    basicFieldName('Location', 'item'), t('updatedAt')]

  const itemKeys: string[] = ['mark', 'title', 'item_type', 'arrival', 'item_status', 'assignee_name', 'stage_name',
    'location_status', 'updated_at']

  const containerHeaders = [basicFieldName('Container ID', 'container'),
    basicFieldName('Title', 'container'), t('inspectionStatus'),
    basicFieldName('Arrival Date', 'container'), basicFieldName('Departure Date', 'container'),
     t('assignedTo'), basicFieldName('Yard Location', 'container'), t('updatedAt')]

  const containerKeys: string[] = ['external_container_id', 'title', 'container_status', 'arrival_date', 'departure_date',
    'assignee_name', 'yard_location', 'updated_at']

  if(objectType === 'ITEM') {
    headers = itemHeaders
    keys = itemKeys
  } else {
    headers = containerHeaders
    keys = containerKeys
  }

  const csvRows = [];

  // Add headers
  csvRows.push(headers.join('\t')); // Join the headers into a comma-separated string

  // Add data rows
  for (const row of arrayOfObjects) {
      const values = keys.map(key => {
          // Handle commas or special characters inside values
          let value = [null, undefined].includes(row[key]) ? '' : row[key];
          value = value.toString().replace(/"/g, '""') // Escape quotes
          if(['arrival', 'updated_at', 'arrival_date', 'departure_date'].includes(key)) {
            value = formatDate(value)
          }
          return value
      });
      csvRows.push(values.join('\t')); // Join values into a comma-separated string
  }

  // Step 2: Convert the rows array to a CSV string
  const csvString = csvRows.join('\n');

  // Step 3: Create a Blob with the CSV content and create a download link
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

  // Create an anchor element and trigger the download
  const link = document.createElement('a');
  if (link.download !== undefined) { // Feature detection for browsers that support the 'download' attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  }
}

export { tokenAndRedirectHelper, slugify, formatDate, downloadCSV };
