import React,{useState,useEffect, useContext} from "react";
// import '../../Members/membersList/membersList.scss';
import ReactPaginate from "react-paginate";
import inputIcon from '../../../assets/images/input_search_icon.svg';
import Button from "../../../components/Button/button";
import InputField from "../../../components/InputField/inputField";
import editIcon from '../../../assets/images/edit_icon.svg';
import '../../items/itemList/itemList.scss';
import '../../containers/containersList/containersList.scss';
import './superadminOrganisations.scss';
// import { ItemsListContext } from "../../../contexts/itemsListContext";
// import AssignItemsPopup from "../../../components/AssignItemsPopup/assignItemsPopup";
// import { getAllItemsList } from "../../../api/apiCalls";
// import QrCode from "../../../components/QrCodePopup/qrCodePopup";
// import QrCodePopup from "../../../components/QrCodePopup/qrCodePopup";
// import DownloadMultipleQrCodes from "../../../components/DownloadMultipleQrCodes/downloadMultipleQrCodes";
import { SuperadminOrganisationsContext } from "../../../contexts/superadminOrganisationsContext";
import { SuperadminSelectedOrgContext } from "../../../contexts/superadminSelectedOrgContext";
// import ReactToPrint from "react-to-print";
// import QRCode from "react-qr-code";

const itemsPerPage:number = 5;

const SuperadminOrganisations = (props:any) => {

    const [itemsList, setItemsList, { isLoading, isSuccess, isError, data, error , refetch}] = useContext(SuperadminOrganisationsContext);
    const [orgDetails, setOrgDetails] = useContext(SuperadminSelectedOrgContext);    
    const [checkAll, setCheckAll] = useState(false);
    const [checkedList, setCheckedList] = useState<any>([]);
    const [showAssignText, setShowAssignText] = useState<boolean>(false);
    const [showAssignPopup, setShowAssignPopup] = useState<boolean>(false);
    const [itemsToAssignList, setItemsToAssignList] = useState<any>([]);
    const [currentItems, setCurrentItems] = useState<any>([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemToSearch, setItemToSearch] = useState<String>("");
    const [showQrCode, setShowQrCode] = useState<boolean>(false);
    const [qrData, setQrData] = useState<any>("");
    const [itemId, setItemId] = useState<any>(null);
    const [showDownloadMultipleQrCodesBtn, setShowDownloadMultipleQrCodesBtn] = useState<boolean>(false);
    const [downloadMultipleQrCodes, setDownloadMultipleQrCodes] = useState<boolean>(false);
    // const componentRef = React.useRef(null);
    // const [multipleQrData, setMultipleQrData] = useState<any>([]);

    useEffect(() => {    
        const endOffset = itemOffset + itemsPerPage;
        if(itemsList && itemsList.length > 0){
            setCurrentItems(itemsList.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(itemsList.length / itemsPerPage));
        }
    }, [itemsList, itemOffset, itemsPerPage]);
    
    const handlePageClick = (event:any) => {
        const newOffset = (event.selected * itemsPerPage) % itemsList.length;
        setItemOffset(newOffset);
    };

    const onAddNewItemClick = () => {        
        props.addOrgStatus(true)
        props.editOrgStatus(false)
    }

    const onEditClick = (item:any) => {
        // props.setOrgId(id)        
        props.editOrgStatus(true)
        props.addOrgStatus(false)
        setOrgDetails(item)
    }

    useEffect(() => {
        refetch()
    }, [])

    return(
        <>
        <div className="organisation-settings-outer-container">
            {/* <div className="display_flex selected-tab-header-container">
                <p className="selected-tab-page-title cursor_default">Organisation settings</p>
                <img src={arrowRight} className="arrow-right-icon"/>
                <p className="sub-tab-selected-title">{selectedTab}</p>
            </div>             */}
            <div className="sub-tab-content-container sub-tab-content-container-item-type sub-tab-content-container-superadmin">
                <p className="selected-tab-page-title manage-members-page-title members-list-page-title cursor_default">Organisations</p>
                <p className="tabs-page-title-bottom-border"/>
                <div className="item-type-button-wrapper items-top-btns-container">
                <div className="add-new-item-btn-container org-list-add-btn">
                    <Button 
                    padding="10px 20px" 
                    onClick={onAddNewItemClick}
                    backgroundColor="#212e4a"                    
                    >
                        + Add New Organisation
                    </Button>
                </div>

            </div>
            <div className="container-list-table-container">
                <div className="container-list-headers-container display_flex">                                    
                    <div className="container-list-first-item organisation-list-title">                        
                        <td className="container-list-individual-item container-list-header-text item-list-image-container organisation-list-title cursor_default">Company Logo</td>
                    </div>                        
                    <td className="container-list-individual-item container-list-header-text organisation-list-title cursor_default">Company Name</td>
                    <td className="container-list-individual-item container-list-header-text organisation-list-title cursor_default">Company Email</td>
                    <td className="container-list-individual-item container-list-header-text organisation-list-title cursor_default">Company Type</td>
                    <td className="container-list-individual-item container-list-header-text organisation-list-title cursor_default">Actions</td>
                </div>
                {currentItems && currentItems.length > 0 && currentItems.map(                        
                    (item:any,index:any)=>{
                        // console.log(item)
                        return(
                        <div className="container-list-content-container">
                            <div                                
                            className={index%2 === 0 ? 'even-item-bg-color cursor_default display_flex container-list-single-item-container' : 'cursor_default display_flex container-list-single-item-container'}
                            key={item.id}
                            >
                                <div className="container-list-first-item organisation-list-title">
                                    <img src={item.wide_brand_logo} className="org-image"/>
                                </div>
                                <div className="container-list-individual-item container-list-content-text organisation-list-title cursor_default" >{item.name}</div>
                                <div className="container-list-individual-item container-list-content-text organisation-list-title cursor_default" >{item.email}</div>   
                                <div className="container-list-individual-item container-list-content-text organisation-list-title cursor_default" >{item.type}</div>
                                <div className="container-list-individual-item container-list-content-text organisation-list-title" ><span className="item-type-action-text no_padding" onClick={() => onEditClick(item)}>Edit Organisation Info</span></div>
                            </div>
                        </div>
                        )
                    }
                )}                                
            </div>
            <ReactPaginate
                nextLabel={`Next`}
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel="Prev"
                containerClassName="item-type-all-btn-container"
                pageClassName="item-type-btn"
                pageLinkClassName="item-type-link-btn"
                previousClassName="item-type-btn"
                previousLinkClassName="item-type-prev-btn"
                nextClassName="item-type-btn"
                nextLinkClassName="item-type-prev-btn"
                breakLabel="..."
                breakClassName="item-type-btn"
                breakLinkClassName="item-type-link-btn"
                activeClassName="item-type-active-link-btn"
            />
        </div>
        </div>
    </>
    );
};

export default SuperadminOrganisations;