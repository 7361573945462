import ClipLoader from 'react-spinners/ClipLoader'
import React, { useState } from 'react'

import { css, styled } from '../../stitches.config'

import cameraIcon from '../../assets/images/camera_icon.svg'
import trashIcon from '../../assets/images/trash_icon.svg'
import { t } from 'i18next'
import { uploadFile } from '../../api/apiCalls'
import Flex from '../flex/Flex'
import Text from '../typography/Text'

const override = css({
  display: 'block',
  margin: '0 auto',
})

const Input = styled('input', {
  position: 'absolute',
  inset: 0,
  opacity: 0,
})

const Img = styled('img', {
  width: '100%',
  height: '100%',
  display: 'block',
  objectFit: 'contain',
})

function ImageField({ defaultValue, css, label, name, isActiveDefaultValue, clearable, prepend, append }: any) {
  const [isUploading, setUploading] = useState(false)
  const [value, setValue] = useState(defaultValue)
  const [isActive, setIsActive] = useState(isActiveDefaultValue)

  const onAddImgClick = async (event: any) => {
    if (event.target.files[0]) {
      setUploading(true)

      const res: any = await uploadFile(event.target.files[0])
      const imgUrl = res.file_path || ''
      setValue(imgUrl)
      setUploading(false)
    }
  }

  const isDeactivatable = isActiveDefaultValue !== undefined
  if (isDeactivatable && !isActive) {
    return null
  }

  return (
    <Flex css={{ flexShrink: 0, gap: 12 }} align='stretch' direction='column'>
      {prepend}
      <input type='hidden' value={value} name={name} />
      <div>
        {label && <Text css={{ fontSize: 14, my: 14 }}>{label}</Text>}
        <Flex
          align='center'
          justify='center'
          css={{
            boxSizing: 'border-box',
            position: 'relative',
            background: '#F6F6F6',
            border: '1px dashed #888888',
            borderRadius: 10,
            ...css,
          }}
        >
          {(isDeactivatable || clearable) && (
            <Flex
              as='button'
              type='button'
              aria-label='delete'
              justify='center'
              align='center'
              css={{ all: 'unset', cursor: 'pointer', position: 'absolute', right: 12, top: 12, zIndex: 1, p: 4 }}
            >
              <img
                src={trashIcon}
                onClick={() => {
                  if (isDeactivatable) {
                    setIsActive(false)
                  }

                  if (clearable) {
                    setValue('')
                  }
                }}
              />
            </Flex>
          )}
          <Input type='file' onChange={onAddImgClick} accept='image/*' />
          {isUploading ? (
            <ClipLoader color={'var(--color-digital_mint)'} loading={isUploading} css={override()} size={18} />
          ) : value ? (
            <Img src={value} />
          ) : (
            <Flex direction='column' justify='center' align='center'>
              <img src={cameraIcon} />
              <Text css={{ fontSize: 12, color: '#888', mt: 8 }}>{t('addImage')}</Text>
            </Flex>
          )}
        </Flex>
      </div>
      {append}
    </Flex>
  )
}

export default ImageField
