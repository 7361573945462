import React, { useEffect } from 'react'
import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Loader from '../../components/Loader/loader'
import withTitle from '../../hoc/withTitle'
import i18n from '../../i18n/i18n'
import AddNewMember from './addNewMember/addNewMember'
import './members.scss'
import MembersList from './membersList/MembersListV2'
import NoMembersFound from './noMembersFound/noMembersFound'

const Members = (props: any) => {
  const [loading, showLoader] = useState(false)
  const membersList: any = [1]

  const [addNewMember, setAddNewMember] = useState<boolean>(false)
  const [hideDangerZone, setHideDangerZone] = useState<boolean>(false)
  const [memberId, setMemberId] = useState<any>(null)

  useEffect(() => {
    if (props.resetMembersState) {
      setAddNewMember(false)
      setMemberId(null)
      setHideDangerZone(false)
      props.setResetmembersState(false)
    }
  })

  return (
    <div className='organisation-settings-outer-container'>
      <Outlet />
    </div>
  )
}

export default withTitle(Members, `${i18n.t('users')} | ${i18n.t('appName')}`)
