import React, { useState, useEffect } from 'react'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import FilterAccordion, { AssigneesProps, ItemTypesProps, StagesProps } from '../accordion'
import { Button } from '../Button'
import ScrollArea from '../scrollArea'
import {getAssigneeList, getItemLocationListApi, getItemStatusList, getItemTypes, getStageList} from '../../api/apiCalls'
import moment from 'moment'
import { HiOutlineFilter } from 'react-icons/hi'
import {t} from "i18next";

export type FilterData = {
  creationDate: Date
  inspectionStatus: string[]
  itemType: number[]
  assignedTo: number[]
  arrivalDate: Date
  stage: number[],
  location: string[],
}

const MenuItem = ({ children }: { children: React.ReactNode }) => (
  <DropdownMenuPrimitive.Item className='w-full rdx-highlighted:outline-none' onSelect={e => e.preventDefault()}>
    {children}
  </DropdownMenuPrimitive.Item>
)

const FilterDropdown = ({
  setFilterCount: _setFilterCount,
  filterCount: _filterCount,
  filterData: _filterData,
  setFilterData: _setFilterData,
  applyFilter,
  onClearAll,
}: {
  children?: React.ReactNode
  setFilterCount?: (count: number) => void
  filterCount?: number
  filterData?: FilterData
  setFilterData?: (data: FilterData) => void
  applyFilter: (data: FilterData) => void
  onClearAll: () => void
}) => {
  const [filterCount, setFilterCount] = useState(0)
  const [filterData, setFilterData] = useState<any>(_filterData)
  const getItemFilterDate = (field: string) => {
    const cacheData = localStorage.getItem('itemFilterCriteria')
    if (cacheData) {
      const date = JSON.parse(cacheData)[field]
      if (date.length > 0) {
        const d1 = new Date(date[0])
        const d2 = new Date(date[1])
        return `${d1.getDate()}-${d1.getMonth() + 1}-${d1.getFullYear()} ~ ${d2.getDate()}-${d2.getMonth() + 1}-${d2.getFullYear()}`
      }
    }
    return ''
  }
  const [isOpen, setIsOpen] = useState(false)
  const [isNewFilterConditions, setIsNewFilterConditions] = useState(false)
  const [isAnyFilterConditions, setIsAnyFilterConditions] = useState(false)
  const [tempFilterData, setTempFilterData] = useState({
    creationDate: getItemFilterDate('creationDate'),
    arrivalDate: getItemFilterDate('arrivalDate'),
  })
  const [itemStatusList, setItemStatusList] = useState<string[]>([])
  const [itemTypes, setItemTypes] = useState<ItemTypesProps[]>([])
  const [assignees, setAssignees] = useState<AssigneesProps[]>([])
  const [stages, setStages] = useState<StagesProps[]>([])
  const [locationList, setLocationList] = useState<string[]>([])

  useEffect(() => {
    let count = 0
    Object.keys(filterData).forEach(key => {
      const value = filterData[key];
      if (Array.isArray(value)) {
        if (value.length > 0) {
          count += 1;
        }
      } else if (typeof value === 'string') {
        if (value.length > 0) {
          count += 1;
        }
      }
    })
    setIsNewFilterConditions(count > 0)
    setFilterCount(count)
  }, [filterData])

  useEffect(() => {
    const getItemStatus = async () => {
      const token = localStorage.getItem('accessToken')
      try {
        const res = await getItemStatusList(token)
        if (res.data) {
          setItemStatusList(res.data)
        }
      } catch (err: any) {
        alert(err.message)
      }
    }

    const fetchItemTypes = async () => {
      const token = localStorage.getItem('accessToken')
      try {
        const res = await getItemTypes(token, '')
        if (res.item_types) {
          setItemTypes(res.item_types)
        }
      } catch (err: any) {
        alert(err.message)
      }
    }

    const getAssignees = async () => {
      const token = localStorage.getItem('accessToken')
      try {
        const res = await getAssigneeList(token)

        if (res) {
          setAssignees(res)
        }
      } catch (err: any) {
        alert(err.message)
      }
    }

    const getItemStages = async () => {
      const token = localStorage.getItem('accessToken')
      try {
        const res = await getStageList(token)
        if (res) {
          setStages(res.data)
        }
      } catch (err: any) {
        alert(err.message)
      }
    }

    const getItemLocations = async () => {
      const token = localStorage.getItem('accessToken')
      try {
        const res = await getItemLocationListApi(token)
        if (res) {
          setLocationList(res.data)
        }
      } catch (err: any) {
        alert(err.message)
      }
    }

    getItemStatus()
    fetchItemTypes()
    getAssignees()
    getItemStages()
    getItemLocations()
  }, [])

  const validateAndApplyTempFilterData = (tempDateArr: typeof tempFilterData) => {
    const { creationDate, arrivalDate } = tempDateArr
    const creationDateArr = creationDate.length > 0 ? creationDate.split(' ~ ') : []
    const arrivalDateArr = arrivalDate.length > 0 ? arrivalDate.split(' ~ ') : []
    if (creationDateArr.length === 2 && !moment(creationDateArr[0], 'DD-MM-YYYY').isValid()) {
      alert('Invalid starting Creation Date, please enter in DD-MM-YYYY format')
      return
    }
    if (creationDateArr.length === 2 && !moment(creationDateArr[1], 'DD-MM-YYYY').isValid()) {
      alert('Invalid end Creation Date, please enter in DD-MM-YYYY format')
      return
    }
    if (arrivalDateArr.length === 2 && !moment(arrivalDateArr[0], 'DD-MM-YYYY').isValid()) {
      alert('Invalid starting Arrival Date, please enter in DD-MM-YYYY format')
      return
    }
    if (arrivalDateArr.length === 2 && !moment(arrivalDateArr[1], 'DD-MM-YYYY').isValid()) {
      alert('Invalid end Arrival Date, please enter in DD-MM-YYYY format')
      return
    }

    if (creationDateArr.length === 2 && moment(creationDateArr[0], 'DD-MM-YYYY').isAfter(moment(creationDateArr[1], 'DD-MM-YYYY'))) {
      alert('Creation Date From should be less than Creation Date To')
      return
    }

    if (arrivalDateArr.length === 2 && moment(arrivalDateArr[0], 'DD-MM-YYYY').isAfter(moment(arrivalDateArr[1], 'DD-MM-YYYY'))) {
      alert('Arrival Date From should be less than Arrival Date To')
      return
    }

    const newCreationDateFrom: any =
      creationDateArr.length === 2
        ? moment(creationDateArr[0], 'DD-MM-YYYY').toDate() > new Date()
          ? new Date()
          : moment(creationDateArr[0], 'DD-MM-YYYY').toDate()
        : ''
    const newCreationDateTo: any =
      creationDateArr.length === 2
        ? moment(creationDateArr[1], 'DD-MM-YYYY').toDate() > new Date()
          ? new Date()
          : moment(creationDateArr[1], 'DD-MM-YYYY')
        : ''
    const newArrivalDateFrom: any =
      arrivalDateArr.length === 2
        ? moment(arrivalDateArr[0], 'DD-MM-YYYY').toDate() > new Date()
          ? new Date()
          : moment(arrivalDateArr[0], 'DD-MM-YYYY').toDate()
        : ''
    const newArrivalDateTo: any =
      arrivalDateArr.length === 2
        ? moment(arrivalDateArr[1], 'DD-MM-YYYY').toDate() > new Date()
          ? new Date()
          : moment(arrivalDateArr[1], 'DD-MM-YYYY').toDate()
        : ''

    const tempCreationDate =
      newCreationDateFrom !== '' && newCreationDateTo !== ''
        ? [
            newCreationDateFrom._isAMomentObject ? newCreationDateFrom._d : newCreationDateFrom,
            newCreationDateTo._isAMomentObject ? newCreationDateTo._d : newCreationDateTo,
          ]
        : ''
    if (newCreationDateFrom !== '' && newCreationDateTo !== '') {
      setFilterData({
        ...filterData,
        creationDate: tempCreationDate as unknown as Date,
      })
    }

    const tempArrivalDate =
      newArrivalDateFrom !== '' && newArrivalDateTo !== ''
        ? [
            newArrivalDateFrom._isAMomentObject ? newArrivalDateFrom._d : newArrivalDateFrom,
            newArrivalDateTo._isAMomentObject ? newArrivalDateTo._d : newArrivalDateTo,
          ]
        : ''
    if (newArrivalDateFrom !== '' && newArrivalDateTo !== '') {
      setFilterData({
        ...filterData,
        arrivalDate: tempArrivalDate as unknown as Date,
      })
    }

    setIsOpen(false)

    console.log('filterData new: ', filterData)
    applyFilter({
      ...filterData,
      creationDate: tempCreationDate ? tempCreationDate : filterData.creationDate || '',
      arrivalDate: tempArrivalDate ? tempArrivalDate : filterData.arrivalDate || '',
    })

    setTempFilterData({
      ...tempFilterData,
      creationDate: '',
      arrivalDate: '',
    })
  }

  const handleClearAll = () => {
    setFilterData({
      creationDate: '' as unknown as Date,
      inspectionStatus: [],
      itemType: [],
      assignedTo: [],
      arrivalDate: '' as unknown as Date,
      stage: [],
      location: [],
    })
    setIsNewFilterConditions(true)
    onClearAll()
  }

  return (
    <DropdownMenuPrimitive.Root open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DropdownMenuPrimitive.Trigger asChild className='p-0 border-none outline-none ring-0'>
        <Button>
          <HiOutlineFilter className='w-4 h-4' /> {t('filters')}
          {filterCount > 0 ? (
            <span className='flex items-center justify-center w-5 h-5 text-xs font-semibold bg-white rounded-full text-rich_moss'>
              {filterCount}
            </span>
          ) : null}
        </Button>
      </DropdownMenuPrimitive.Trigger>
      <DropdownMenuPrimitive.Portal>
        <DropdownMenuPrimitive.Content
          sideOffset={2}
          collisionPadding={10}
          className='flex flex-col items-start justify-start gap-3 px-4 py-6 transition duration-300 ease-in-out transform bg-white rounded-lg shadow-lg text-strong_graphite peer font-nunito'
        >
          <div className='flex items-start justify-between w-full gap-5'>
            <div className='flex flex-col gap-1 mb-4 w-fit'>
              <span className='text-lg font-bold leading-6'>{t('filters')}</span>
              <span className='h-[1px] bg-strong_graphite w-full' />
            </div>
            <div className='flex items-center gap-2'>
              <Button intent='secondary' size='small' disabled={filterCount === 0} onClick={handleClearAll}>
                {t('clearAll')}
              </Button>
              <Button
                intent='primary'
                size='small'
                disabled={!isNewFilterConditions}
                onClick={() => {
                  setIsOpen(false)
                  setIsNewFilterConditions(false)
                  validateAndApplyTempFilterData(tempFilterData)
                }}
              >
                {t('apply')}
              </Button>
            </div>
          </div>
          <ScrollArea rootClassName='h-80'>
            <MenuItem>
              <FilterAccordion
                filterData={filterData as unknown as FilterData}
                onFilterDataChange={(data: FilterData) => setFilterData(data as unknown as typeof filterData)}
                tempFilterData={tempFilterData}
                onTempFilterDataChange={(data: typeof tempFilterData) => setTempFilterData(data)}
                itemStatusList={itemStatusList}
                itemTypes={itemTypes}
                assignees={assignees}
                stages={stages}
                itemLocationList={locationList}
                setIsNewFilterConditions={setIsNewFilterConditions}
              />
            </MenuItem>
          </ScrollArea>
          <DropdownMenuPrimitive.Arrow className='fill-rich_moss' />
        </DropdownMenuPrimitive.Content>
      </DropdownMenuPrimitive.Portal>
    </DropdownMenuPrimitive.Root>
  )
}

export default FilterDropdown
