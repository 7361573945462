import { useState } from "react"
import jwt_decode from "jwt-decode"

interface User {
  email: string
  user_id: number
  user_name: string
  role: string
  organization_id: number
  org_uuid: string
  iat: number
  exp: number
}

const useUserFromLocalStorage = () => {
  const [user, setUser] = useState((): User | null => {
    const token = localStorage.getItem("accessToken")
    if (token) {
      return jwt_decode(token) as User;
    }
    return null
  })

  const hasUserManagementRights = (user_role: string) => {
    switch (user?.role) {
      case "OA":
        return true
      case "WA":
        if (user_role && user_role !== "OA") {
          return true
        } else {
          return false
        }
      default:
        if (user?.role === user_role) {
          return true
        }
        return false
    }
  }

  return {user, setUser, hasUserManagementRights}
}

export default useUserFromLocalStorage
