import React from 'react'
import { Navigate } from 'react-router-dom'
import PageNotFound from '../pages/pageNotFound/pageNotFound'
import { SuperadminMembersProvider } from '../contexts/superadminMembersContext'
import { SuperadminOrganisationsProvider } from '../contexts/superadminOrganisationsContext'
import { SuperadminSelectedOrgProvider } from '../contexts/superadminSelectedOrgContext'

export const SuperadminProtectedRoute = (props: any) => {
  if (localStorage.getItem('adminLoggedIn') !== 'true') {
    if (props.pageNotFound) {
      return <PageNotFound />
    }
    return <Navigate to='/superadmin' replace />
  }

  return (
    <SuperadminOrganisationsProvider>
      <SuperadminSelectedOrgProvider>
        <SuperadminMembersProvider>{props.children}</SuperadminMembersProvider>
      </SuperadminSelectedOrgProvider>
    </SuperadminOrganisationsProvider>
  )
}
