import React, { useState, useEffect, createContext } from 'react'
import { useQuery } from 'react-query'
import { getStageList } from '../api/apiCalls'

export const StageListContext = createContext<any>({})

const getStagesList = async () => {
  const token = localStorage.getItem('accessToken')
  const res = await getStageList(token)
  return res
}

export const StageListProvider: React.FC<any> = props => {
  const { isLoading, isSuccess, isError, data, error, refetch } = useQuery('stageList', getStagesList, {
    enabled: localStorage.getItem('loggedIn') === 'true' ? true : false,
  })

  return (
    <StageListContext.Provider value={[data, { isLoading, isSuccess, isError, data, error, refetch }]}>
      {props.children}
    </StageListContext.Provider>
  )
}
