import React, { useState } from 'react';
import Collapsible from 'react-collapsible';
import downArrow from '../../assets/images/arrow_down.svg';
import upArrow from '../../assets/images/arrow_up.svg';
import ReportItem from './dateilPageShareReportISubComponent';
import {t} from "i18next"; // Ensure the path is correct

const ShareCheckReport = ({ object, objectType }: { object: any; objectType: string}) => {
  const [checkReportAccordionOpen, setCheckReportAccordionOpen] = useState(false);

  const onExpandCheckReportClick = () => {
    setCheckReportAccordionOpen((prev) => !prev);
  };

  const objectId = objectType === 'item' ? object.mark : object.external_container_id
  return (
    <>
      <p className='add-new-item-left-col-title'>{t('inspectionReports').toUpperCase()}</p>
      {object?.check_reports.length <= 1 ? (
        object.check_reports.map((report: any) => (
          <ReportItem key={report.id} report={report} objectId={objectId} objectTitle={object.title} />
        ))
      ) : (
        <Collapsible
          trigger={
            <div className='check-report-accordion-container'>
              <div>
                {object.check_reports.slice(0, 1).map((report: any) => (
                  <ReportItem key={report.id} report={report} objectId={objectId} objectTitle={object.title} />
                ))}
              </div>
              {!checkReportAccordionOpen && (
                <div className='check-reports-expand-collapse-container cursor_pointer' onClick={onExpandCheckReportClick}>
                  <p className='expand-check-reports-text'>Show more</p>
                  <img className='check-reports-expand-collapse-icon' src={downArrow} alt="Expand" />
                </div>
              )}
            </div>
          }
          open={checkReportAccordionOpen}
          triggerDisabled={true}
        >
          <div>
            {object.check_reports.slice(1).map((report: any) => (
              <ReportItem key={report.id} report={report} objectId={objectId} objectTitle={object.title} />
            ))}
            {checkReportAccordionOpen && (
              <div className='check-reports-expand-collapse-container cursor_pointer' onClick={onExpandCheckReportClick}>
                <p className='expand-check-reports-text'>Show less</p>
                <img className='check-reports-expand-collapse-icon' src={upArrow} alt="Collapse" />
              </div>
            )}
          </div>
        </Collapsible>
      )}
    </>
  );
};

export default ShareCheckReport;
