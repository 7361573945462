import React from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'

const Tooltip = ({ children, title }: { children: React.ReactNode; title: string }) => {
  return (
    <TooltipPrimitive.Provider delayDuration={300}>
      <TooltipPrimitive.Root>
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <TooltipPrimitive.Content
            className='px-3 py-2 text-sm bg-gray-100 rounded-md shadow-lg text-strong_graphite font-nunito'
            sideOffset={5}
          >
            {title}
            <TooltipPrimitive.Arrow className='TooltipArrow' />
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}

export default Tooltip
