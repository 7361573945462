import React from "react";

export const emailValidation = (email: String) => {	
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

export const getEmailErrorMsg = (email: String) => {        
    let validity = emailValidation(email)
    if(!validity && email){
      return "Enter a valid email address";
    }
    return "";
}

export const phoneNumberValidation = (val: any) => {	
	const re = /^\+?[0-9]*$/;
	return re.test(String(val).toLowerCase());
};

export const setPasswordValidation = (val: any) => {
	if(val){
		return "";
	}
	return "Please enter a strong password";
}

export const numberInputValidation = (val: any) => {
	const re = /^[0-9]*$/;
	return re.test(String(val).toLowerCase());
};

export const decimalNumberInputValidation = (val: any) => {		
	const re = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
	return re.test(String(val).toLowerCase());	
};

export function showAlertForError(error:any) {
	const errorName = error?.response?.data?.errors?.name;
	const errorMessage = error?.response?.data?.errors?.message;
  
	if (!errorName || !errorMessage) {
	  alert("Something went wrong, please try again later!");
	} else {
	  alert(errorName + ": " + errorMessage);
	}
  }