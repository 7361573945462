import React from "react";
import {useQuery} from "react-query";
import {getSuperadminStats} from "../../../api/apiCalls";
import Flex from '../../../components/flex/Flex'
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register the components to ChartJS
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


const SuperadminStats = (props:any) => {
 const dtFormat = new Intl.DateTimeFormat('en-DE', {
    year: "numeric",
    month: "long",
    day: "numeric"
  }
 )
  const { isLoading, data } = useQuery(
    ['stats'],
    () => getSuperadminStats(),
    {
      enabled: localStorage.getItem('adminLoggedIn') === 'true',
    }
  )

  const barChartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Monthly Billable Reports',
      },
    },
  };

  const billingsData = {
    labels: data?.section_3.map((x: any) => [x.year,x.month].join(' ')).reverse(),
    datasets: [
      {
        label: 'Reports',
        // @ts-ignore
        data: data?.section_3?.map(x => x.reports).reverse() ?? []
      },
    ],
  }

  // @ts-ignore
  // @ts-ignore
  return(
    <><Flex direction='row'>
      <Flex direction='column'
            css={{
              p: '2rem',
              m: '2rem'
            }}>
        <div>
          Day-wise number of Check Reports Created <br/> <br/>
          <table>
            {data?.section_1.map((row: any) => (
              <tr key={row?.id}>
                <td>{dtFormat.format(new Date(row?.date))}</td>
                <td>{row?.org}</td>
                <td>{row?.reports}</td>
              </tr>
            ))}
          </table>
        </div>
      </Flex>
      <Flex direction='column'
            css={{
              p: '2rem',
              m: '2rem'
            }}>
        <div>
          Month-wise number of Check Reports Created <br/> <br/>
          <table>
            {data?.section_2.map((row: any) => (
              <tr key={row?.id}>
                <td>{row?.year}</td>
                <td>{row?.month}</td>
                <td>{row?.org}</td>
                <td>{row?.reports}</td>
              </tr>
            ))}
          </table>
        </div>
      </Flex>
      <Flex direction='column'
            css={{
              p: '2rem',
              m: '2rem'
            }}>
        <div>
          Billable total reports per month <br/> <br/>
          <table>
            {data?.section_3.map((row: any) => (
              <tr key={row?.id}>
                <td>{row?.year}</td>
                <td>{row?.month}</td>
                <td>{row?.reports}</td>
              </tr>
            ))}
          </table>
        </div>

      </Flex>
    </Flex>
      <div>
        <Bar data={billingsData} options={barChartOptions}/>
      </div>
    </>
  );
};

export default SuperadminStats;
