import React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";

const LinkOpener = ({
    href,
    className,
}: {
    href: string;
    className?: string;
}) => {
    return (
        <a href={href} target="_blank" rel="noreferrer" className={className}>
            <FaExternalLinkAlt className="w-5 h-5 text-white" />
        </a>
    );
};

export default LinkOpener;
