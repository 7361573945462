import React, { forwardRef, Ref } from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

const button = cva(
  'flex font-medium w-fit capitalize items-center cursor-pointer outline-none rounded-md transition-all ease-in-out duration-300',
  {
    variants: {
      intent: {
        primary:
          'bg-rich_moss border-none text-white hover:bg-rich_moss/75 focus:bg-rich_moss/75 focus:ring-2 focus:ring-rich_moss/75 focus:ring-offset-2 focus:ring-offset-white focus:outline-none disabled:bg-calm_platinum disabled:cursor-not-allowed',
        secondary:
          'bg-white border border-rich_moss hover:bg-digital_mint/10 focus:bg-digital_mint/10 text-rich_moss focus:border-rich_moss/75 focus:ring-2 focus:ring-rich_moss/75 focus:ring-offset-2 focus:ring-offset-white focus:outline-none disabled:border-calm_platinum/50 disabled:text-calm_platinum/50 disabled:cursor-not-allowed disabled:hover:bg-white',
        danger:
          'bg-white border border-emory_danger hover:bg-emory_danger/10 focus:bg-emory_danger/10 text-emory_danger focus:border-emory_danger/75 focus:ring-2 focus:ring-emory_danger/75 focus:ring-offset-2 focus:ring-offset-white focus:outline-none disabled:border-calm_platinum/50 disabled:text-calm_platinum/50 disabled:cursor-not-allowed disabled:hover:bg-white',
        success: 'bg-success hover:bg-success-light focus:bg-success-light',
        warning: 'bg-warn hover:bg-warn-light focus:bg-warn-light',
      },
      size: {
        small: ['text-xs', 'px-3', 'gap-[6px]'],
        medium: ['text-sm', 'px-4', 'gap-2'],
        large: ['text-base', 'px-5', 'gap-3'],
      },
    },
    compoundVariants: [
      { intent: 'primary', size: 'small', className: 'py-1' },
      { intent: 'primary', size: 'medium', className: 'py-2' },
      { intent: 'primary', size: 'large', className: 'py-3' },
      { intent: 'secondary', size: 'small', className: 'py-[3px]' },
      { intent: 'secondary', size: 'medium', className: 'py-[6px]' },
      { intent: 'secondary', size: 'large', className: 'py-[10px]' },
    ],
    defaultVariants: { intent: 'primary', size: 'medium' },
  }
)

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof button> {}

const Button: React.FC<ButtonProps> = forwardRef(({ className, intent, size, ...props }, ref: Ref<any>) => (
  // @ts-ignore
  <button ref={ref} className={button({ intent, size, className })} {...props} />
))

export { Button }
