import React, { useContext, useState } from 'react'
import * as Accordion from '@radix-ui/react-accordion'
import { RxChevronRight } from 'react-icons/rx'
import CheckBox from '../checkBox'
import DatePicker from '../datePicker'
import Tooltip from '../tooltip'
import { HiXMark } from 'react-icons/hi2'
import {t} from "i18next";
import { useTranslation } from 'react-i18next'
import {useBasicFieldName} from "../../hooks/useBasicFieldName";


export type ItemTypesProps = {
  id: number
  name: string
  status: number
}

export type AssigneesProps = {
  id: number
  full_name: string
  email: string
}

export type StagesProps = {
  id: number
  name: string
}

type IProps = {
  filterData: {
    creationDate: Date
    inspectionStatus: string[]
    itemType: number[]
    assignedTo: number[]
    arrivalDate: Date
    stage: number[],
    location: string[]
  }
  onFilterDataChange: (data: {
    creationDate: Date
    inspectionStatus: string[]
    itemType: number[]
    assignedTo: number[]
    arrivalDate: Date
    stage: number[]
    location: string[]
  }) => void
  tempFilterData: {
    creationDate: string
    arrivalDate: string
  }
  onTempFilterDataChange: (data: { creationDate: string; arrivalDate: string }) => void
  itemStatusList: string[]
  itemTypes: ItemTypesProps[]
  assignees: AssigneesProps[]
  stages: StagesProps[]
  itemLocationList: string[]
  setIsNewFilterConditions: (data: boolean) => void
}

export enum FilterFields {
  INSPECTION_STATUS = 'inspectionStatus',
  ITEM_TYPE = 'itemType',
  ASSIGNED_TO = 'assignedTo',
  STAGE = 'stage',
  ITEM_LOCATION = 'location'
}
const FilterAccordion = (props: IProps) => {
  const [inspectionSearchQuery, setInspectionSearchQuery] = useState('')
  const [inspectionStatusFilteredData, setInspectionStatusFilteredData] = useState(props.itemStatusList)
  const [itemSearchQuery, setItemSearchQuery] = useState('')
  const [itemTypeFilteredData, setItemTypeFilteredData] = useState(props.itemTypes)
  const [assignedToSearchQuery, setAssignedToSearchQuery] = useState('')
  const [assignedToFilteredData, setAssignedToFilteredData] = useState(props.assignees)
  const [stageSearchQuery, setStageSearchQuery] = useState('')
  const [stageFilteredData, setStageFilteredData] = useState(props.stages)
  const [itemLocationsSearchQuery, setItemLocationsSearchQuery] = useState('')
  const [itemLocationsFilteredData, setItemLocationsFilteredData] = useState(props.itemLocationList)
  const basicFieldName = useBasicFieldName();
  const getLocalFormattedDate = (date: Date[]) => {
    try {
      if (date.length > 1) {
        return `${date[0].getDate()}-${date[0].getMonth() + 1}-${date[0].getFullYear()} ~ ${date[1].getDate()}-${
          date[1].getMonth() + 1
        }-${date[1].getFullYear()}`
      }
    } catch (error) {
      return date as unknown as string
    }
  }

  const handleManualDateChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    props.onTempFilterDataChange({ ...props.tempFilterData, [fieldName]: e.target.value as unknown as string })
    props.setIsNewFilterConditions(true)
  }

const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: FilterFields) => {
  if (fieldName === FilterFields.INSPECTION_STATUS || fieldName === FilterFields.ITEM_LOCATION) {
    const currentFieldData: string[] = props.filterData[fieldName] || [];
    const updatedField = e.target.checked
      ? [...currentFieldData, e.target.value]
      : currentFieldData.filter(item => item !== e.target.value);
    props.onFilterDataChange({ ...props.filterData, [fieldName]: updatedField });
  } else if (fieldName === FilterFields.ITEM_TYPE || fieldName === FilterFields.ASSIGNED_TO || fieldName === FilterFields.STAGE) {
    const currentFieldData: number[] = props.filterData[fieldName] || [];
    const value = parseInt(e.target.value, 10);
    const updatedField = e.target.checked
      ? [...currentFieldData, value]
      : currentFieldData.filter(item => item !== value);
    props.onFilterDataChange({ ...props.filterData, [fieldName]: updatedField });
  } else if (fieldName === 'creationDate' || fieldName === 'arrivalDate') {
    const date = new Date(e.target.value);
    props.onFilterDataChange({ ...props.filterData, [fieldName]: date });
  }
  props.setIsNewFilterConditions(true);
};

  const handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    switch (fieldName) {
      case 'inspectionStatus':
        setInspectionSearchQuery(e.target.value)
        setInspectionStatusFilteredData(props.itemStatusList.filter(item => item.toLowerCase().includes(e.target.value.toLowerCase())))
        break
      case 'itemType':
        setItemSearchQuery(e.target.value)
        setItemTypeFilteredData(props.itemTypes.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase())))
        break
      case 'assignedTo':
        setAssignedToSearchQuery(e.target.value)
        setAssignedToFilteredData(props.assignees.filter(item => item.full_name.toLowerCase().includes(e.target.value.toLowerCase())))
        break
      case 'stage':
        setStageSearchQuery(e.target.value)
        setStageFilteredData(props.stages.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase())))
        break
      case 'location':
        setItemLocationsSearchQuery(e.target.value)
        setItemLocationsFilteredData(props.itemLocationList.filter(item => item.toLowerCase().includes(e.target.value.toLowerCase())))
        break
    }
  }

  const { t } = useTranslation()

  return (
    <Accordion.Root
      type='single'
      collapsible
      className='border-none outline-none focus:outline-none text-strong_graphite hover:ring-0 focus:ring-0 ring-0 min-w-[250px]'
    >

      <Accordion.Item value='item-type'>
        <AccordionTrigger>
        {basicFieldName('Item Type', 'item')} {props.filterData.itemType.length > 0 ? '(' + props.filterData.itemType.length + ')' : ''}
        </AccordionTrigger>
        <AccordionContent dataLength={itemTypeFilteredData.length}>
          <div className='relative flex w-full min-w-[250px]'>
            <input
              placeholder={t('select')+' '+basicFieldName('Item Type', 'item')}
              className='flex w-full px-4 py-2 m-1 transition duration-300 ease-in-out border rounded-md outline-none form-input focus:border-digital_mint focus:ring-digital_mint hover:ring-1 ring-digital_mint border-calm_platinum/50 focus:outline-none'
              value={itemSearchQuery}
              onChange={e => handleSearchQuery(e, 'itemType')}
            />
            <button
              className='absolute flex w-8 h-8 items-center justify-center bg-calm_platinum/20 cursor-pointer rounded-full top-[9px] outline-none border-none right-[10px] hover:bg-calm_platinum/50 transition-colors ease-in-out'
              onClick={() => {
                setItemSearchQuery('')
                setItemTypeFilteredData(props.itemTypes)
              }}
            >
              <HiXMark className='w-4 h-4' />
            </button>
          </div>
          {itemTypeFilteredData.length > 0 ? (
            itemTypeFilteredData.map((option: ItemTypesProps, index: number) => (
              <CheckBox
                key={index}
                label={option.name}
                value={option.id}
                checked={props.filterData.itemType.includes(option.id)}
                onChange={e => {
                  handleCheckBoxChange(e, FilterFields.ITEM_TYPE)
                }}
              />
            ))
          ) : (
            <p className='text-sm text-center text-calm_platinum'>{t('noDataAvailable')}</p>
          )}
        </AccordionContent>
      </Accordion.Item>

      <Divider />

      <Accordion.Item value='arrival'>
        <AccordionTrigger>{basicFieldName('Arrival Date', 'item')} {props.filterData.arrivalDate ? '*' : ''}</AccordionTrigger>
        <NonFunctionalAccordionContent>
          <Tooltip title={t('selectDateFromCalendar')}>
            <div className='relative flex w-full min-w-[250px]'>
              <input
                placeholder={t('select')+' '+basicFieldName('Arrival Date', 'item')}
                className='flex w-full px-4 py-2 m-1 transition duration-300 ease-in-out border rounded-md outline-none form-input focus:border-digital_mint focus:ring-digital_mint hover:ring-1 ring-digital_mint border-calm_platinum/50 focus:outline-none'
                value={props.tempFilterData.arrivalDate || getLocalFormattedDate(props.filterData.arrivalDate as unknown as Date[]) || ''}
                onChange={e => handleManualDateChange(e, 'arrivalDate')}
              />
              <button
                className='absolute flex w-8 h-8 items-center justify-center bg-calm_platinum/20 cursor-pointer rounded-full top-[9px] outline-none border-none right-[10px] hover:bg-calm_platinum/50 transition-colors ease-in-out'
                onClick={() => {
                  props.onFilterDataChange({ ...props.filterData, arrivalDate: '' as unknown as Date })
                  props.onTempFilterDataChange({ ...props.tempFilterData, arrivalDate: '' })
                }}
              >
                <HiXMark className='w-4 h-4' />
              </button>
            </div>
          </Tooltip>
          <DatePicker
            value={props.filterData.arrivalDate}
            onChange={date => {
              props.onFilterDataChange({ ...props.filterData, arrivalDate: date })
              props.onTempFilterDataChange({ ...props.tempFilterData, arrivalDate: '' })
            }}
          />
        </NonFunctionalAccordionContent>
      </Accordion.Item>

      <Divider />

      <Accordion.Item value='inspection-status'>
        <AccordionTrigger>
          {t('inspectionStatus')} {props.filterData.inspectionStatus.length > 0 ? '(' + props.filterData.inspectionStatus.length + ')' : ''}
        </AccordionTrigger>
        <AccordionContent dataLength={inspectionStatusFilteredData.length}>
          <div className='relative flex w-full min-w-[250px]'>
            <input
              placeholder={t('select')+' '+t('inspectionStatus')}
              className='flex w-full px-4 py-2 m-1 transition duration-300 ease-in-out border rounded-md outline-none form-input focus:border-digital_mint focus:ring-digital_mint hover:ring-1 ring-digital_mint border-calm_platinum/50 focus:outline-none'
              value={inspectionSearchQuery}
              onChange={e => handleSearchQuery(e, 'inspectionStatus')}
            />
            <button
              className='absolute flex w-8 h-8 items-center justify-center bg-calm_platinum/20 cursor-pointer rounded-full top-[9px] outline-none border-none right-[10px] hover:bg-calm_platinum/50 transition-colors ease-in-out'
              onClick={() => {
                setInspectionSearchQuery('')
                setInspectionStatusFilteredData(props.itemStatusList)
              }}
            >
              <HiXMark className='w-4 h-4' />
            </button>
          </div>
          {inspectionStatusFilteredData.length > 0 ? (
            inspectionStatusFilteredData.map((option: string, index: number) => (
              <CheckBox
                key={index}
                label={option}
                value={option}
                checked={props.filterData.inspectionStatus.includes(option)}
                onChange={e => handleCheckBoxChange(e, FilterFields.INSPECTION_STATUS)}
              />
            ))
          ) : (
            <p className='text-sm text-center text-calm_platinum'>{t('noDataAvailable')}</p>
          )}
        </AccordionContent>
      </Accordion.Item>

      <Divider />

      <Accordion.Item value='assigned-to'>
        <AccordionTrigger>
          {t('assignedTo')} {props.filterData.assignedTo.length > 0 ? '(' + props.filterData.assignedTo.length + ')' : ''}
        </AccordionTrigger>
        <AccordionContent dataLength={assignedToFilteredData.length}>
          <div className='relative flex w-full min-w-[250px]'>
            <input
              placeholder={t('select')+' '+t('assignedTo')}
              className='flex w-full px-4 py-2 m-1 transition duration-300 ease-in-out border rounded-md outline-none form-input focus:border-digital_mint focus:ring-digital_mint hover:ring-1 ring-digital_mint border-calm_platinum/50 focus:outline-none'
              value={assignedToSearchQuery}
              onChange={e => handleSearchQuery(e, 'assignedTo')}
            />
            <button
              className='absolute flex w-8 h-8 items-center justify-center bg-calm_platinum/20 cursor-pointer rounded-full top-[9px] outline-none border-none right-[10px] hover:bg-calm_platinum/50 transition-colors ease-in-out'
              onClick={() => {
                setAssignedToSearchQuery('')
                setAssignedToFilteredData(props.assignees)
              }}
            >
              <HiXMark className='w-4 h-4' />
            </button>
          </div>
          {assignedToFilteredData.length > 0 ? (
            assignedToFilteredData.map((option: AssigneesProps, index: number) => (
              <CheckBox
                key={index}
                label={option.full_name}
                value={option.id}
                checked={props.filterData.assignedTo.includes(option.id)}
                onChange={e => handleCheckBoxChange(e, FilterFields.ASSIGNED_TO)}
              />
            ))
          ) : (
            <p className='text-sm text-center text-calm_platinum'>{t('noDataAvailable')}</p>
          )}
        </AccordionContent>
      </Accordion.Item>

      <Divider />

      <Accordion.Item value='stage'>
        <AccordionTrigger>{basicFieldName('Stage', 'item')} {props.filterData.stage?.length > 0 ? '(' + props.filterData.stage?.length + ')' : ''}</AccordionTrigger>
        <AccordionContent dataLength={stageFilteredData.length}>
          <div className='relative flex w-full min-w-[250px]'>
            <input
              placeholder={t('select')+' '+basicFieldName('Stage', 'item')}
              className='flex w-full px-4 py-2 m-1 transition duration-300 ease-in-out border rounded-md outline-none form-input focus:border-digital_mint focus:ring-digital_mint hover:ring-1 ring-digital_mint border-calm_platinum/50 focus:outline-none'
              value={stageSearchQuery}
              onChange={e => handleSearchQuery(e, 'stage')}
            />
            <button
              className='absolute flex w-8 h-8 items-center justify-center bg-calm_platinum/20 cursor-pointer rounded-full top-[9px] outline-none border-none right-[10px] hover:bg-calm_platinum/50 transition-colors ease-in-out'
              onClick={() => {
                setStageSearchQuery('')
                setStageFilteredData(props.stages)
              }}
            >
              <HiXMark className='w-4 h-4' />
            </button>
          </div>
          {stageFilteredData.length > 0 ? (
            stageFilteredData.map((option: StagesProps, index: number) => (
              <CheckBox
                key={index}
                label={option.name}
                value={option.id}
                checked={props.filterData.stage?.includes(option.id)}
                onChange={e => handleCheckBoxChange(e, FilterFields.STAGE)}
              />
            ))
          ) : (
            <p className='text-sm text-center text-calm_platinum'>{t('noDataAvailable')}</p>
          )}
        </AccordionContent>
      </Accordion.Item>

      <Divider />

      <Accordion.Item value='item-locations'>
        <AccordionTrigger>
          {basicFieldName('Location', 'item')} {props.filterData?.location?.length > 0 ? '(' + props.filterData?.location?.length + ')' : ''}
        </AccordionTrigger>
        <AccordionContent dataLength={itemLocationsFilteredData.length}>
          <div className='relative flex w-full min-w-[250px]'>
            <input
              placeholder={t('select')+' '+basicFieldName('Location', 'item')}
              className='flex w-full px-4 py-2 m-1 transition duration-300 ease-in-out border rounded-md outline-none form-input focus:border-digital_mint focus:ring-digital_mint hover:ring-1 ring-digital_mint border-calm_platinum/50 focus:outline-none'
              value={itemLocationsSearchQuery}
              onChange={e => handleSearchQuery(e, 'location')}
            />
            <button
              className='absolute flex w-8 h-8 items-center justify-center bg-calm_platinum/20 cursor-pointer rounded-full top-[9px] outline-none border-none right-[10px] hover:bg-calm_platinum/50 transition-colors ease-in-out'
              onClick={() => {
                setItemLocationsSearchQuery('')
                setItemLocationsFilteredData(props.itemLocationList)
              }}
            >
              <HiXMark className='w-4 h-4' />
            </button>
          </div>
          {itemLocationsFilteredData.length > 0 ? (
            itemLocationsFilteredData.map((option: string, index: number) => (
              <CheckBox
                key={index}
                label={option}
                value={option}
                checked={props.filterData?.location?.includes(option)}
                onChange={e => handleCheckBoxChange(e, FilterFields.ITEM_LOCATION)}
              />
            ))
          ) : (
            <p className='text-sm text-center text-calm_platinum'>{t('noDataAvailable')}</p>
          )}
        </AccordionContent>
      </Accordion.Item>

      <Divider />

      <Accordion.Item value='creation-date'>
        <AccordionTrigger>{t('creationDate')} {props.filterData.creationDate ? '*' : ''}</AccordionTrigger>
        <NonFunctionalAccordionContent>
          <Tooltip title={t('selectDateFromCalendar')}>
            <div className='relative flex w-full min-w-[250px]'>
              <input
                placeholder={t('select')+' '+t('creationDate')}
                className='flex w-full px-4 py-2 m-1 transition duration-300 ease-in-out border rounded-md outline-none form-input focus:border-digital_mint focus:ring-digital_mint hover:ring-1 ring-digital_mint border-calm_platinum/50 focus:outline-none'
                value={props.tempFilterData.creationDate || getLocalFormattedDate(props.filterData.creationDate as unknown as Date[]) || ''}
                onChange={e => handleManualDateChange(e, 'creationDate')}
              />
              <button
                className='absolute flex w-8 h-8 items-center justify-center bg-calm_platinum/20 cursor-pointer rounded-full top-[9px] outline-none border-none right-[10px] hover:bg-calm_platinum/50 transition-colors ease-in-out'
                onClick={() => {
                  props.onFilterDataChange({
                    ...props.filterData,
                    creationDate: '' as unknown as Date,
                  })
                  props.onTempFilterDataChange({ ...props.tempFilterData, creationDate: '' })
                }}
              >
                <HiXMark className='w-4 h-4' />
              </button>
            </div>
          </Tooltip>
          <DatePicker
            value={props.filterData.creationDate}
            onChange={date => {
              props.onFilterDataChange({ ...props.filterData, creationDate: date })
              props.onTempFilterDataChange({ ...props.tempFilterData, creationDate: '' })
            }}
            // minDate={new Date("02-01-2020")}
            maxDate={new Date()}
          />
        </NonFunctionalAccordionContent>
      </Accordion.Item>

    </Accordion.Root>
  )
}

const AccordionTrigger = ({ children, ...props }: { children: React.ReactNode }) => {
  return (
    <Accordion.Header className='m-0'>
      <Accordion.Trigger
        className='inline-flex items-center justify-start w-full py-2 text-sm font-semibold transition duration-300 ease-in-out bg-transparent border-none rounded-md outline-none cursor-pointer ring-0 group hover:text-digital_mint rdx-state-open:text-digital_mint rdx-state-open:font-bold text-strong_graphite'
        {...props}
      >
        <RxChevronRight
          aria-hidden
          className='mr-2 transition duration-300 ease-in-out transform stroke-1 group-rdx-state-open:rotate-90 group-hover:text-digital_mint'
        />
        {children}
      </Accordion.Trigger>
    </Accordion.Header>
  )
}

const AccordionContent = ({
  children,
  className,
  dataLength,
  ...props
}: {
  children: React.ReactNode
  className?: string
  dataLength?: number
}) => {
  const [viewMore, setViewMore] = useState(false)
  return (
    <Accordion.Content className={`py-2 mx-4 ${className}`} {...props}>
      <div
        className={`flex flex-col w-full gap-4 justify-start py-1 overflow-hidden ${
          viewMore ? '' : dataLength && dataLength > 5 ? 'max-h-44' : 'max-h-60'
        }`}
      >
        {children}
      </div>
      {dataLength && dataLength > 5 ? (
        <button
          className='flex items-center justify-center px-3 py-2 text-sm font-semibold transition duration-300 ease-in-out bg-transparent border-none outline-none cursor-pointer w-fit ring-0 hover:text-digital_mint text-strong_graphite'
          onClick={() => setViewMore(!viewMore)}
        >
          {viewMore ? t('viewLessDots') : t('viewMoreDots')}
        </button>
      ) : null}
    </Accordion.Content>
  )
}

const NonFunctionalAccordionContent = ({ children, className, ...props }: { children: React.ReactNode; className?: string }) => {
  return (
    <Accordion.Content className={`py-2 mx-3 ${className}`} {...props}>
      {children}
    </Accordion.Content>
  )
}

const Divider = () => (
  <div className='mx-2'>
    <div className='flex my-2 w-full h-[1px] bg-strong_graphite' />
  </div>
)

export default FilterAccordion
