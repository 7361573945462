import React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

const chip = cva(
    'flex items-center justify-center h-4 px-2 my-auto mt-1 rounded-md text-[10px] text-center leading-[10px] font-medium text-white',
    {
        variants: {
            intent: {
                inspected: 'bg-digital_mint',
                submitted_for_review: 'bg-[#635dff]',
                draft: 'bg-emory_orange',
                assigned: 'bg-[#1071e5]',
                new: 'bg-emory_danger',
                default: 'bg-rich_moss',
            },
        },
        defaultVariants: { intent: 'default' },
    },
)

interface StatusChipProps extends VariantProps<typeof chip>, React.HTMLAttributes<HTMLDivElement> {
    status: string
}

const StatusChip: React.FC<StatusChipProps> = ({ status, intent, ...props }) => {
    return (
        <div className={chip({ intent })}>{status}</div>
    )
}

export default StatusChip
