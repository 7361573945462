import React, {useCallback, useEffect} from 'react'
import { useState } from 'react'
import Button from '../../components/Button/button'
import InputField from '../../components/InputField/inputField'
import './signupPage.scss'
import {NavLink, useNavigate} from 'react-router-dom'
import { getEmailErrorMsg, setPasswordValidation } from '../../helpers/validations'
import {signup} from '../../api/apiCalls'
import Loader from '../../components/Loader/loader'
import emoryLogo from '../../assets/images/emory_logo.svg'
import viewPassword from '../../assets/images/view_password.png'
import hidePassword from '../../assets/images/hide_password.png'
import withTitle from '../../hoc/withTitle'
import i18n from "../../i18n/i18n";


import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import CheckBox from "../../components/checkBox";
import {t} from "i18next";

let captchaToken: string;
let canSubmit: boolean = false;
let companyNameGlobal: string;
let emailGlobal: string;
let passwordGlobal: string;
let emailErrorGlobal: any;
let passwordErrorGlobal: any;
const canSubmitFn = (): boolean => {
  return !!(captchaToken && companyNameGlobal && emailGlobal && passwordGlobal)
}

const YourReCaptchaComponent = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [checked, setChecked] = React.useState(false);

  const handleCheckboxChange = () => {
    setChecked(!checked);
    handleReCaptchaVerify().then();
  };

  // Create an event handler, so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    captchaToken = await executeRecaptcha('yourAction');
    canSubmit = canSubmitFn()
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  return (
    <>
      <CheckBox
        key={1}
        label=""
        checked={checked}
        onChange={handleCheckboxChange} value={''}  />
      <p>{t('iHaveReadUsage')}&nbsp;
        <NavLink
          target='_blank'
          to='/terms-trial'>
          {t('termsAndConditions')}
        </NavLink>
      </p>
    </>
  )
}

const SignupPage = () => {
  const navigate = useNavigate()

  const [inProgress, setInProgress] = useState<boolean>(true)
  const [companyName, setCompanyName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordError, setPasswordError] = useState<string>('')
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [showPasswordStatus, setShowPasswordStatus] = useState<boolean>(false)

  const onCompanyNameChange = (value: any) => {
    setCompanyName(value)
    companyNameGlobal = value
    canSubmit = canSubmitFn()
  }

  const onEmailChange = (value: any) => {
    setEmail(value)
    setEmailError(getEmailErrorMsg(value))
    emailGlobal = value
    emailErrorGlobal = emailError
    canSubmit = canSubmitFn()
  }

  const onEnterNewPasswordHandler = (value: any) => {
    setPassword(value)
    setPasswordError(setPasswordValidation(value))
    passwordGlobal = value
    passwordErrorGlobal = passwordError
    canSubmit = canSubmitFn()
  }

  const onPasswordShowHideClick = () => {
    setShowPasswordStatus(!showPasswordStatus)
  }

  const onSignupClick = async () => {
    if(canSubmit) {
      const data = {
        captchaToken: captchaToken,
        companyName: companyName,
        email: email,
        password: password,
        deviceType: 'web'
      }

      setShowLoader(true)
      signup(data)
        .then((response: { status: number; data: { errors: { message: React.SetStateAction<string> } } }) => {
          setShowLoader(false)
          if (response.status === 200) {
            setInProgress(false)

          } else {
            if (response.data.errors.message === 'Invalid Email') {
              setEmailError('Invalid Email')
            } else if (response.data.errors.message === 'Invalid Password') {
              setPasswordError('Invalid Password')
            }
          }
        })
        .catch((error: any) => {
          setShowLoader(false)
          console.log(error)
          alert(error.response?.data?.message || t('genericError'))
        })
    }
    else {
      alert(t('pleaseCompleteForm'))
    }
  }

  return (
    <div className='login-page-container'>
      {showLoader && <Loader />}
      <div className='login-card-container'>
        <div className='login-page-emory-logo-container'>
          <img src={emoryLogo} className='login-page-emory-logo' />
        </div>

        {inProgress ? (
          <div className='login-details-outer-container'>
            <div className='login-page-input-field-container'>
              <InputField
                label={t('companyName')}
                value={companyName}
                onChange={onCompanyNameChange}
                placeholder={t('companyName')}
                type='text'
                className='login-inputs-style'
              />
            </div>
            <div className='login-page-input-field-container'>
              <InputField
                label={t('Email')}
                value={email}
                placeholder={t('email')}
                onChange={onEmailChange}
                type='email'
                error={emailError}
                className='login-inputs-style'
              />
            </div>
            <div className='login-page-input-field-container login-page-password-field-container'>
              <InputField
                label={t('password')}
                value={password}
                placeholder={t('newPassword')}
                onChange={onEnterNewPasswordHandler}
                type={showPasswordStatus ? 'text' : 'password'}
                error={passwordError}
                className='login-inputs-style'
              />
              <img
                src={showPasswordStatus ? viewPassword : hidePassword}
                className='view-hide-password-icon cursor_pointer'
                onClick={onPasswordShowHideClick}
              />
            </div>
            <div className={'login-btn-container centered-text'}>
              <GoogleReCaptchaProvider reCaptchaKey="6LfGGiopAAAAABjOyAkBbnmQduu17iVPY9g8_Qch">
                <YourReCaptchaComponent />
              </GoogleReCaptchaProvider>
            </div>
            <div className={'login-btn-container'}>
              <Button
                onClick={onSignupClick}
              >
                {i18n.t('signup')}
              </Button>
            </div>
          </div>
        ) : (
          <p className='centered-text margin-top-40-percent'>{t('checkAccountVerificationLink')}</p>
        )}
      </div>
    </div>
  )
}

export default withTitle(SignupPage, `Emory - ${i18n.t("signup")}`)
