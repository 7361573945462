import React from "react";
import { css } from "@emotion/react";
import PulseLoader from "react-spinners/PulseLoader";
import variables from "../../themes.module.scss";

const LoaderWithProgress = ({ progress }: { progress?: string | number }) => {
    const override = css`
        display: block;
        margin: 0 auto;
    `;

    return (
        <div
            style={{
                height: "100%",
                background: "#a5a4a44f",
                // zIndex: 9999,
                zIndex: 99999999999999999999,
                backdropFilter: "blur(5px)",
                WebkitBackdropFilter: "blur(5px)",
                position: "fixed",
                width: "100%",
                // top: "0"
            }}
        >
            <div className="flex flex-col items-center justify-center w-full h-full gap-4">
                <span className="text-base text-rich_moss">
                    {progress || ""}
                </span>
                <PulseLoader
                    color={variables.digitalMint}
                    loading={true}
                    css={override}
                    size={18}
                    margin={5}
                />
            </div>
        </div>
    );
};

export default LoaderWithProgress;
