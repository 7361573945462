import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://2e42971ce1abe27c6b2f73cae78a1c0c@o4506984959180800.ingest.us.sentry.io/4506984976089088",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ]
});


ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
