import React, { useState, useEffect, createContext } from "react";
import { useQuery } from 'react-query';
import { getAdminOrgs, getAllItemsList } from "../api/apiCalls";

export const SuperadminOrganisationsContext = createContext<any>({});

export const SuperadminOrganisationsProvider: React.FC<any> = (props) => {
    
    const [adminOrgsList, setAdminOrgsList] = useState();
    
    let getAdminOrgsList = async () => {    
      let token = localStorage.getItem('adminAccessToken')
      const res = await getAdminOrgs(token)
      return res
    };
        
    const { isLoading, isSuccess, isError, data, error, refetch } = useQuery('superadminOrgsList',  getAdminOrgsList, 
        {
            enabled: localStorage.getItem("adminLoggedIn") === "true" ? true : false
        }
    )

    useEffect(() => {      
      if(data && localStorage.getItem("adminLoggedIn") === "true"){ 
        // console.log(data)                       
        setAdminOrgsList(data.organizations)
      }          
  }, [data])

    useEffect(() => {
      refetch()
    }, [])
    
  return (    
    <SuperadminOrganisationsContext.Provider value={[adminOrgsList, setAdminOrgsList, { isLoading, isSuccess, isError, data, error , refetch}]}>
      {props.children}
    </SuperadminOrganisationsContext.Provider>
  );
  }