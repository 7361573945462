import React from "react";
import '../AssignItemsPopup/assignItemsPopup.scss';
import './customMessagePopup.scss';
import Button from "../Button/button";

const CustomMessagePopup = (props:any) => {

    return(
        <div>            
            <div
            className="assignee-list-component-outer-container"
            id="assignee-list-component-outer-container"                
            >
                <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 999999999
                
                }}
                >                        
                    <div className="assignee-list-card-outer-container custom-message-popup-outer-container">
                        <div className="display_flex assign-title-close-icon-container">
                            <h1 className="assign-items-title">
                                {props.title && props.title}
                            </h1>
                            {/* <p className="cross-icon cursor_pointer" onClick={() => props.dismissHandler()}>
                                X
                            </p> */}
                        </div>      
                        <h3 className="custom-popup-message-text">
                            {props.message}
                        </h3>   

                        <div className="custom-message-popup-dismiss-btn-container">
                            <Button
                            padding="0.75rem 2rem"
                            fontSize="12px"
                            height="fit-content"
                            width="fit-content"
                            // leftMargin="1.25rem"
                            onClick={() => props.dismissHandler()}                    
                            >
                                Dismiss
                            </Button>  
                        </div>                                         
                    </div>
                </div> 
            </div>                    
            )            
        </div>
    );
};

export default CustomMessagePopup;