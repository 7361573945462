import React from "react";
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";

type IProps = {
    children?: React.ReactNode;
    rootClassName?: string;
};

const ScrollArea = (props: IProps) => (
    <ScrollAreaPrimitive.Root className={`w-full relative rounded-md overflow-hidden bg-white ${props.rootClassName || "h-64"}`}>
        <ScrollAreaPrimitive.Viewport className="w-full h-full">{props.children}</ScrollAreaPrimitive.Viewport>
        <ScrollAreaPrimitive.Scrollbar className="z-20 w-2 h-full -mr-1 bg-transparent rounded-md" orientation="vertical">
            <ScrollAreaPrimitive.Thumb className="rounded-lg bg-calm_platinum/50" />
        </ScrollAreaPrimitive.Scrollbar>
        {/* <ScrollAreaPrimitive.Scrollbar
            className="ScrollAreaScrollbar"
            orientation="horizontal"
        >
            <ScrollAreaPrimitive.Thumb className="ScrollAreaThumb" />
        </ScrollAreaPrimitive.Scrollbar> */}
        {/* <ScrollAreaPrimitive.Corner className="ScrollAreaCorner" /> */}
    </ScrollAreaPrimitive.Root>
);

export default ScrollArea;
