import React from 'react'
import { useEffect } from 'react'
import './inputField.scss'

const InputField = ({ containerClassName, ...props }: any) => {
  useEffect(() => {
    const textareas: any = document.querySelectorAll('#autoresizing')
    textareas.forEach((textarea: any) => {
      textarea.style.height = 'auto'
      textarea.style.height = textarea.scrollHeight + 'px'
    })
  }, [props.value])

  return (
    <label className={`input-field-label-container ${containerClassName || ''}`}>
      {props.label && (
        <p className='input-field-label'>
          {props.label}
          {props.required && <span className='input-field-required-asterisk'> *</span>}
        </p>
      )}
      {props.textarea ? (
        <textarea
          id={props.autoSize && 'autoresizing'}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onKeyDown={props.onKeyDown}
          onChange={e => {
            props.onChange(e.target.value)
          }}
          placeholder={props.placeholder}
          readOnly={props.disabled}
          value={props.value}
          // className={!props.error ? "input-field " + props.className: "input-field input-error-style " + props.className}
          className={
            !props.error
              ? props.autoSize && props.disabled
                ? 'input-field autoresizing ' + props.className
                : 'input-field ' + props.className
              : props.autoSize && props.disabled
              ? 'input-field input-error-style autoresizing ' + props.className
              : 'input-field input-error-style ' + props.className
          }
          style={{ whiteSpace: 'pre-line', ...props.style}}
        />
      ) : (
        <input
          {...props}
          // className={!props.error ? props.className ? "input-field " + props.className : "input-field" : "input-field input-error-style"}
          className={!props.error ? 'input-field ' + props.className : 'input-field input-error-style ' + props.className}
          placeholder={props.placeholder}
          disabled={props.disabled}
          value={props.value}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onKeyDown={props.onKeyDown}
          onChange={e => {
            props.onChange?.(e.target.value)
          }}
          type={props.type}
        />
      )}

      {props.error ? props.error.length > 0 && <p className='error-msg-style'>{props.error}</p> : null}
    </label>
  )
}

export default InputField
