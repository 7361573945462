import * as Dialog from '@radix-ui/react-dialog'
import React from 'react'
import Button from '../Button/button'
import './qrCodePopup.scss'
import ReactToPrint from 'react-to-print'
import { QRCode } from 'react-qrcode-logo'
import emoryLogo from '../../assets/images/emory_icon.svg'
import {t} from "i18next";
import { useBasicFieldName } from "../../hooks/useBasicFieldName";

const QrCodePopup = (props: any) => {
  const ref = React.useRef(null)
  const qrCodeRef = React.useRef(null)
  const basicFieldName = useBasicFieldName();
  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <Button
        padding="0.75rem 2rem"
        fontSize="12px"
        height="fit-content"
        width="fit-content"
      >
        {t('printQRCode')}
      </Button>
    )
  }, [])

  const reactToPrintContent = React.useCallback(() => {
    return ref.current
  }, [ref.current])

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>{props.children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay
          style={{
            height: '100%',
            background: 'rgba(174,174,174,0.1)',
            zIndex: 99999,
            backdropFilter: 'blur(5px)',
            WebkitBackdropFilter: 'blur(5px)',
            position: 'fixed',
            width: '100%',
            top: '0',
            left: '0',
          }}
        />
        <Dialog.Content
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 999999999,
          }}
        >
          <div>
            <div className="assignee-list-card-outer-container custom-message-popup-outer-container single-qr-code-popup-outer-container">
              <div className="display_flex assign-title-close-icon-container close-icon-container-qr-popup">
                <Dialog.Close asChild>
                  <p className="cross-icon cursor_pointer close-icon-qr-popup">x</p>
                </Dialog.Close>
              </div>
              <div className="display_flex assign-title-close-icon-container"></div>
              <div ref={ref}>
                <div className="custom-popup-message-text print-qr-codes-data">
                  <QRCode
                    value={JSON.stringify(props.data)}
                    size={props.size || 225}
                    logoImage={props.logo || emoryLogo}
                    logoHeight={props.hight || 50}
                    logoWidth={props.width || 50}
                    removeQrCodeBehindLogo={true}
                    ecLevel="H"
                  />
                  <p className="qr-item-id-text">
                    {props.type === 'item' ? basicFieldName('Mark', 'item')+': ' :
                      basicFieldName('Container ID', 'container')+': '} {props.id}
                  </p>
                  <p className="qr-item-id-text text-sm">
                        {props.type === 'item' ? basicFieldName('Title', 'item')+': ' :
                          basicFieldName('Title', 'container')+': '}{props.title}
                      </p>
                </div>
              </div>

              <div className="custom-message-popup-dismiss-btn-container no_margin single-qr-download-trigger-container">
                <ReactToPrint
                  content={reactToPrintContent}
                  documentTitle="QR Code"
                  removeAfterPrint
                  trigger={reactToPrintTrigger}
                />
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default QrCodePopup
