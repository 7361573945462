import React, { ComponentType } from 'react'
import { Helmet } from 'react-helmet'

function withTitle<T extends ComponentType<any>>(Component: T, title: string) {
  return ((props: any) => (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Component {...props} />
    </>
  )) as unknown as T
}

export default withTitle
