import imageCompression from 'browser-image-compression'
import { uploadFile } from '../api/apiCalls'
import { APP_CONSTANTS } from '../configs/constants'

const useImageCompressorAndUploader = () => {
  const compressImage = async (file: File) => {
    const options = {
      maxWidthOrHeight: 1080,
      fileType: 'image/png',
      initialQuality: 0.7,
    }
    const compressedFile = await imageCompression(file, options)
    if (file.name.split('.').pop() !== 'png') {
      let fileName = file.name.split('.')
      fileName.pop()
      const newFileName = fileName.join('.')
      const newFile = new File([compressedFile], `${newFileName}.png`, {
        type: 'image/png',
      })
      return newFile
    } else {
      return compressedFile
    }
  }

  const compressAndUploadImage = async (file: File) => {
    if (!APP_CONSTANTS.ACCEPTED_IMAGE_FORMATS.includes(file.type)) {
      alert('Please upload a supported image format')
      return
    }
    const compressedFile = await compressImage(file)
    const data = await uploadFile(compressedFile)
    return data
  }

  return { compressImage, compressAndUploadImage }
}

export default useImageCompressorAndUploader
