import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, Outlet } from 'react-router-dom'
import 'react-tabs/style/react-tabs.css'
import Flex from '../../components/flex/Flex'
import Loader from '../../components/Loader/loader'
import { renderBreadCrumbs, useBreadCrumbs } from '../../contexts/breadcrumbs'
import { UserPermissionsContext } from '../../contexts/userPermissionsContext'
import withTitle from '../../hoc/withTitle'
import i18n from '../../i18n/i18n'
import './organisationSettings.scss'
import { ScreenOrderForTranslationContext } from '../../contexts/userDetailsContext'

const OrganisationSettings = () => {
  const breadcrumbs = useBreadCrumbs(i18n.t('organisationSettings'), null)

  const { ...userPermissionsData } = useContext(UserPermissionsContext)
  const userPermissions = userPermissionsData?.data

  const { t } = useTranslation()
  const { translations, translationsLoaded } = useContext(ScreenOrderForTranslationContext)
  return (
    <div className='organisation-settings-outer-container'>
      {userPermissionsData.isLoading && <Loader />}
      {renderBreadCrumbs(breadcrumbs)}
      <Flex className='react-tabs__tab-list' css={{ gap: 48 }}>
        {userPermissions && userPermissions.data.manage_organization && (
          <NavLink className={({ isActive }) => `react-tabs__tab ${isActive && 'react-tabs__tab--selected'}`} to='company-details'>
            <p className={'no_margin'}>{t('companyDetails')}</p>
          </NavLink>
        )}

        {userPermissions && userPermissions.data.manage_organization && (
          <NavLink className={({ isActive }) => `react-tabs__tab ${isActive && 'react-tabs__tab--selected'}`} to='primary-contact-details'>
            <p className={'no_margin'}>{t('primaryContactDetails')}</p>
          </NavLink>
        )}

        {/*  <Tab className={(isActive) => `react-tabs__tab ${isActive && 'react-tabs__tab--selected'}`} onClick={() => onOrgSettingsTabClick('User Roles')}>
            <p className={'no_margin'}>{t('userRoles')}</p>
          </Tab> */}

        {userPermissions && userPermissions.data.manage_organization_item_type && (
          <NavLink to='stage' className={({ isActive }) => `react-tabs__tab ${isActive && 'react-tabs__tab--selected'}`}>
            <p className={'no_margin'}>{t('stage')}</p>
          </NavLink>
        )}

        {userPermissions && userPermissions.data.manage_organization_access_flags && (
          <NavLink className={({ isActive }) => `react-tabs__tab ${isActive && 'react-tabs__tab--selected'}`} to='access-flags'>
            <p className={'no_margin'}>{t('accessFlags')}</p>
          </NavLink>
        )}

        {userPermissions && userPermissions.data.manage_organization_item_type && (
          <NavLink className={({ isActive }) => `react-tabs__tab ${isActive && 'react-tabs__tab--selected'}`} to='item-types'>
            <p className={'no_margin'}>{translations['itemType']}</p>
          </NavLink>
        )}

        {userPermissions && userPermissions.data.manage_organization_markings && (
          <NavLink className={({ isActive }) => `react-tabs__tab ${isActive && 'react-tabs__tab--selected'}`} to={'markings'}>
            <p className={'no_margin'}>{t('markings')}</p>
          </NavLink>
        )}

        {userPermissions && userPermissions.data.manage_organization_container && (
          <NavLink className={({ isActive }) => `react-tabs__tab ${isActive && 'react-tabs__tab--selected'}`} to={'container'}>
            <p className={'no_margin'}>{translations['container']}</p>
          </NavLink>
        )}

        {userPermissions && userPermissions.data.manage_organization_container && (
          <NavLink className={({ isActive }) => `react-tabs__tab ${isActive && 'react-tabs__tab--selected'}`} to={'item'}>
            <p className={'no_margin'}>{translations['item']}</p>
          </NavLink>
        )}
      </Flex>

      {!!breadcrumbs.length && <Outlet />}
    </div>
  )
}

export default withTitle(OrganisationSettings, `${i18n.t('organisationSettings')} | ${i18n.t('appName')}`)
