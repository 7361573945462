import { useContext } from 'react';
import { UserDetailsContext } from '../contexts/userDetailsContext'
import {t} from "i18next";

export const resolveHeader = (t: any, basicFieldsMap: any, preferred_language: string, key: string, defaultValue = 'en') => {
  return (
    JSON.parse(basicFieldsMap?.[key]?.field_name_i18n || '{}')[preferred_language] ||
    basicFieldsMap?.[key]?.field_name ||
    key
  )
}

const useBasicFieldName = () => {
  const {
    data: { item, container, preferred_language } = {
      item: null,
      container: null,
      preferred_language: 'en',
    },
  } = useContext(UserDetailsContext);

  /**
 * Get basic-fields name/label configured in org settings
 * @param key Title|Mark|Location...
 * @param type item|container
 */
  const getBasicFieldName = (key: string, type: string) => {
    const basicFieldsMapContainer = container?.basic_fields.reduce((acc: any, field: any) => {
      acc[field.field_name] = field;
      return acc;
    }, {});

    const basicFieldsMapItem = item?.basic_fields.reduce((acc: any, field: any) => {
      acc[field.field_name] = field;
      return acc;
    }, {});

    if (type === 'item') {
      return resolveHeader(t, basicFieldsMapItem, preferred_language, key);
    } else if (type === 'container') {
      return resolveHeader(t, basicFieldsMapContainer, preferred_language, key);
    } else {
      return null;
    }
  };

  return getBasicFieldName;
};

export { useBasicFieldName };
