import React, { useState, useEffect, createContext, useContext } from 'react';
import { useQuery } from 'react-query';
import { getAssigneeList } from '../api/apiCalls';

// Define the context state type
interface AssigneeListContextState {
  assigneesList: any[];
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  error: any;
  refetch: () => void;
}

// Initialize context with an empty state
const defaultContextState: AssigneeListContextState = {
  assigneesList: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  data: null,
  error: null,
  refetch: () => {}
};

// Create the context
export const AssigneeListContext = createContext<AssigneeListContextState>(defaultContextState);

// Define the provider
export const AssigneeListProvider: React.FC = ({ children }) => {
  const [assigneesList, setAssigneesList] = useState<any[]>([]);

  const getAssigneesList = async () => {
    const token = localStorage.getItem('accessToken');
    const res = await getAssigneeList(token, true);
    return res;
  };

  const { isLoading, isSuccess, isError, data, error, refetch } = useQuery('assigneeList', getAssigneesList, {
    enabled: localStorage.getItem('loggedIn') === 'true',
  });

  useEffect(() => {
    if (data && localStorage.getItem('loggedIn') === 'true') {
      setAssigneesList(data);
    }
  }, [data]);

  return (
    <AssigneeListContext.Provider value={{ assigneesList, isLoading, isSuccess, isError, data, error, refetch }}>
      {children}
    </AssigneeListContext.Provider>
  );
};

// Custom hook to use the AssigneeListContext
export const useAssigneeList = () => {
  return useContext(AssigneeListContext);
};
