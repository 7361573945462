import * as Popover from '@radix-ui/react-popover'
import React, { useState } from 'react'
import { HexColorPicker } from 'react-colorful'

import Flex from '../flex/Flex'
import InputField from '../InputField/inputField'
import { PopoverArrow, PopoverContent } from '../popover/Popover'

function ColorInput(props: any) {
  const [value, setValue] = useState(
    props.defaultValue?.replace('#', '')?.padStart(6, props.defaultValue?.replace('#', ''))?.padStart(7, '#') || ''
  )
  return (
    <>
      <Popover.Root>
        <Popover.Trigger asChild>
          <Flex css={{ position: 'relative' }}>
            <Flex css={{ position: 'absolute', bottom: 12, right: 12, width: 36, height: 36, borderRadius: 12, backgroundColor: value }} />
            <InputField maxLength={7} value={value} onChange={setValue} {...props} />
          </Flex>
        </Popover.Trigger>
        <Popover.Portal>
          <PopoverContent>
            <HexColorPicker color={value} onChange={setValue} />
            <PopoverArrow />
          </PopoverContent>
        </Popover.Portal>
      </Popover.Root>
    </>
  )
}

export default ColorInput
