import React, { useContext } from 'react'
import { useState } from 'react'
import Button from '../../../../../components/Button/button'
import InputField from '../../../../../components/InputField/inputField'
import '../../itemType/addNewItem/addNewItem.scss'
import editIcon from '../../../../../assets/images/edit_icon.svg'
import trashIcon from '../../../../../assets/images/trash_icon.svg'
import cameraIcon from '../../../../../assets/images/camera_icon.svg'
import DropdownSelect from '../../../../../components/dropdownSelect/dropdownSelect'
import { useEffect } from 'react'
import {
  createNewStage,
  getDataTypes,
  getDefaultInspectionFields,
  getOrgItemTypeData,
  updateOrgItemTypes,
  updateStageName,
} from '../../../../../api/apiCalls'
// import { UploadToS3 } from "../../../../../helpers/uploadToS3";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { css } from '@emotion/react'
import ClipLoader from 'react-spinners/ClipLoader'
import variables from '../../../../../themes.module.scss'
import { UserPermissionsContext } from '../../../../../contexts/userPermissionsContext'
import { useTranslation } from 'react-i18next'

window.Buffer = window.Buffer || require('buffer').Buffer

const AddNewStage = (props: any) => {
  const { t } = useTranslation()
  const [itemTypeName, setItemTypeName] = useState<string>('')

  useEffect(() => {
    if (props.itemName) {
      setItemTypeName(props.itemName)
    }
  }, [])

  const onSaveClick = () => {
    if (!itemTypeName) {
      alert('Please enter Stage Name')
    } else {
      if (props.editExistingData) {
        const data = {
          name: itemTypeName,
        }
        // console.log(data)
        const token = localStorage.getItem('accessToken')
        updateStageName(data, token, props.itemId)
          .then(response => {
            // console.log(response)
            if (response.status === 200) {
              showSuccessToast()
              props.onSaveClick()
            } else {
              showFailureToast()
            }
          })
          .catch(error => {
            console.log(error)
            showFailureToast()
          })
      } else {
        const data = {
          type: 'STAGE',
          name: itemTypeName,
        }
        // console.log(data)
        const token = localStorage.getItem('accessToken')
        createNewStage(data, token)
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              showSuccessToast()
              props.onSaveClick()
            } else {
              showFailureToast()
            }
          })
          .catch(error => {
            console.log(error)
            showFailureToast()
          })
      }
    }
  }

  const showSuccessToast = () => {
    toast.success('New Stage added!', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  const showFailureToast = () => {
    toast.error('An error occurred while creating new stage!', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  const override = css`
    display: block;
    margin: 0 auto;
  `

  return (
    <>
      <ToastContainer
        position='bottom-center'
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme='colored'
      />

      <div className='display_flex add-item-type-outer-container'>
        <div className='add-item-type-left-col-container' style={{ borderRight: 'none' }}>
          <InputField
            label={t('stageName')}
            value={itemTypeName}
            placeholder={t('enter')+' '+t('stageName')}
            onChange={(val: string) => setItemTypeName(val)}
            type='text'
            error={false}
          />
        </div>
      </div>

      <div className='company-details-bottom-btn-container'>
        <div className='display_flex company-details-bottom-btn-inner-container'>
          <Button
            backgroundColor='#EEEEEE'
            textColor='#333333'
            padding='0.75rem 2rem'
            fontSize='12px'
            height='fit-content'
            width='auto'
            onClick={() => props.onCancelClick()}
          >
            {t('discard')}
          </Button>

          <Button padding='0.75rem 2rem' fontSize='12px' height='fit-content' width='auto' leftMargin='1.25rem' onClick={onSaveClick}>
            Save Changes
          </Button>
        </div>
      </div>
    </>
  )
}

export default AddNewStage
