import React, { createContext, useEffect } from 'react'
import { useQuery } from 'react-query'
import { Navigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { getLoggedInUserPermissions } from '../api/apiCalls'

export const UserPermissionsContext = createContext<any>({})

export const UserPermissionsProvider: React.FC<any> = props => {
  const userPermissionsInfo = async () => {
    const token = localStorage.getItem('accessToken')
    const res = await getLoggedInUserPermissions(token)
    return res
  }

  const { isLoading, isSuccess, isError, data, error, refetch } = useQuery('userPermissions', userPermissionsInfo, {
    enabled: localStorage.getItem('loggedIn') === 'true' ? true : false,
  })

  if (error || (!isLoading && !data)) {
    localStorage.removeItem('loggedIn')
    return <Navigate to='/login' replace />
  }

  useEffect(() => {
    if (data) {
      Sentry.setUser({
        email: data.data.email,
        id: data.data.user_id,
        username: data.data.user_name,
      })
    } else {
      Sentry.setUser(null)
    }
  }, [data])

  return (
    <UserPermissionsContext.Provider value={{ isLoading, isSuccess, isError, data, error, refetch }}>
      {props.children}
    </UserPermissionsContext.Provider>
  )
}
