import React from 'react';
import './App.scss';
import './themes.module.scss';
import RoutesDashboard from './routes/routesDashboard';
import { QueryClient, QueryClientProvider } from 'react-query';

const App = () => {

  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <RoutesDashboard />
    </QueryClientProvider>
  );
}

export default App;
