import React, { useState, useEffect, useContext } from 'react'
import '../../Members/membersList/membersList.scss'
import ReactPaginate from 'react-paginate'
import inputIcon from '../../../assets/images/input_search_icon.svg'
import Button from '../../../components/Button/button'
import { SuperadminMembersContext } from '../../../contexts/superadminMembersContext'
import { useTranslation } from 'react-i18next'

const itemsPerPage = 5

const SuperadminOrgMembersList = (props: any) => {
  const [membersList, setMembersList, { isLoading, isSuccess, isError, data, error, refetch }] = useContext(SuperadminMembersContext)
  const [currentItems, setCurrentItems] = useState<any>([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)

  const onAddMemberClick = () => {
    props.addMemberStatus(true)
    props.hideDangerZone(true)
  }

  const onEditUserClick = (id: any) => {
    props.setMemberId(id)
    props.addMemberStatus(true)
    props.hideDangerZone(false)
  }

  useEffect(() => {
    refetch()
    if (membersList) {
      // console.log(membersList)
    }
  }, [])

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    if (membersList && membersList.length > 0) {
      setCurrentItems(membersList.slice(itemOffset, endOffset))
      setPageCount(Math.ceil(membersList.length / itemsPerPage))
    }
  }, [membersList, itemOffset, itemsPerPage])

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % membersList.length
    setItemOffset(newOffset)
  }

  const { t } = useTranslation()

  return (
    <div className='organisation-settings-outer-container'>
      <div className='sub-tab-content-container sub-tab-content-container-item-type sub-tab-content-container-superadmin'>
        <p className='selected-tab-page-title manage-members-page-title members-list-page-title cursor_default'>Manage Org Admins</p>
        <p className='tabs-page-title-bottom-border' />
        <div className='item-type-button-wrapper'>
          <div className='add-new-item-btn-container'>
            <Button padding='10px 20px' onClick={onAddMemberClick} backgroundColor='#212e4a'>
              + Add Org Admin
            </Button>
          </div>
          <div className='member-count-text'>Total Org Admins: {membersList && membersList.length}</div>
        </div>
        <table className='item-type-table-container'>
          <tbody>
            <tr className='item-type-table-heading'>
              <th className='item-type-table-first-data'>Name</th>
              <th>Email</th>
              <th>Organization name</th>
              <th>Status</th>
              <th>User Role</th>
              <th>Actions</th>
              {/* <th></th> */}
            </tr>
            {currentItems &&
              currentItems.length > 0 &&
              currentItems.map((item: any, index: any) => {
                // console.log(item)
                return (
                  <tr className={index % 2 === 0 ? 'even-item-bg-color' : ''} key={item.id}>
                    <td className='item-type-table-first-data'>{item.full_name}</td>
                    <td className=''>{item.email}</td>
                    <td className=''>{item.organization_name}</td>
                    <td>
                      <span className={item.status === 1 ? 'item-type-active-status-text' : 'item-type-suspentend-status-text'}>
                        {item.status === 1 ? t('active') : t('suspended')}
                      </span>
                    </td>
                    <td className=''>Org Admin</td>
                    {/* <td className={item.status === 1 ? "item-type-active-Resend-text" : 'item-type-suspentend-resend-text'}><span className={item.status === 1 ? "cursor_pointer" : ""} onClick={() => onResetPasswordClick(item.email, item.status)}>Send Password Reset Link </span></td> */}
                    <td>
                      <span className='item-type-action-text no_padding' onClick={() => onEditUserClick(item.id)}>
                        Edit User Info
                      </span>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
        <ReactPaginate
          nextLabel={`Next`}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel='Prev'
          containerClassName='item-type-all-btn-container'
          pageClassName='item-type-btn'
          pageLinkClassName='item-type-link-btn'
          previousClassName='item-type-btn'
          previousLinkClassName='item-type-prev-btn'
          nextClassName='item-type-btn'
          nextLinkClassName='item-type-prev-btn'
          breakLabel='...'
          breakClassName='item-type-btn'
          breakLinkClassName='item-type-link-btn'
          activeClassName='item-type-active-link-btn'
        />
      </div>
    </div>
  )
}

export default SuperadminOrgMembersList
