import { styled } from '../../stitches.config'
import inputIcon from '../../assets/images/input_search_icon.svg'

export const SearchContainer = styled('div', {
  position: 'relative',

  '&::before': {
    content: '""',
    backgroundImage: `url(${inputIcon})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: 14,
    width: 14,
    display: 'inline-block',
    position: 'absolute',
    left: 10,
    top: '50%',
    transform: 'translateY(-50%)',
  },
})

export const SearchBar = styled('input', {
  width: 300,
  padding: 8,
  pl: 30,
  fontFamily: '$nunito',
  borderRadius: '5px',
  border: '1px solid #DDDDDD',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: 1.38,
  color: '$text',
})
